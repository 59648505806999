import React, { useMemo } from 'react';
import { Flex, IconButton } from '@chakra-ui/react';
import {
	useMessengerSidebarsOpenStore,
	useChatUserStore,
} from '../../../../store';
import { UserCircleIcon } from '../../../../assets';
import {
	DefaultDirectSearchLabelOptions,
	DirectMainSearchOptions,
	DirectOptionsSubKeywords,
	DirectSearchPopupOptionsState,
} from '../../constants';
import { SearchPopup } from '../../common-components';
import { ChatType, SearchSuggestionUserType, UserByIdData } from '../../types';

interface IDirectHeader {
	receiver: UserByIdData | null;
	id: number;
}

export const DirectHeader: React.FC<IDirectHeader> = ({ receiver, id }) => {
	const { onToggleDirectFriendInfoSidebar } = useMessengerSidebarsOpenStore();
	const { user } = useChatUserStore();

	const staticSearchSuggestions: SearchSuggestionUserType[] = useMemo(() => {
		if (!user) {
			return [];
		}
		const currentUserData = {
			id: user.userId,
			avatarUrl: user.avatarUrl,
			displayName: user.displayName,
			nickName: user.nickName,
		};
		if (!receiver || !id) {
			return [currentUserData];
		}
		return [
			currentUserData,
			{
				id,
				avatarUrl: receiver.avatarUrl,
				displayName: receiver.displayName,
				nickName: receiver.nickName,
			},
		];
	}, [user, receiver, id]);
	return (
		<Flex
			width="100%"
			gap="10px"
			justify="flex-end"
			align="center"
			bg="mainBg"
			p="10px"
			position="relative"
			zIndex={21}
			boxShadow="0px 1px 20px #0F1B491A">
			<IconButton
				aria-label="User-info-sidebar-toggle"
				bg="white"
				onClick={onToggleDirectFriendInfoSidebar}>
				<UserCircleIcon />
			</IconButton>

			<SearchPopup
				key={id}
				defaultMainKeywords={DefaultDirectSearchLabelOptions}
				searchPopupOptionsState={DirectSearchPopupOptionsState}
				mainSearchOptionsMenu={DirectMainSearchOptions}
				defaultSubKeywords={DirectOptionsSubKeywords}
				staticSuggestions={staticSearchSuggestions}
				chatType={ChatType.DIRECT}
			/>
		</Flex>
	);
};
