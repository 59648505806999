import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useAuth } from 'oidc-react';
import axios from 'axios';
import { Loader } from '../Loader/Loader';
import { userManager } from '../../utils';

const verifySession = (token: string) =>
	axios.get(
		(import.meta.env.VITE_BASE_URL || 'http://localhost:3000') +
			'/api/web/verify',
		{
			headers: {
				Authorization: `Bearer ${token}`,
			},
		},
	);

// TESTING NO MATCHING STATE FOUND IN STORAGE
// const readParams = (url: string, responseMode: "query" | "fragment" = "query"): URLSearchParams => {
// 	if (!url) throw new TypeError("Invalid URL");
// 	// the base URL is irrelevant, it's just here to support relative url arguments
// 	const parsedUrl = new URL(url, "http://127.0.0.1");
// 	const params = parsedUrl[responseMode === "fragment" ? "hash" : "search"];
// 	return new URLSearchParams(params.slice(1));
// }
// console.log(readParams(window.location.href, 'query'))
// console.log(userManager.settings)
// const params = new URLSearchParams(readParams(window.location.href, 'query'))
// console.log(params.get('state'))
// =======================================

export const ProtectedArea: React.FC<PropsWithChildren> = ({ children }) => {
	const { userData } = useAuth();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		if (userData) {
			verifySession(userData.access_token)
				.then(() => {
					setLoading(false);
				})
				.catch(() => {
					userManager.signoutRedirect({
						extraQueryParams: {
							returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
						},
					});
				});
		}
	}, [userData]);

	if (loading) {
		return <Loader centerHeight="100vh" />;
	}

	if (userData) {
		return <>{children}</>;
	} else {
		return null;
	}
};
