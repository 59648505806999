import { useQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { MessengerQueryKeys } from './query-keys';
import { ApiResponse } from '../../../types';
import { ChannelUsersResponse } from '../types';

export const useGetChannelOnlineUsers = (
	debouncedSearchQuery: string,
	isEnabled: boolean,
	filter?: number,
	channelId?: number,
	count?: number,
) => {
	return useQuery<ApiResponse<ChannelUsersResponse>>({
		queryKey: [
			MessengerQueryKeys.GET_CHANNEL_ONLINE_USERS,
			debouncedSearchQuery,
			filter,
			// channelId,
		],
		queryFn: () =>
			API_CHAT.get('/api/app/channel/users', {
				PageSize: count,
				Search: debouncedSearchQuery || undefined,
				Filter: filter,
			}),
		enabled: isEnabled && !!channelId,
	});
};
