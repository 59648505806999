import React from 'react';
import { Button, Text } from '@chakra-ui/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import MyProfile from '../../MyProfile';
import { ApiResponseEmpty, ICurrentUser } from '../../../../types';
import { QueryKeys } from '../../../../constants';
import { useForm } from 'react-hook-form';
import {
	MyProfileForm,
	MyProfilePhoneForm,
	profilePhoneResolver,
} from './validation/profile.validation';
import { API_IDENTITY } from '../../../../api/api.base';
import { Alerter } from '../../../../utils';
import { Loader, InputText } from '../../../../components';

export const PhoneForm: React.FC = () => {
	const queryClient = useQueryClient();
	const { data: userData, isLoading } = useQuery<ICurrentUser>({
		queryKey: [QueryKeys.CURRENT_USER],
	});

	const {
		register,
		formState: { errors },
		handleSubmit,
		watch,
	} = useForm<MyProfilePhoneForm>({
		resolver: profilePhoneResolver,
	});

	const { mutate, isPending: isMutationLoading } = useMutation<
		ApiResponseEmpty,
		any,
		MyProfileForm
	>({
		mutationFn: data => API_IDENTITY.post('/api/user/current/edit', data),
		onSuccess(e) {
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.CURRENT_USER],
			});
			Alerter.success(e.messages?.[0]?.message);
		},
		onError(e) {
			Alerter.error(e);
		},
	});

	if (isLoading) {
		return <Loader centerHeight={'100vh'} />;
	}

	const onSubmit = (data: MyProfilePhoneForm) => {
		console.log(data);
		if (userData) {
			mutate({
				firstName: userData.firstName,
				lastName: userData.lastName,
				phoneNumber: data.phone,
			});
		}
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<MyProfile>
				<InputText
					focusBorderColor="#08BB4B"
					{...register('phone')}
					errorMsg={errors.phone?.message}
					bg={'#F1F4F9'}
					borderRadius={'10px'}
					h={'55px'}
					label={'Phone'}
					leftIcon={<Text color={'inherit'}>+1</Text>}
					placeholder={
						userData?.phoneNumber
							? String(userData.phoneNumber)
							: ''
					}
				/>
				<Button
					type={'submit'}
					h={'60px'}
					isDisabled={
						isMutationLoading || !(watch('phone')?.length === 10)
					}
					bg={'success'}
					color={'white'}>
					Change Phone
				</Button>
			</MyProfile>
		</form>
	);
};
