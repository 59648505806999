import React, { useEffect } from 'react';
import { Flex, IconButton, Text } from '@chakra-ui/react';
import { months } from '../helper';
import { ArrowDownIcon, ArrowUpIcon } from '../../../assets';

interface ICustomHeaderProps {
	date: Date;
	decreaseMonth: () => void;
	increaseMonth: () => void;
	prevMonthButtonDisabled: boolean;
	nextMonthButtonDisabled: boolean;
	changeYear: (year: number) => void;
	changeMonth: (month: number) => void;
	selectedYear: number;
	selectedMonth: string;
	onInteract?: VoidFunction;
}
export const PickerCustomHeader: React.FC<ICustomHeaderProps> = ({
	date,
	decreaseMonth,
	increaseMonth,
	prevMonthButtonDisabled,
	nextMonthButtonDisabled,
	changeYear,
	changeMonth,
	selectedYear,
	selectedMonth,
	onInteract,
}) => {
	useEffect(() => {
		changeYear(selectedYear);
	}, [selectedYear]);

	useEffect(() => {
		changeMonth(months.indexOf(selectedMonth));
	}, [selectedMonth]);

	return (
		<Flex
			bg="white"
			mb="10px"
			justifyContent={'space-between'}
			alignItems={'center'}>
			<Text variant={'bodyMedium'} color="blue.200" fontFamily="Inter">
				{`${months[date.getMonth()]} ${date.getFullYear()}`}
			</Text>
			<Flex>
				<IconButton
					aria-label={'prev month'}
					bg={'inherit'}
					p={0}
					w={'20px'}
					_hover={{ bg: 'inherit' }}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						decreaseMonth();
						onInteract?.();
					}}
					icon={<ArrowUpIcon />}
					size={'sm'}
					disabled={prevMonthButtonDisabled}
				/>
				<IconButton
					aria-label={'next month'}
					bg={'inherit'}
					p={0}
					_hover={{ bg: 'inherit' }}
					onClick={e => {
						e.preventDefault();
						e.stopPropagation();
						increaseMonth();
						onInteract?.();
					}}
					icon={<ArrowDownIcon />}
					size={'sm'}
					disabled={nextMonthButtonDisabled}
				/>
			</Flex>
		</Flex>
	);
};
