import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { AxiosProgressEvent } from 'axios';
import { MessageInput, IMessageInputRef } from '../messages-components';
import {
	BlockMessageWrapper,
	DirectHeader,
	DirectRightSidebar,
} from './components';
import { checkFileType, getVideoDetails } from '../utils';
import {
	ChatType,
	CreatePrivateConversationPayload,
	JumpToPresentConditionEnum,
	ReplyMessage,
	SendPrivateMessagePayload,
} from '../types';
import { DirectMessagesList } from './messages/DirectMessagesList';
import { DirectMessagesUnreadBanner } from './messages/DirectMessagesUnreadBanner';
import {
	useChatUserStore,
	useMessageNavigationStore,
	useMessengerSidebarsOpenStore,
} from '../../../store';
import {
	useCheckConversationExist,
	useCreateDirectConversation,
	useSendDirectMessage,
	useMarkAsReadConversation,
	useGetUserById,
} from '../queries';
import {
	CONVERSATION_HAS_BLOCKED,
	DEFAULT_LAST_READ_TIME,
	FileAttachmentType,
} from '../constants';
import { useCheckMarkAsRead } from '../../../store/useCheckMarkAsRead';
import { Alerter } from '../../../utils';
import { UploadProgress } from '../common-components';
import { UploadProgressState } from '../../../types';
import { useUpdateUserInfoById } from '../hooks';
import { MessengerQueryKeys } from '../queries/query-keys';
import { Loader } from '../../../components';

export const DirectMessages: React.FC = () => {
	const queryClient = useQueryClient();

	const { id } = useParams();
	const { user } = useChatUserStore();
	const { onCloseSearchResultSidebar } = useMessengerSidebarsOpenStore();
	const { mutate: updateUserInfoById } = useUpdateUserInfoById();

	const { setMarkConversationId } = useCheckMarkAsRead();
	const {
		jumpToPresentCondition,
		forcedLoading,
		setJumpToPresentCondition,
		setForcedLoading,
	} = useMessageNavigationStore();
	const { mutateAsync: createDirectConversation } =
		useCreateDirectConversation();
	const { mutateAsync: sendDirectMessage } = useSendDirectMessage();

	const [replyMessage, setReplyMessage] = useState<ReplyMessage | null>(null);
	const [findLoading, setFindLoading] = useState(false);
	const [sendingMessageLoading, setSendingMessageLoading] = useState(false);
	const [searchParams, setSearchParams] = useSearchParams();
	const [lastReadTime, setLastReadTime] = useState<string>(
		DEFAULT_LAST_READ_TIME,
	);
	const [unreadCount, setUnreadCount] = useState(0);
	const [hasVideoAttachment, setHasVideoAttachment] = useState(false);
	const [uploadProgress, setUploadProgress] =
		useState<UploadProgressState | null>(null);

	const messageInputRef = useRef<IMessageInputRef>(null);
	const shouldCallMarkAsReadRef = useRef(true);

	const { mutateAsync: markAsReadConversation } = useMarkAsReadConversation();

	const {
		// isPending: loadingCheckConversationExist,
		mutateAsync: checkConversationExist,
	} = useCheckConversationExist();

	const conversationId = useMemo(
		() => searchParams.get('conversationId'),
		[id, searchParams],
	);

	const topScrollRef = useRef<HTMLSpanElement>(null);

	const { data: receiverData, isLoading: receiverLoading } = useGetUserById(
		id ? +id : null,
		true,
	);

	const { value: receiver } = receiverData || {};

	const onCheckConversationExist = async (receiverId: number) => {
		try {
			const res = await checkConversationExist(receiverId);
			if (!res.value.conversationId) {
				setLastReadTime(DEFAULT_LAST_READ_TIME);
				setUnreadCount(0);
				return;
			}
			setSearchParams(params => {
				params.set('conversationId', `${res.value.conversationId}`);
				return params;
			});
		} catch (e) {
			console.log(e);
		}
	};

	const onMarkAsRead = async (conversationId: number) => {
		try {
			await markAsReadConversation({
				conversationId: Number(conversationId),
				dateTime: new Date().toISOString(),
			});
			setUnreadCount(0);
			setLastReadTime(new Date().toISOString());
			setMarkConversationId(conversationId);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		if (id && !conversationId) {
			onCheckConversationExist(+id);
		}
		setReplyMessage(null);
		return () => {
			setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
			onCloseSearchResultSidebar(false);
		};
	}, [id]);

	useEffect(() => {
		return () => {
			if (
				user?.userId &&
				conversationId &&
				shouldCallMarkAsReadRef.current
			) {
				onMarkAsRead(+conversationId);
			}
			if (user?.userId && conversationId) {
				queryClient.resetQueries({
					queryKey: [
						MessengerQueryKeys.GET_DIRECT_MESSAGES,
						+conversationId,
					],
				});
			}
		};
	}, [conversationId, user]);

	const handleUploadProgress = (event: AxiosProgressEvent) => {
		if (event.total) {
			setUploadProgress({
				progress: Math.round((100 * event.loaded) / event.total),
				totalSize: event.total,
			});
		}
	};

	const onSubmit = async (msg: string, file: File | null) => {
		try {
			setSendingMessageLoading(true);
			if (!id || (!file && !msg.length)) {
				return;
			}

			const fileType = checkFileType(file?.type || '');
			const isVideoType = fileType === FileAttachmentType.VIDEO;
			const {
				videoDuration = 0,
				videoWidth = 0,
				videoHeight = 0,
			} = file && isVideoType ? await getVideoDetails(file) : {};

			if (isVideoType) {
				setHasVideoAttachment(true);
			}

			if (!conversationId) {
				const newConversationObj: CreatePrivateConversationPayload = {
					UserToSend: +id,
					Message: msg.length ? msg : null,
					[fileType || '']: [file],
				};

				const formData = new FormData();

				for (const [key, value] of Object.entries(newConversationObj)) {
					if (!value) {
						continue;
					}
					if (Array.isArray(value)) {
						if (!value[0]) {
							continue;
						}

						formData.append(key, value[0] as Blob);
						continue;
					}

					formData.append(key, value as string);
				}

				if (isVideoType) {
					formData.append(
						'VideoMetaData[0].Width',
						String(videoWidth),
					);
					formData.append(
						'VideoMetaData[0].Height',
						String(videoHeight),
					);
					formData.append(
						'VideoMetaData[0].Duration',
						String(videoDuration),
					);
				}

				const createConversationResult = await createDirectConversation(
					{ payload: formData, onUpload: handleUploadProgress },
				);

				if (!createConversationResult?.success) {
					Alerter.error(
						createConversationResult?.errors?.[0]?.message ||
							'Error sending message',
					);
					return;
				}

				await markAsReadConversation({
					conversationId: Number(createConversationResult.value),
					dateTime: new Date().toISOString(),
				});
				setSearchParams(params => {
					params.set(
						'conversationId',
						`${createConversationResult.value}`,
					);
					return params;
				});
				setMarkConversationId(createConversationResult.value);
				shouldCallMarkAsReadRef.current = true;

				return;
			}

			// creating object for iterating it for formData
			const messageObj: SendPrivateMessagePayload = {
				ConversationId: +conversationId,
				ReplyToId: replyMessage ? replyMessage.messageId : null,
				Text: msg.length ? msg : null,
				[fileType || '']: [file],
			};

			// creating formData
			const formData = new FormData();

			for (const [key, value] of Object.entries(messageObj)) {
				if (!value) {
					continue;
				}
				if (Array.isArray(value)) {
					if (!value[0]) {
						continue;
					}
					formData.append(key, value[0] as Blob);
					continue;
				}

				formData.append(key, value as string);
			}
			if (isVideoType) {
				formData.append('VideoMetaData[0].Width', String(videoWidth));
				formData.append('VideoMetaData[0].Height', String(videoHeight));
				formData.append(
					'VideoMetaData[0].Duration',
					String(videoDuration),
				);
			}

			const sendMessageResult = await sendDirectMessage({
				payload: formData,
				onUpload: handleUploadProgress,
			});

			if (replyMessage) {
				setReplyMessage(null);
			}
			if (
				sendMessageResult?.errors?.[0]?.message ===
				CONVERSATION_HAS_BLOCKED
			) {
				return updateUserInfoById(+id, { isBlockingMe: true });
			}
			if (!sendMessageResult?.success) {
				Alerter.error(
					sendMessageResult?.errors?.[0]?.message ||
						'Error sending message',
				);
				return;
			}
			// scrollRef.current?.scrollIntoView();
		} catch (error) {
			console.error('Error adding document: ', error);
		} finally {
			setSendingMessageLoading(false);
			setHasVideoAttachment(false);
		}
	};
	const onSetReplyMessage = (obj: ReplyMessage) => {
		setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
		setReplyMessage(obj);
		messageInputRef.current?.focusInput();
	};

	useEffect(() => {
		return () => {
			setForcedLoading(false);
		};
	}, []);

	const getFindLoadingContainerHeight = () => {
		const offset =
			jumpToPresentCondition === JumpToPresentConditionEnum.Visible ||
			jumpToPresentCondition === JumpToPresentConditionEnum.Loading ||
			replyMessage
				? 230
				: 160;

		return `calc(100vh - ${offset}px)`;
	};

	return (
		<Flex direction="column" width="100%">
			<DirectHeader receiver={receiver || null} id={id ? +id : 0} />
			<Flex flex={1}>
				<Flex
					bg="mainBg"
					flexDirection="column"
					overflowY="auto"
					overflowX="hidden"
					px="20px"
					pb="20px"
					position="relative"
					flex={1}>
					{unreadCount ? (
						<DirectMessagesUnreadBanner
							topScrollRef={topScrollRef}
							unreadCount={unreadCount}
							onMarkAsRead={() => {
								if (conversationId) {
									onMarkAsRead(+conversationId);
								}
							}}
							lastReadTime={
								lastReadTime === DEFAULT_LAST_READ_TIME
									? null
									: lastReadTime
							}
						/>
					) : null}
					{findLoading || forcedLoading ? (
						<Box
							position="absolute"
							backgroundColor="mainBg"
							height={getFindLoadingContainerHeight()}
							width="100%"
							top={0}
							left={0}
							right={0}
							bottom={0}
							zIndex={45}>
							<Loader centerHeight="100%" />
						</Box>
					) : null}
					<DirectMessagesList
						key={id}
						conversationId={conversationId}
						onSetReplyMessage={onSetReplyMessage}
						setFindLoading={(value: boolean) =>
							setFindLoading(value)
						}
						replyMessage={replyMessage}
						receiver={receiver || null}
						receiverId={id}
						user={user}
						updateUnreadCount={setUnreadCount}
						receiverLoading={receiverLoading}
						updateLastReadTime={(time: string) =>
							setLastReadTime(time)
						}
						lastReadTime={
							lastReadTime === DEFAULT_LAST_READ_TIME
								? undefined
								: lastReadTime
						}
						deleteConversationHandler={() => {
							setUnreadCount(0);
							setLastReadTime(DEFAULT_LAST_READ_TIME);
							setSearchParams(params => {
								params.delete('conversationId');
								return params;
							});
							shouldCallMarkAsReadRef.current = false;
						}}
					/>

					{/* <TypingIndicator /> */}
					<BlockMessageWrapper
						receiverId={id}
						isBlockedMe={receiver?.isBlockingMe}
						isReceiverBlocked={receiver?.isBlocked}>
						<MessageInput
							onSubmit={onSubmit}
							replyMessage={replyMessage}
							setReplyMessage={setReplyMessage}
							sendingMessageLoading={sendingMessageLoading}
							ref={messageInputRef}
							enableMentions={false}
							chatId={id}
							chatType={ChatType.DIRECT}
							isListLoading={findLoading}
						/>
					</BlockMessageWrapper>
					{hasVideoAttachment && uploadProgress ? (
						<UploadProgress
							progressValue={uploadProgress?.progress}
							totalSize={uploadProgress?.totalSize}
							wrapperProps={{
								position: 'absolute',
								top: unreadCount ? '60px' : '16px',
								right: '20px',
								zIndex: 9,
							}}
						/>
					) : null}
				</Flex>

				<DirectRightSidebar
					receiver={receiver}
					receiverLoading={receiverLoading}
					conversationId={conversationId ? +conversationId : 0}
				/>
			</Flex>
		</Flex>
	);
};
