export const Checkbox = {
	variants: {
		// CRUZ CONTROL CHECKBOX
		['cruzctrl-checkbox']: {
			icon: {
				color: 'white',
				width: '16px',
				height: '16px',
			},
			control: {
				borderColor: 'success',
				borderRadius: '4px',
				width: '24px',
				height: '24px',
				_disabled: {
					borderColor: 'gray.300',
					bg: 'gray.200',
				},
				_checked: {
					background: 'success',
					borderColor: '#08BB4B',
					_hover: {
						opacity: 0.7,
						background: 'success',
						borderColor: '#08BB4B',
					},
				},
				_indeterminate: {
					background: 'success',
					borderColor: '#08BB4B',
					_hover: {
						opacity: 0.7,
						background: 'success',
						borderColor: '#08BB4B',
					},
				},
			},
			label: {
				fontSize: '14px',
				lineHeight: '17px',
				fontWeight: 500,
				color: 'blue.300',
			},
		},
		// ==================
	},

	baseStyle: {
		icon: {
			color: '#08BB4B',
		},
		control: {
			borderColor: 'white',
			borderRadius: '2px',
			_disabled: {
				borderColor: 'gray.300',
				bg: 'gray.200',
			},
			_checked: {
				background: 'transparent',
				borderColor: '#08BB4B',
				_hover: {
					background: 'transparent',
					borderColor: '#08BB4B',
				},
			},
		},
	},
};
