import React from 'react';
import { Box, Button, HStack, Img, Text } from '@chakra-ui/react';
import { REACTION_LIST_MEDIUM } from '../../../constants';
import { MessageReactionItem, MessageReactionType } from '../../../types';
import { colors } from '../../../../../theme';

interface IReactionsListProps {
	reactions: MessageReactionItem[];
	isSuspended?: boolean;
	onReact?: (
		messageId: number,
		emojiId: MessageReactionType,
		reactedEmojiId?: number | null,
	) => void;
	messageId: number;
	userReactedId?: MessageReactionType | null;
}

export const ReactionsList: React.FC<IReactionsListProps> = ({
	isSuspended,
	reactions,
	messageId,
	onReact,
	userReactedId,
}) => {
	return (
		<HStack>
			{reactions
				.sort((a, b) => b.count - a.count)
				.map(reaction => (
					<Button
						key={reaction.emojiId}
						borderRadius="20px"
						p="2px 4px"
						h="20px"
						minW="32px"
						bg={
							reaction.userReacted
								? colors.blue[100]
								: colors.mainBg
						}
						border={`1px solid ${colors.dropBlue}`}
						columnGap="2px"
						isDisabled={isSuspended}
						cursor={onReact && !isSuspended ? 'pointer' : 'default'}
						onClick={() =>
							onReact?.(
								messageId,
								reaction.emojiId,
								userReactedId,
							)
						}
						_hover={{
							bg:
								onReact && !isSuspended
									? colors.blueHover[100]
									: colors.mainBg,
						}}
						_disabled={{
							cursor: 'not-allowed',
						}}>
						<Box boxSize="16px">
							<Img
								src={REACTION_LIST_MEDIUM[reaction.emojiId]}
								alt=""
								boxSize="16px"
							/>
						</Box>
						<Text
							fontSize="12px"
							lineHeight="16px"
							color={
								reaction.userReacted
									? 'white'
									: colors.blue[300]
							}>
							{reaction.count}
						</Text>
					</Button>
				))}
		</HStack>
	);
};
