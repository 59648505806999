import {
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalHeader,
	ModalOverlay,
	Stack,
	Text,
	VStack,
} from '@chakra-ui/react';
import { CustomAsyncSelect, CustomReactSelect } from '../../components';
import { InputText, Loader } from '../../../../components';
import React, { useCallback, useEffect } from 'react';
import {
	defaultEntityValues,
	errorMsgStyles,
	inputLabelStyles,
	inputStyles,
} from '../../constants';
import { Controller, useForm } from 'react-hook-form';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import {
	BusinessEntityFormType,
	businessEntityResolver,
} from '../../validations';
import { LoadOptionsType } from '../../types';
import debounce from 'lodash.debounce';
import { getPlaceById, getPlaces } from '../../queries';
import { SingleValue } from 'react-select';
import { getEntityValues, transformGeocodeValueToOption } from '../../utils';
import {
	useCreateBusinessEntity,
	useGetBusinessEntity,
	useUpdateBusinessEntity,
} from '../../queries/business-entity';
import { StripeStatusConnection } from './components';

interface IBusinessEntityModal {
	id?: number;
	isOpen: boolean;
	onClose: VoidFunction;
	onOpenDisconnectDialog?: VoidFunction;
}

const timezones = {
	...allTimezones,
	'America/New_York': 'New York',
};

export const BusinessEntityModal: React.FC<IBusinessEntityModal> = ({
	id,
	isOpen,
	onClose,
	onOpenDisconnectDialog,
}) => {
	const { data, isLoading } = useGetBusinessEntity({
		id,
		isEnabled: !!(id && isOpen),
	});

	const modalTitle = id ? 'Edit Business Entity' : 'Add Business Entity';

	const {
		handleSubmit,
		register,
		formState: { errors },
		reset,
		control,
	} = useForm<BusinessEntityFormType>({
		resolver: businessEntityResolver,
		defaultValues: defaultEntityValues,
	});

	const { options: timezoneOptions } = useTimezoneSelect({
		timezones,
		displayValue: 'UTC',
	});

	const createEntity = useCreateBusinessEntity();
	const updateEntity = useUpdateBusinessEntity();

	// const connectMailBox = useConnectMailbox();

	// const { data: mailboxes, isLoading: mailboxesLoading } =
	// 	useGetConnectedMailboxes();

	// const googleLogin = useGoogleLogin({
	// 	onSuccess: async tokenResponse => {
	// 		if (tokenResponse.code) {
	// 			// const response = await getTokensFromAuthCode(
	// 			// 	tokenResponse.code,
	// 			// );
	// 			connectMailBox.mutate({
	// 				code: tokenResponse.code,
	// 			});
	// 		}
	// 	},
	// 	onError: errorResponse => {
	// 		Alerter.error('Error while attempting authenticate via Google');
	// 		console.log(errorResponse, 'google auth error');
	// 	},
	// 	flow: 'auth-code',
	// 	scope: GOOGLE_SCOPE.join(' '),
	// });

	const loadOptions: LoadOptionsType = useCallback(
		debounce((inputValue, callback) => {
			getPlaces(inputValue).then(resp => callback(resp || []));
		}, 500),
		[],
	);

	const onReset = useCallback(() => {
		reset(defaultEntityValues);
		onClose();
	}, []);

	const onSubmit = (values: BusinessEntityFormType) => {
		// const { mailbox, ...rest } = values;
		// const payload = {
		// 	...rest,
		// 	mailboxId: Number(mailbox?.value),
		// };

		if (id) {
			updateEntity.mutate(
				{
					id,
					data: values,
				},
				{
					onSuccess: data => {
						if (data.success) {
							onReset();
						}
					},
				},
			);
		} else {
			createEntity.mutate(values, {
				onSuccess: data => {
					if (data.success) {
						onReset();
					}
				},
			});
		}
	};

	useEffect(() => {
		if (id && data?.value && isOpen) {
			reset(getEntityValues(data.value));
		}
		// if (mailboxes?.value?.mailboxes?.length) {
		// 	setValue('mailbox', {
		// 		value: mailboxes?.value.mailboxes[0].id,
		// 		label: mailboxes?.value.mailboxes[0].email,
		// 	});
		// }
	}, [data?.value, id, isOpen]);

	return (
		<Modal
			closeOnOverlayClick={false}
			isCentered={true}
			isOpen={isOpen}
			onClose={onReset}>
			<ModalOverlay />
			<ModalContent
				bg={'mainBg'}
				maxW={'800px'}
				w={{ base: '90vw', lg: '100%' }}>
				<ModalHeader
					px={'16px'}
					fontSize={'20px'}
					fontWeight={'700'}
					lineHeight={'24px'}>
					{modalTitle}
				</ModalHeader>
				<ModalCloseButton color={'blue.200'} />
				<ModalBody p={'16px'}>
					{isLoading ? (
						<Loader centerHeight={'300px'} />
					) : (
						<form onSubmit={handleSubmit(onSubmit)}>
							<VStack align={'stretch'} spacing={'16px'}>
								<Box bg={'white'} borderRadius={'8px'}>
									<Box
										borderBottom={'1px solid #F1F4F9'}
										p={'16px'}>
										<Text fontWeight={600}>
											Business Entity
										</Text>
									</Box>
									<VStack
										p={'16px'}
										align={'stretch'}
										spacing={'16px'}>
										<Stack
											direction={{
												base: 'column',
												md: 'row',
											}}
											spacing={'16px'}>
											<InputText
												{...register('entityName')}
												errorMsg={
													errors.entityName?.message
												}
												{...inputStyles}
												placeholder={
													'Enter Entity Name'
												}
												label={'Entity Name'}
												errorMessageProps={
													errorMsgStyles
												}
												formLabelProps={
													inputLabelStyles
												}
											/>
											<InputText
												{...register('ownerName')}
												errorMsg={
													errors.ownerName?.message
												}
												{...inputStyles}
												placeholder={'Enter Owner Name'}
												label={'Owner Name'}
												errorMessageProps={
													errorMsgStyles
												}
												formLabelProps={
													inputLabelStyles
												}
											/>
										</Stack>
										<Stack
											direction={{
												base: 'column',
												md: 'row',
											}}
											spacing={'16px'}>
											<Controller
												render={({
													field: { value, onChange },
													fieldState: { error },
												}) => (
													<CustomAsyncSelect
														onChange={async (
															newValue: SingleValue<any>,
														) => {
															if (
																newValue?.value
															) {
																const response =
																	await getPlaceById(
																		newValue.value,
																	);
																onChange({
																	address:
																		newValue.label,
																	...response,
																});
															}
														}}
														value={transformGeocodeValueToOption(
															value,
														)}
														loadOptions={
															loadOptions
														}
														label={'Address'}
														placeholder={
															'Search Address'
														}
														errMsg={error?.message}
													/>
												)}
												name={'location'}
												control={control}
											/>
											{/*<Controller*/}
											{/*	control={control}*/}
											{/*	name="mailbox"*/}
											{/*	render={({*/}
											{/*		field: { value, onChange },*/}
											{/*		fieldState: { error },*/}
											{/*	}) => (*/}
											{/*		<CustomReactSelect*/}
											{/*			components={{*/}
											{/*				MenuList:*/}
											{/*					renderCustomMenuList(*/}
											{/*						() => {*/}
											{/*							googleLogin();*/}
											{/*						},*/}
											{/*					),*/}
											{/*			}}*/}
											{/*			isLoading={*/}
											{/*				mailboxesLoading*/}
											{/*			}*/}
											{/*			noOptionsMessage={() =>*/}
											{/*				'No email accounts found'*/}
											{/*			}*/}
											{/*			onChange={onChange}*/}
											{/*			value={*/}
											{/*				value as OptionType*/}
											{/*			}*/}
											{/*			label="Mail Account"*/}
											{/*			placeholder="Select Mail Account"*/}
											{/*			options={transformMailboxesToOptions(*/}
											{/*				mailboxes?.value*/}
											{/*					.mailboxes,*/}
											{/*			)}*/}
											{/*			errMsg={error?.message}*/}
											{/*		/>*/}
											{/*	)}*/}
											{/*/>*/}
											<Controller
												render={({
													field: { value, onChange },
													formState: { errors },
												}) => (
													<CustomReactSelect
														errMsg={
															errors.timeZoneId
																?.message
														}
														onChange={(
															newValue: SingleValue<any>,
														) => {
															if (newValue) {
																onChange(
																	newValue.value,
																);
															}
														}}
														label={'Time Zone'}
														placeholder={
															'Select Timezone'
														}
														options={
															timezoneOptions
														}
														value={timezoneOptions.find(
															it =>
																it.value ===
																value,
														)}
													/>
												)}
												name={'timeZoneId'}
												control={control}
											/>
										</Stack>
										{/*<Flex*/}
										{/*	direction={{*/}
										{/*		base: 'column',*/}
										{/*		md: 'row',*/}
										{/*	}}*/}
										{/*	gap={'16px'}>*/}
										{/*	<Controller*/}
										{/*		render={({*/}
										{/*			field: { value, onChange },*/}
										{/*			formState: { errors },*/}
										{/*		}) => (*/}
										{/*			<CustomReactSelect*/}
										{/*				errMsg={*/}
										{/*					errors.timeZoneId*/}
										{/*						?.message*/}
										{/*				}*/}
										{/*				onChange={(*/}
										{/*					newValue: SingleValue<any>,*/}
										{/*				) => {*/}
										{/*					if (newValue) {*/}
										{/*						onChange(*/}
										{/*							newValue.value,*/}
										{/*						);*/}
										{/*					}*/}
										{/*				}}*/}
										{/*				label={'Time Zone'}*/}
										{/*				placeholder={*/}
										{/*					'Select Timezone'*/}
										{/*				}*/}
										{/*				options={*/}
										{/*					timezoneOptions*/}
										{/*				}*/}
										{/*				value={timezoneOptions.find(*/}
										{/*					it =>*/}
										{/*						it.value ===*/}
										{/*						value,*/}
										{/*				)}*/}
										{/*			/>*/}
										{/*		)}*/}
										{/*		name={'timeZoneId'}*/}
										{/*		control={control}*/}
										{/*	/>*/}
										{/*	<Box*/}
										{/*		w="100%"*/}
										{/*		display={{*/}
										{/*			base: 'none',*/}
										{/*			md: 'block',*/}
										{/*		}}*/}
										{/*	/>*/}
										{/*</Flex>*/}
									</VStack>
								</Box>
								{id ? (
									<Box bg={'white'} borderRadius={'8px'}>
										<Box
											borderBottom={'1px solid #F1F4F9'}
											p={'16px'}>
											<Text fontWeight={600}>
												Stripe Status
											</Text>
										</Box>
										<Box p={'16px'}>
											<StripeStatusConnection
												id={id}
												isAdded={
													data?.value?.businessEntity
														?.isStripeAdded || false
												}
												isCompleted={
													data?.value?.businessEntity
														?.isStripeCompleted ||
													false
												}
												stripeEmail={
													data?.value?.businessEntity
														?.stripeEmail || null
												}
												onOpenDisconnectDialog={
													onOpenDisconnectDialog
												}
											/>
										</Box>
									</Box>
								) : null}
								<Button
									isLoading={
										createEntity.isPending ||
										updateEntity.isPending
									}
									isDisabled={createEntity.isPending}
									type={'submit'}
									maxW={{ base: 'full', md: '200px' }}
									w={'full'}
									variant={'cruzctrl-solid'}
									alignSelf={'flex-end'}>
									Submit
								</Button>
							</VStack>
						</form>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
