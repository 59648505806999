import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { TenantDetailsType } from '../../types';
import { useQuery } from '@tanstack/react-query';

export const useGetTenantDetails = (id: number) => {
	return useQuery({
		enabled: !!id,
		queryKey: [CruzQueryKeys.TenantDetails, id],
		queryFn: (): Promise<ApiResponse<TenantDetailsType>> =>
			API_CTRL.get(`/api/app/tenants/${id}`, {}),
	});
};
