/* eslint-disable indent */
import React from 'react';
import Downshift from 'downshift';
import { Box, Center, List, ListItem, Text } from '@chakra-ui/react';
import { InputText } from '../InputText/InputText';
import { Loader } from '../Loader/Loader';
import { SearchIcon } from '../../assets';
import { colors } from '../../theme';

interface IAutoCompleteProps {
	errorMsg?: string;
	label?: string;
	onSelectItem: (item: { id: number; name: string }) => void;
	// value: string;
	setValue: (value: string) => void;
	items: { id: number; name: string }[] | undefined;
	placeholder?: string;
	clearItems?: VoidFunction;
	isLoading?: boolean;
	rightIcon?: JSX.Element;
	onRightIconClick?: VoidFunction | null;
	name?: string;
	clearValues?: string[];
	disabledInputs?: {
		[key: string]: boolean;
	};
	clearInputs?: (
		e: React.ChangeEvent<HTMLInputElement>,
		fn: any,
		name: string,
	) => void;
	inputWidth?: string;
}

export const AutoComplete: React.FC<IAutoCompleteProps> = ({
	errorMsg,
	label,
	onSelectItem,
	items,
	placeholder,
	// clearItems,
	isLoading,
	// value,
	setValue,
	rightIcon,
	onRightIconClick,
	name,
	clearValues,
	disabledInputs,
	clearInputs,
	inputWidth = '420px',
}) => {
	return (
		<Downshift
			onChange={selection => {
				onSelectItem(selection);
				// setValue('');
				// clearItems?.();
			}}
			onStateChange={obj => {
				if (obj.type === Downshift.stateChangeTypes.changeInput) {
					setValue(obj.inputValue || '');
				}
			}}
			itemToString={item => (item ? item.name : '')}>
			{({
				getInputProps,
				getItemProps,
				getMenuProps,
				highlightedIndex,
				isOpen,
				clearSelection,
				toggleMenu,
				closeMenu,
			}) => (
				<div
					style={{
						display: 'inline-block',
						width: '100%',
						position: 'relative',
					}}>
					{/* @ts-ignore */}
					<InputText
						{...getInputProps({
							onChange: e => {
								if (clearInputs && name) {
									clearInputs(e, clearSelection, name);
								}
							},

							onClick: e => {
								if (e.target instanceof HTMLInputElement) {
									toggleMenu();
								}
							},
						})}
						leftIcon={<SearchIcon />}
						w={inputWidth}
						border={`1px solid ${colors.dropBlue}`}
						h={'46px'}
						_focus={{
							boxShadow: '0px 0px 10px 0px #BDCDE9B2',
						}}
						focusBorderColor={'transparent'}
						_hover={{ border: `1px solid ${colors.dropBlue}` }}
						placeholder={placeholder}
						label={label}
						errorMsg={errorMsg}
						bg="white"
						rightIcon={rightIcon}
						onRightIconClick={onRightIconClick}
						clearSelection={clearSelection}
						name={name}
						clearValues={clearValues}
						disabledInputs={disabledInputs}
						toggleMenu={toggleMenu}
						// value={value}
					/>
					<Box
						bg="white"
						mt={'4px'}
						boxShadow="md"
						borderRadius={'10px'}
						padding={0}
						w={'100%'}
						maxW={inputWidth}
						p={isOpen ? (items?.length ? '8px' : '0px') : '0px'}
						position="absolute"
						zIndex={50}>
						{isLoading ? (
							<Loader centerHeight={'100px'} spinnerSize={'md'} />
						) : (
							<List
								{...getMenuProps()}
								maxHeight="254px"
								className={'custom-scroll2'}
								overflowY="scroll">
								{isOpen ? (
									items?.length ? (
										items?.map((item, index) => (
											<ListItem
												borderRadius={'8px'}
												key={`${item.name}${index}`}
												cursor="pointer"
												onClick={closeMenu}
												px={'8px'}
												py={'13.5px'}
												backgroundColor={
													highlightedIndex === index
														? 'blue.100'
														: 'white'
												}
												{...getItemProps({
													key: `${item.name}${index}`,
													item,
													index,
												})}>
												<Text
													noOfLines={1}
													color={
														highlightedIndex ===
														index
															? 'white'
															: 'blue.300'
													}
													fontSize={'16px'}
													variant={'bodyMedium'}>
													{item.name}
												</Text>
											</ListItem>
										))
									) : (
										<Center h={'62px'}>
											<Text color="inactiveText">
												No options
											</Text>
										</Center>
									)
								) : null}
							</List>
						)}
					</Box>
				</div>
			)}
		</Downshift>
	);
};
