import { useMutation } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { MessengerQueryKeys } from './query-keys';
import { ApiResponse } from '../../../types';

export const useCheckConversationExist = () => {
	return useMutation<
		ApiResponse<{ conversationId: number }>,
		unknown,
		number
	>({
		mutationFn: receiverUserId =>
			API_CHAT.post('/api/app/conversation/exists', { receiverUserId }),
		mutationKey: [MessengerQueryKeys.CHECK_CONVERSATION_EXIST],
		onSuccess(data) {
			if (!data.success) {
				return;
			}
		},
		onError(error) {
			console.log(error, 'er');
		},
	});
};
