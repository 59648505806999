import { Flex, FlexProps, Text } from '@chakra-ui/react';
import React, { FC } from 'react';

interface StateAbbreviationBoxProps extends FlexProps {
	stateCode?: string;
}

export const StateAbbreviationBox: FC<StateAbbreviationBoxProps> = ({
	stateCode,
	...etc
}) => {
	return (
		<Flex
			bg={'blue.200'}
			borderRadius={'50%'}
			justify={'center'}
			align={'center'}
			color={'white'}
			boxSize={'40px'}
			fontSize={'16px'}
			_groupHover={{
				bg: 'white',
			}}
			{...etc}>
			<Text
				color={'white'}
				fontSize={'inherit'}
				_groupHover={{
					color: 'blue.300',
				}}>
				{stateCode}
			</Text>
		</Flex>
	);
};
