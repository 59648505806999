/* eslint-disable indent */

import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';

type TimeUnit = 'day' | 'month' | 'year';

dayjs.extend(isBetween);

export function isNewDay(date1: Date, date2: Date) {
	return !dayjs(date1).isSame(dayjs(date2), 'day');
}

export function chatDividerFormat(date: Date) {
	return dayjs(date).format('MMMM D, YYYY');
}

export function getTimeDifference(date1: Date, date2: Date) {
	return dayjs(date1).diff(dayjs(date2), 'minutes');
}

export function getTime(date: Date) {
	return dayjs(date).format('hh:mm A');
}

export function getTimeAndDate(date: Date) {
	return dayjs(date).format('MM.DD.YYYY, hh:mm A');
}

export function isDateBetween(
	dateToCheck?: Date,
	startDate?: Date,
	endDate?: Date,
) {
	if (!dateToCheck) {
		return false;
	}
	const date = dayjs(dateToCheck);
	const start = dayjs(startDate);
	const end = dayjs(endDate);

	return date.isBetween(start, end, 'seconds', '(]');
}

export const getISODateRange = (
	date: string | number,
	unit: TimeUnit = 'day',
) => {
	const dateObj =
		unit === 'month'
			? dayjs().month(date as number)
			: unit === 'year'
			? dayjs().year(date as number)
			: dayjs(date);

	const startDate = dateObj.startOf(unit).toISOString();
	const endDate =
		unit === 'year'
			? dateObj.year() === dayjs().year()
				? dayjs().toISOString()
				: dateObj.endOf(unit).toISOString()
			: dateObj.endOf(unit).toISOString();

	return { startDate, endDate };
};

export const getYearsUntilNow = (startYear: number) => {
	const currentYear = dayjs().year();
	const years: { label: string; value: number }[] = [];

	for (let year = startYear; year <= currentYear; year++) {
		years.push({ label: year.toString(), value: year });
	}

	return years;
};

export const isValidDate = (dateString: string): boolean => {
	const date = dayjs(dateString, 'MM.DD.YYYY', true);
	return date.isValid() && date.isBefore(dayjs());
};
