import { useDisclosure } from '@chakra-ui/react';
import React, { ReactNode, useEffect } from 'react';
import { PandaDocModal } from './components/PandaDoc.modal';
import { OnboardingFormModal } from '../OnboardingFormModal/OnboardingFormModal';
import { useCheckTOSStatusQuery } from './queries';

interface IPandaDocContainerProps {
	children: ReactNode;
	isAgreementSigned: boolean;
	isGuest: boolean;
}
export const PandaDocContainer: React.FC<IPandaDocContainerProps> = ({
	isAgreementSigned,
	isGuest,
	children,
}) => {
	const {
		isOpen: pandaOpen,
		onOpen: onPandaOpen,
		onClose: onPandaClose,
	} = useDisclosure();

	const { data } = useCheckTOSStatusQuery(isGuest);

	useEffect(() => {
		if (!isAgreementSigned && !isGuest) {
			onPandaOpen();
		}
	}, [isAgreementSigned, isGuest]);

	return (
		<>
			{children}
			{pandaOpen ? (
				<PandaDocModal
					isOpen={pandaOpen}
					onClose={onPandaClose}
					isAgreementSigned={data?.value?.isAgreementSigned}
				/>
			) : null}
			<OnboardingFormModal
				isOpen={
					!!(
						data?.value?.isAgreementSigned &&
						!data?.value?.isCampaignFieldsSet
					)
				}
			/>
		</>
	);
};
