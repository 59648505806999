import React from 'react';
import { colors } from '../../theme';
import { BaseIconType } from '../../types';

export const PaperclipIcon: React.FC<BaseIconType> = ({
	width = 24,
	height = 24,
	stroke = colors.blue[200],
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M21.1527 10.899L12.1371 19.9146C10.0869 21.9648 6.76275 21.9648 4.71249 19.9146C2.66224 17.8643 2.66224 14.5402 4.71249 12.49L13.7281 3.47435C15.0949 2.10751 17.311 2.10751 18.6779 3.47434C20.0447 4.84118 20.0447 7.05726 18.6779 8.42409L10.0158 17.0862C9.33238 17.7696 8.22434 17.7696 7.54092 17.0862C6.8575 16.4027 6.8575 15.2947 7.54092 14.6113L15.1423 7.00988"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
