import { useState, useEffect } from 'react';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { UserPresenceItem, UserPresenceStatus } from '../types';

export type PollingResult = {
	[key: string]: UserPresenceStatus;
};

export const usePolling = (userIds: number[], enableFetch: boolean) => {
	const [result, setResult] = useState<PollingResult | null>(null);

	useEffect(() => {
		let interval: NodeJS.Timeout;
		if (enableFetch && userIds.length) {
			interval = setInterval(async () => {
				try {
					const res: ApiResponse<UserPresenceItem[]> =
						await API_CHAT.post(
							'/api/app/users/online-status',
							userIds,
						);
					if (!res.success || !res.value) {
						return;
					}

					setResult(
						res.value.reduce(
							(result: PollingResult, obj: UserPresenceItem) => {
								result[`${obj.userId}`] = obj.status;
								return result;
							},
							{},
						),
					);
				} catch (error) {
					console.log('polling error:', error);
				}
			}, 90 * 1000);
		}

		return () => {
			clearInterval(interval);
		};
	}, [userIds, enableFetch]);

	return result;
};
