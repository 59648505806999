import { create } from 'zustand';
import { JumpToPresentConditionEnum } from '../pages/Messenger/types';

interface IState {
	forcedLoading: boolean;
	setForcedLoading: (value: boolean) => void;
	messageAnchorId: number;
	setMessageAnchorId: (messageId: number) => void;
	jumpToPresentCondition: JumpToPresentConditionEnum;
	setJumpToPresentCondition: (
		jumpToPresentCondition: JumpToPresentConditionEnum,
	) => void;
}

export const useMessageNavigationStore = create<IState>(set => ({
	messageAnchorId: 0,
	jumpToPresentCondition: JumpToPresentConditionEnum.Hidden,
	forcedLoading: false,
	setMessageAnchorId: messageId =>
		set(() => ({
			messageAnchorId: messageId,
		})),
	setJumpToPresentCondition: jumpToPresentCondition =>
		set(() => ({ jumpToPresentCondition })),
	setForcedLoading: forcedLoading => set(() => ({ forcedLoading })),
}));
