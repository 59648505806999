import { useMutation, useQueryClient } from '@tanstack/react-query';
import { API_CTRL } from '../../../../api/api.base';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { BusinessEntityPayload } from '../../types';
import { CruzQueryKeys } from '../query-keys';

interface IPayload {
	id: number;
	data: BusinessEntityPayload;
}

export const useUpdateBusinessEntity = () => {
	const queryClient = useQueryClient();

	return useMutation<ApiResponseEmpty, unknown, IPayload>({
		mutationFn: payload =>
			API_CTRL.put(
				`/api/app/business-entity/${payload.id}/update`,
				payload.data,
			),
		onSuccess: (data, { id }) => {
			if (data.success) {
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.BusinessEntities, false],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.BusinessEntity, id],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.BusinessEntitySelector],
				});
				Alerter.success('Updated successfully');
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};
