import { useQuery } from '@tanstack/react-query';
import { MessengerQueryKeys } from './query-keys';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ConversationHistoryItem } from '../types';

export const useFindChannelMessage = (
	messageId: string | undefined | null,
	channelId: number,
) => {
	return useQuery<
		ApiResponse<{
			messages: ConversationHistoryItem[];
			userLastReadDateTime: string;
			unreadCount: number;
		}>
	>({
		queryKey: [
			MessengerQueryKeys.FIND_CHANNEL_MESSAGE,
			messageId,
			channelId,
		],
		queryFn: () =>
			API_CHAT.get(`/api/app/channel/messages/${messageId}/find`, {
				ChannelId: channelId,
				PageSize: 25,
			}),
		enabled: !!messageId,
	});
};
