import { useMutation } from '@tanstack/react-query';
import { Alerter } from '../../../utils';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';

export const useCancelFriendRequest = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: (id: number) =>
			API_CHAT.post('/api/app/friend/cancel-request', {
				friendRequestId: id,
			}),
		onSuccess(data) {
			if (!data.success) {
				return Alerter.error('Error while cancelling');
			}
			Alerter.success('Request cancelled');
		},

		onError(error) {
			console.log('useCancelFriendRequest', error);
		},
	});
};
