/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, {
	BaseSyntheticEvent,
	Dispatch,
	SetStateAction,
	useEffect,
	useRef,
} from 'react';
import {
	Box,
	chakra,
	Text,
	FormControl,
	FormErrorMessage,
	// HStack,
} from '@chakra-ui/react';
import { useForm, Controller } from 'react-hook-form';
import { editMessageResolver, EditMessageForm } from '../../../validation';
import { queryMentionUsers } from '../../../utils';
import { MentionsInput, Mention } from '../../../../../components/Mentions';
import { useChatUserStore } from '../../../../../store';
import { colors } from '../../../../../theme';
import { TextEditor } from '../../TextEditor';
import { useTextEditor } from '../../../hooks/useTextEditor';
// import { EmojiPicker } from '../../../common-components';

interface IMessageEditProps {
	text: string;
	onCancelEdit?: VoidFunction;
	onSubmitEdit: (newText: string) => void;
	enableMentions?: boolean;
}

export const MessageEdit: React.FC<IMessageEditProps> = ({
	text,
	onCancelEdit,
	onSubmitEdit,
	enableMentions,
}) => {
	const { user } = useChatUserStore();
	const inputRef = useRef<HTMLInputElement>(null);

	const {
		formState: { errors },
		handleSubmit,
		control,
		watch,
		setValue,
	} = useForm<EditMessageForm>({
		resolver: editMessageResolver,
		defaultValues: { text: text },
	});

	const {
		selectedText,
		appliedStyles,
		handleTextSelection,
		replaceSelection,
		toggleQuoteOnLine,
		showPreview,
	} = useTextEditor(inputRef, watch('text'), ((value: string) =>
		setValue('text', value)) as Dispatch<SetStateAction<string>>);

	const onSubmit = (data: EditMessageForm) => {
		onSubmitEdit(data.text);
		onCancelEdit?.();
	};

	useEffect(() => {
		const handleKeyDown = (event: any) => {
			if (event.key === 'Escape' || event.keyCode === 27) {
				onCancelEdit?.();
			}
		};

		window.addEventListener('keydown', handleKeyDown);

		return () => window.removeEventListener('keyDown', handleKeyDown);
	}, []);

	return (
		<Box w="100%">
			<form onSubmit={handleSubmit(onSubmit)}>
				<Box position="relative">
					<Controller
						name="text"
						control={control}
						render={({ field }) => (
							<FormControl
								flex={1}
								isInvalid={!!errors.text?.message}>
								<TextEditor
									toggleQuoteOnLine={toggleQuoteOnLine}
									replaceSelection={replaceSelection}
									selectedText={selectedText}
									appliedStyles={appliedStyles}
									showPreview={showPreview}
								/>
								<MentionsInput
									inputRef={inputRef}
									onSelect={(event: BaseSyntheticEvent) => {
										handleTextSelection(event);
									}}
									value={field.value}
									onChange={(val: any) =>
										field.onChange(val.target.value)
									}
									forceSuggestionsAboveCursor={true}
									placeholder="Enter message"
									autoFocus={true}
									onFocus={(
										e: React.FocusEvent<HTMLInputElement>,
									) => {
										if (e.currentTarget) {
											e.currentTarget.setSelectionRange(
												e.currentTarget.value.length,
												e.currentTarget.value.length,
											);
										}
									}}
									onKeyDown={(
										ev: React.KeyboardEvent<HTMLInputElement>,
									) => {
										if (
											ev.key === 'Enter' &&
											!ev.shiftKey
										) {
											handleSubmit(onSubmit)();
										}
									}}
									style={{
										borderRadius: '4px',
										color: colors.blue[200],
										padding: '10px',
										backgroundColor: colors.mainBg,
										border: 'none',
									}}>
									<Mention
										displayTransform={(
											id: string,
											display: string,
										) => `@${display}`}
										trigger="@"
										data={
											enableMentions
												? (
														q: string,
														callback: (
															val: any[],
														) => void,
												  ) =>
														queryMentionUsers(
															q,
															callback,
															user?.userId,
															user?.isAdmin,
														)
												: []
										}
										appendSpaceOnAdd={true}
										// isLoading={true}
									/>
								</MentionsInput>
								<FormErrorMessage mt={'5px'}>
									{errors.text?.message}
								</FormErrorMessage>
							</FormControl>
						)}
					/>
					{/* <EmojiPicker
						onChange={emoji => {
							// setMessage(prev =>
							// 	// 	`${prev.trim()} ${emoji}`.trim(),
							// 	// );
							// );
							setValue(
								'text',
								`${watch('text')} ${emoji}`.trim(),
							);
						}}
						isButtonLoading={false}
					/> */}
					{/* <CustomInput
					{...register('text')}
					autoFocus={true}
					borderRadius="4px"
					bg="mainBg"
					border="none"
					minH="40px"
					errorMsg={errors.text?.message}
					color="black"
				/> */}
				</Box>
				<Text
					fontSize="14px"
					lineHeight="17px"
					color="blue.200"
					mt="5px">
					<chakra.span fontWeight={700} color="success">
						escape
					</chakra.span>{' '}
					to cancel,{' '}
					<chakra.span fontWeight={700} color="success">
						enter
					</chakra.span>{' '}
					to save
				</Text>
			</form>
		</Box>
	);
};
