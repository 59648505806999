import { useCallback } from 'react';
import { Message } from 'ably';
import { useQueryClient } from '@tanstack/react-query';
import { MessengerQueryKeys } from '../pages/Messenger/queries/query-keys';
import { ApiResponse } from '../types';
import { ServerCategoryData } from '../pages/Messenger/types';
import { QueryKeys } from '../constants';

enum Events {
	ADMIN_UNREAD_COUNTER = 'admin-unread-counter',
	NEW_MESSAGE_IN_CHANNEL = 'new-channel-message-counter',
	CHANNEL_UNREAD_COUNTER = 'channel-unread-counter',
	CONVERSATION_UNREAD_COUNTER = 'conversation-unread-counter',
}

type CategoriesResponse = ApiResponse<{
	serverCategories: ServerCategoryData[];
}>;

type CounterResponse = ApiResponse<{
	amountUnreadCommunity: number;
	amountUnreadAdminMessages: number;
}>;

type CounterTabsResponse = ApiResponse<{
	amountUnreadConversations: number;
	amountUnreadChannels: number;
}>;

export const useHandleUnreadSocketEvent = () => {
	const queryClient = useQueryClient();

	const handleUnreadSocketEvent = useCallback((message: Message) => {
		console.log(message.name, 'handleUnreadSocketEvent');
		switch (message.name) {
			case Events.CONVERSATION_UNREAD_COUNTER:
				queryClient.setQueryData<CounterResponse | undefined>(
					[QueryKeys.UNREAD_SIDEBAR_COUNTER],
					prev => {
						if (!prev) {
							return prev;
						}
						return {
							...prev,
							value: {
								...prev.value,
								amountUnreadCommunity:
									prev.value.amountUnreadCommunity + 1,
							},
						};
					},
				);
				queryClient.setQueryData<CounterTabsResponse | undefined>(
					[QueryKeys.UNREAD_TABS_COUNTER],
					prev => {
						if (!prev) {
							return prev;
						}
						return {
							...prev,
							value: {
								...prev.value,
								amountUnreadConversations:
									prev.value.amountUnreadConversations + 1,
							},
						};
					},
				);
				break;
			case Events.CHANNEL_UNREAD_COUNTER:
				queryClient.setQueryData<CounterResponse | undefined>(
					[QueryKeys.UNREAD_SIDEBAR_COUNTER],
					prev => {
						if (!prev) {
							return prev;
						}
						return {
							...prev,
							value: {
								...prev.value,
								amountUnreadCommunity:
									prev.value.amountUnreadCommunity + 1,
							},
						};
					},
				);
				queryClient.setQueryData<CounterTabsResponse | undefined>(
					[QueryKeys.UNREAD_TABS_COUNTER],
					prev => {
						if (!prev) {
							return prev;
						}
						return {
							...prev,
							value: {
								...prev.value,
								amountUnreadChannels:
									prev.value.amountUnreadChannels + 1,
							},
						};
					},
				);
				break;
			case Events.ADMIN_UNREAD_COUNTER:
				queryClient.setQueryData<CounterResponse | undefined>(
					[QueryKeys.UNREAD_SIDEBAR_COUNTER],
					prev => {
						if (!prev) {
							return prev;
						}
						return {
							...prev,
							value: {
								...prev.value,
								amountUnreadAdminMessages:
									prev.value.amountUnreadAdminMessages + 1,
							},
						};
					},
				);
				break;
			case Events.NEW_MESSAGE_IN_CHANNEL:
				queryClient.setQueryData<CategoriesResponse | undefined>(
					[MessengerQueryKeys.GET_SERVER_CATEGORIES],
					prev => {
						if (prev?.value) {
							return {
								...prev,
								value: {
									serverCategories:
										prev.value.serverCategories.map(
											category => {
												return {
													...category,
													channels:
														category.channels.map(
															channel => {
																if (
																	channel.id ===
																	Number(
																		message.data,
																	)
																) {
																	return {
																		...channel,
																		amountUnreadMessages:
																			channel.amountUnreadMessages +
																			1,
																	};
																}
																return channel;
															},
														),
												};
											},
										),
								},
							};
						}
						return prev;
					},
				);
				break;
			default:
				break;
		}
	}, []);

	return { handleUnreadSocketEvent };
};
