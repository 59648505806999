import React, { useMemo } from 'react';
import { Flex, Text, Button, Spinner } from '@chakra-ui/react';
import { ChevronDownIcon } from '../../../assets';
import { useMessageNavigationStore } from '../../../store';
import { JumpToPresentConditionEnum } from '../types';

//* there will be additional condition for direct chat in future

interface IMessageJumpToPresent {
	isButtonDisabled: boolean;
}

export const MessageJumpToPresent: React.FC<IMessageJumpToPresent> = ({
	isButtonDisabled,
}) => {
	const { jumpToPresentCondition, setJumpToPresentCondition } =
		useMessageNavigationStore();

	const isVisible = useMemo(() => {
		const { Hidden, ReplyMessageJump } = JumpToPresentConditionEnum;
		return (
			jumpToPresentCondition !== Hidden &&
			jumpToPresentCondition !== ReplyMessageJump
		);
	}, [jumpToPresentCondition]);
	const isLoading = useMemo(
		() => jumpToPresentCondition === JumpToPresentConditionEnum.Loading,
		[jumpToPresentCondition],
	);

	return (
		<Flex
			height={isVisible ? '40px' : '0px'}
			visibility={isVisible ? 'visible' : 'hidden'}
			opacity={isVisible ? 1 : 0}
			transition="height .2s ease, visibility .2s ease, opacity .2s ease, padding-top .2s ease, padding-bottom .2s ease"
			alignItems="center"
			justify="space-between"
			p={isVisible ? '10px 20px' : '0px 20px'}
			bg="blue.100"
			borderRadius="10px 10px 0px 0px">
			<Text
				fontSize="14px"
				lineHeight="20px"
				color="white"
				fontWeight={700}>
				You&apos;re viewing older messages
			</Text>
			<Button
				variant="ghost"
				px={0}
				h="20px"
				fontWeight={700}
				iconSpacing="5px"
				fontSize="14px"
				lineHeight="20px"
				color="white"
				isDisabled={isLoading || isButtonDisabled}
				rightIcon={
					isLoading ? (
						<Spinner width="20px" height="20px" />
					) : (
						<ChevronDownIcon />
					)
				}
				onClick={() =>
					setJumpToPresentCondition(
						JumpToPresentConditionEnum.StartConversationJump,
					)
				}
				_hover={{
					textDecoration: 'underline',
				}}
				_active={{
					bg: 'transparent',
				}}>
				Jump To Present
			</Button>
		</Flex>
	);
};
