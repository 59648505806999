import { useMutation } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useMarkAsReadConversation = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ conversationId: number; dateTime: string }
	>({
		mutationKey: [MessengerQueryKeys.MARK_AS_READ_CONVERSATION],
		mutationFn: payload =>
			API_CHAT.post(
				'/api/app/conversation/messages/private/read',
				payload,
			),

		onError(error) {
			console.log(error, 'mark-as-read-conversation error');
		},
	});
};
