import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { EntitySelectType } from '../../types';
import { useQuery } from '@tanstack/react-query';

export const useGetBusinessEntitySelector = (searchString?: string) => {
	return useQuery<
		ApiResponse<{
			items: EntitySelectType[];
		}>
	>({
		queryKey: [CruzQueryKeys.BusinessEntitySelector, searchString],
		queryFn: () =>
			API_CTRL.get('/api/app/selector/business-entity', {
				SearchString: searchString,
			}),
	});
};
