import { useState } from 'react';
import { ApiResponseEmpty } from '../../../types';
import { API_CHAT } from '../../../api/api.base';
import { Alerter } from '../../../utils';
import { useQueryClient } from '@tanstack/react-query';
import { MessengerQueryKeys } from './query-keys';

export const usePinMessage = () => {
	const [isLoading, setIsLoading] = useState(false);
	const queryClient = useQueryClient();
	const pinMessage = async (messageId: number, channelId: number) => {
		setIsLoading(true);
		const result: ApiResponseEmpty = await API_CHAT.put(
			`/api/admin/channel/message/${messageId}/pin`,
			{},
		);

		if (result.success) {
			Alerter.success(result.messages[0]?.message || 'Message pinned');

			queryClient.invalidateQueries({
				queryKey: [
					MessengerQueryKeys.GET_CHANNEL_PINNED_MESSAGES,
					channelId,
				],
			});
		} else {
			Alerter.error(
				result.errors[0]?.message || 'Error while pinning message',
			);
		}

		setIsLoading(false);
		return result;
	};

	return {
		pinMessage,
		isLoading,
	};
};
