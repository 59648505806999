import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { PropertyExtendInfoType } from '../../types';
import { useQuery } from '@tanstack/react-query';

export const useGetPropertyExtendInfo = (propertyId: number) => {
	return useQuery<ApiResponse<PropertyExtendInfoType>>({
		enabled: !!propertyId,
		queryKey: [CruzQueryKeys.PropertyExtendInfo, propertyId],
		queryFn: () =>
			API_CTRL.get(`/api/app/properties/${propertyId}/extend-info`, {}),
	});
};
