import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import './index.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from './components';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			refetchOnWindowFocus: false,
		},
	},
});

if (import.meta.env.VITE_MODE !== 'development') {
	Sentry.init({
		dsn: import.meta.env.VITE_SENTRY_DCN,
		integrations: [new Sentry.BrowserTracing({}), new Sentry.Replay()],
		tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
		replaysSessionSampleRate: 0.1,
		replaysOnErrorSampleRate: 1.0,
		environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
		ignoreErrors: [
			'TypeError: Failed to fetch',
			'TypeError: NetworkError when attempting to fetch resource.',
			'AbortError: The operation was aborted.',
			'Error: No matching state found in storage',
			'No matching state found in storage',
		],
		denyUrls: [
			//used to do not capture exception from third party recourses
			// Chrome extensions
			/extensions\//i,
			/^chrome:\/\//i,
			/^chrome-extension:\/\//i,
		],
	});
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
	<BrowserRouter>
		<QueryClientProvider client={queryClient}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
			<ReactQueryDevtools initialIsOpen={false} />
		</QueryClientProvider>
	</BrowserRouter>,
);
