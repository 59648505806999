import { create } from 'zustand';
import {
	SearchContentTypeOptionEnum,
	ChannelSearchUserFilterEnum,
} from '../pages/Messenger/types';

interface IInitialState {
	search: string;
	authorId?: number;
	nickName: string;
	fromDateTime?: string;
	toDateTime?: string;
	mentionId?: number;
	contentType?: SearchContentTypeOptionEnum;
	pinned?: boolean;
	userFilter?: ChannelSearchUserFilterEnum;
	isEnabledSearchMessages: boolean;
	isEnabledSearchUsers: boolean;
}
interface IState extends IInitialState {
	onUpdateQueries: (queries: Partial<IState>) => void;
}

const initialState = {
	search: '',
	nickName: '',
	authorId: undefined,
	fromDateTime: undefined,
	contentType: undefined,
	mentionId: undefined,
	pinned: undefined,
	toDateTime: undefined,
	userFilter: undefined,
	isEnabledSearchMessages: false,
	isEnabledSearchUsers: true,
};

export const useChannelSearchStore = create<IState>(set => ({
	...initialState,
	onUpdateQueries: (queries: Partial<IInitialState>) =>
		set(() => ({
			...initialState,
			...queries,
		})),
}));
