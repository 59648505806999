import { useQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { NotificationSettingItemType } from '../types';
import { MessengerQueryKeys } from './query-keys';

interface IPayload {
	isEnabled: boolean;
}

export const useGetNotificationSetting = ({ isEnabled }: IPayload) => {
	return useQuery<ApiResponse<NotificationSettingItemType>>({
		queryKey: [MessengerQueryKeys.GET_NOTIFICATION_SETTING],
		queryFn: () => API_CHAT.get('/api/app/settings/notifications', {}),
		// cacheTime: 0,
		enabled: isEnabled,
	});
};
