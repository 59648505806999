import React from 'react';
import {
	Menu,
	MenuButton,
	MenuList,
	MenuItem,
	IconButton,
} from '@chakra-ui/react';
import { FiMoreVertical } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import { HREFs } from '../../../constants';

interface IRoleInfoPopupMenuProps {
	isUserBlocked?: boolean;
	userId: number;
	onBlock: VoidFunction;
	unBlock: VoidFunction;
	isAdmin: boolean;
	isDirectChat: boolean;
}

export const RoleInfoPopupMenu: React.FC<IRoleInfoPopupMenuProps> = ({
	isUserBlocked,
	userId,
	onBlock,
	unBlock,
	isAdmin,
	isDirectChat,
}) => {
	return (
		<Menu placement={'left'}>
			<MenuButton
				ml={'auto'}
				size={'sm'}
				bg={'transparent'}
				_active={{
					bg: 'blackAlpha.50',
				}}
				_hover={{
					bg: 'blackAlpha.50',
				}}
				border={'none'}
				variant="outline"
				aria-label={'Options'}
				icon={<FiMoreVertical color={'white'} />}
				as={IconButton}
			/>
			<MenuList bg="mainBg" p="20px" borderRadius="10px" border={'none'}>
				{!isAdmin ? (
					isUserBlocked ? (
						<MenuItem
							onClick={unBlock}
							bg="white"
							borderRadius="4px"
							color="blue.200"
							fontWeight={500}
							fontSize="16px"
							_hover={{
								bg: 'blue.200',
								color: 'white',
							}}>
							Unblock
						</MenuItem>
					) : (
						<MenuItem
							onClick={onBlock}
							bg="white"
							borderRadius="4px"
							fontWeight={500}
							fontSize="16px"
							color={'#C14B4B'}
							_hover={{
								bg: '#C14B4B',
								color: 'white',
							}}>
							Block
						</MenuItem>
					)
				) : null}
				{isDirectChat ? null : !isUserBlocked ? (
					<MenuItem
						as={Link}
						to={HREFs.DIRECT + '/' + userId}
						bg="white"
						color="blue.200"
						borderRadius="4px"
						fontWeight={500}
						fontSize="16px"
						_hover={{
							bg: 'blue.200',
							color: 'white',
						}}>
						Message
					</MenuItem>
				) : null}
			</MenuList>
		</Menu>
	);
};
