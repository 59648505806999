import React from 'react';
import { HStack, SkeletonCircle, Skeleton } from '@chakra-ui/react';

interface ISkeletonUserSuggestionsList {
	length: number;
}

export const SkeletonUserSuggestionsList: React.FC<
	ISkeletonUserSuggestionsList
> = ({ length }) => {
	return (
		<React.Fragment>
			{Array.from({ length }, (_, index) => (
				<HStack key={index} spacing={1} p="8px 10px" w="full">
					<SkeletonCircle boxSize="24px" minW="24px" />
					<Skeleton w="full" height="19px" />
				</HStack>
			))}
		</React.Fragment>
	);
};
