export type BreadCrumbsItem = {
	label: string;
	route: string | null;
	routeParams?: object;
};

export enum Role {
	Admin = 1,
	User = 2,
	Guest = 3,
}

export interface ICurrentUser {
	firstName: string;
	lastName: string;
	email: string;
	permissionId?: number;
	isAgreementSigned: false;
	roleId: Role;
	claimsIds: number[] | null;
	phoneNumber: number;
	imageUrl: string;
	statusId: number;
	isCommunityAvailable: boolean;
	isCommunityInstructionCompleted: boolean;
	userId: number;
}

export enum Claim {
	'FMR Explorer' = 1,
	Courses,
	'City Research',
	'Property Lookup',
	'Deal Calculator',
	'Deal Explorer',
	Messenger,
	Coaching = -1,
	'My Profile' = 1,
	'Billing History' = 1,
	CruzControl = 8,
	TenantFinder = 9,
}

export const DefaultGuestClaims = [
	Claim['FMR Explorer'],
	Claim.Courses,
	Claim['City Research'],
	Claim['Property Lookup'],
	Claim['Deal Calculator'],
	Claim['Deal Explorer'],
];

export type IChatUser = {
	about: null;
	avatarUrl: string | null;
	displayName: string;
	nickName: string;
	userId: number;
	isAdmin: boolean;
	isBannedInChannel: boolean;
	email: string | null;
};

export type UploadProgressState = {
	progress: number;
	totalSize: number;
};

export type SelectOption = {
	label: string;
	value: string | number;
};

export type BaseIconType = {
	width?: number;
	height?: number;
	fill?: string;
	stroke?: string;
};
