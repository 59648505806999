import { getStylePropsForCrimeReportItem } from '../../../utils';
import { Box, Flex } from '@chakra-ui/react';
import { FC } from 'react';

interface StatBarProps {
	styles: ReturnType<typeof getStylePropsForCrimeReportItem>;
}

export const StatBar: FC<StatBarProps> = ({ styles }) => {
	return (
		<Flex w={'100%'} maxW={'302px'} h={'10px'}>
			<Box
				h={'inherit'}
				w={styles.width}
				bg={styles.backgroundColor}
				borderRadius={'5px 0 0 5px'}
				flexShrink={0}
			/>

			<Box
				h={'inherit'}
				bg={'#CBD5DF'}
				w={'100%'}
				borderRadius={styles.width === '0px' ? '5px' : '0 5px 5px 0'}
				flexGrow={1}
			/>
		</Flex>
	);
};
