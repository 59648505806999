import React, { useEffect, useState } from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	Center,
	Text,
	VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { useGetPandaDocDocumentQuery } from '../queries';
import { Loader } from '../../Loader/Loader';
import { useQueryClient } from '@tanstack/react-query';
import { QueryKeys } from '../../../constants';

interface IPandaDocModalProps {
	isOpen: boolean;
	onClose: VoidFunction;
	isAgreementSigned?: boolean;
}

export const PandaDocModal: React.FC<IPandaDocModalProps> = ({
	isOpen,
	onClose,
	isAgreementSigned,
}) => {
	const {
		data: pandaDocDocumentData,
		isLoading: pandaDocDocumentDataLoading,
	} = useGetPandaDocDocumentQuery();

	const queryClient = useQueryClient();

	const [showLink, setShowLink] = useState(false);

	// Function to refetch TOS status
	const refetchData = () => {
		queryClient.invalidateQueries({
			queryKey: [QueryKeys.CHECK_TOS_STATUS],
		});
	};

	useEffect(() => {
		const intervalId = setInterval(refetchData, 2000);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	useEffect(() => {
		if (isAgreementSigned) {
			isAgreementSigned && onClose();
			queryClient.invalidateQueries({
				queryKey: [QueryKeys.CURRENT_USER],
			});
		}
	}, [isAgreementSigned]);

	useEffect(() => {
		if (pandaDocDocumentData?.value) {
			setTimeout(() => {
				setShowLink(true);
			}, 9000);
		}
	}, [pandaDocDocumentData]);

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			closeOnOverlayClick={false}
			closeOnEsc={false}>
			<ModalOverlay />
			<ModalContent
				h={{ base: '75vh', md: '90vh' }}
				w={{ base: '95vw', md: '90vw' }}
				maxW="100vw">
				<ModalBody>
					{showLink ? (
						<Center>
							<Text fontSize={'18px'}>
								{"If your document didn't load "}
								<Link
									style={{
										color: 'blue',
										textDecoration: 'underline',
									}}
									to={pandaDocDocumentData?.value || ''}
									target={'_blank'}>
									click here
								</Link>
							</Text>
						</Center>
					) : null}
					{pandaDocDocumentDataLoading ? (
						<Center mt={10}>
							<VStack>
								<Loader />
								<Text
									fontSize="18px"
									fontWeight={500}
									mt="20px">
									Setting Up Your Section 8 Pro Course Account
								</Text>
							</VStack>
						</Center>
					) : pandaDocDocumentData?.value ? (
						<iframe
							height="100%"
							width="100%"
							src={pandaDocDocumentData?.value}
						/>
					) : (
						<Text>
							{pandaDocDocumentData?.errors?.[0]?.message}
						</Text>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
