import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { colors } from '../../theme';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IStatItemProps {
	name: string;
	value: string | number;
	color?: string;
	isHighlighted?: boolean;
}

export const StatItem: React.FC<IStatItemProps> = ({
	isHighlighted = false,
	name,
	value,
	color,
}) => {
	return (
		<Flex
			flexDirection={'column'}
			justifyContent={'center'}
			maxW={'180px'}
			w={'auto'}
			h={'auto'}
			borderRadius={'30px'}
			bg={isHighlighted ? 'inherit' : 'mainBg'}
			textAlign={'center'}
			border={`2px solid ${isHighlighted ? colors.blue['100'] : 'white'}`}
			gap={'8px'}
			p={'5px 0'}>
			<Text fontWeight={isHighlighted ? 600 : 500}>{name}</Text>
			<Text color={color} fontSize="18px" fontWeight={700}>
				{value}
			</Text>
		</Flex>
	);
};
