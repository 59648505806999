import React, { Dispatch, SetStateAction, useCallback, useState } from 'react';
import { Message } from 'ably';
import { useNavigate } from 'react-router-dom';
import { SOCKET_EVENTS } from '../../constants';
import {
	ChannelMessageReactionMainEvent,
	ChannelMessageReactionUpdateEvent,
	ConversationHistoryItem,
	EditChannelMessageEventData,
	JumpToPresentConditionEnum,
	NewChannelMessageEventData,
	NewChannelPinEventData,
} from '../../types';
import { useChatUserStore, useMessageNavigationStore } from '../../../../store';
import {
	updateMessagesOnDelete,
	updateReactionsOnCreate,
	updateReactionsOnDelete,
	updateReactionsOnEdit,
} from '../../utils';

export const useHandleServerMessagesSocketEvents = ({
	parentRef,
	updateUnreadCount,
	updateLastReadTime,
	id,
	shouldCallMarkAsReadRef,
}: {
	id?: string;
	parentRef: React.RefObject<HTMLDivElement>;
	updateUnreadCount: Dispatch<SetStateAction<number>>;
	updateLastReadTime: (time: string) => void;
	shouldCallMarkAsReadRef: React.MutableRefObject<boolean>;
}) => {
	const { user, setChatUser } = useChatUserStore();
	const { setJumpToPresentCondition } = useMessageNavigationStore();
	const navigate = useNavigate();

	const [newMessages, setNewMessages] = useState<ConversationHistoryItem[]>(
		[],
	);

	const [historyMessages, setHistoryMessages] = useState<
		ConversationHistoryItem[]
	>([]);

	const scrollToBottom = () => {
		setJumpToPresentCondition(JumpToPresentConditionEnum.Hidden);
		parentRef.current?.scrollTo({ top: 0 });
		return;
	};

	const handleServerMessageSocketEvent = useCallback((event: Message) => {
		switch (event.name) {
			case SOCKET_EVENTS.CHANNEL_MESSAGE_EVENT:
				{
					const newMessageData: {
						Value: NewChannelMessageEventData;
					} = JSON.parse(event.data);

					const {
						Text,
						SentAt,
						SenderId,
						ReplyToId,
						MessageId,
						DisplayName,
						AvatarUrl,
						IsAdmin,
						ChannelId,
						Type,
						PinToId,
						IsBannedInChannel,
						AttachmentFiles,
						AttachmentImages,
						AttachmentVideos,
					} = newMessageData.Value;
					if (Number(id) !== ChannelId) {
						return;
					}

					const messagePayload = {
						id: MessageId,
						replyToId: ReplyToId,
						text: Text,
						isEdited: false,
						isSeen: SenderId === user?.userId,
						senderId: SenderId,
						senderName: DisplayName,
						sentAt: SentAt,
						avatarUrl: AvatarUrl,
						type: Type,
						isAdmin: IsAdmin,
						pinToId: PinToId,
						isBannedInChannel: IsBannedInChannel,
						isSenderDeleted: false,
						attachmentFiles: AttachmentFiles.map(attachment => ({
							fileName: attachment.FileName,
							id: attachment.Id,
							extension: attachment.Extension,
							link: attachment.Link,
							storagePath: attachment.StoragePath,
						})),
						attachmentImages: AttachmentImages.map(attachment => ({
							id: attachment.Id,
							extension: attachment.Extension,
							link: attachment.Link,
							storagePath: attachment.StoragePath,
							thumbnailLink: attachment.ThumbnailLink,
							largeImageLink: attachment.LargeImageLink,
						})),
						attachmentVideos: AttachmentVideos.map(attachment => ({
							id: attachment.Id,
							link: attachment.Link,
							storagePath: attachment.StoragePath,
							duration: attachment.Duration,
							fileName: attachment.FileName,
							extension: attachment.Extension,
							height: attachment.Height,
							width: attachment.Width,
						})),
						reactions: [],
					};

					const isCurrentUserSender = SenderId === user?.userId;

					if (isCurrentUserSender) {
						updateUnreadCount(0);

						updateLastReadTime(SentAt);
					}

					setNewMessages(prev => [
						{ ...messagePayload },
						...(prev as ConversationHistoryItem[]),
					]);

					if (!isCurrentUserSender) {
						updateUnreadCount(prev => prev + 1);
						return;
					}
					scrollToBottom();
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_EDIT_EVENT:
				{
					const editedMessageData: {
						Value: EditChannelMessageEventData;
					} = JSON.parse(event.data);

					const { Text, MessageId, ChannelId } =
						editedMessageData.Value;

					if (Number(id) !== ChannelId) {
						return;
					}
					const messagePayload = {
						text: Text,
						isEdited: true,
					};

					setNewMessages(prev =>
						prev.map(msg =>
							msg.id === MessageId
								? { ...msg, ...messagePayload }
								: msg,
						),
					);
					setHistoryMessages(prev =>
						prev.map(msg =>
							msg.id === MessageId
								? { ...msg, ...messagePayload }
								: msg,
						),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_DELETE_EVENT:
				{
					const deletedMessageData: {
						Value: { DeletedMessageId: number };
					} = JSON.parse(event.data);

					const { DeletedMessageId } = deletedMessageData.Value;

					setNewMessages(prev =>
						updateMessagesOnDelete(prev, DeletedMessageId),
					);
					setHistoryMessages(prev =>
						updateMessagesOnDelete(prev, DeletedMessageId),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_REACTION_CREATE:
				{
					const newReactionData: {
						Value: ChannelMessageReactionMainEvent;
					} = JSON.parse(event.data);

					const { ChannelId, Count, EmojiId, MessageId, UserId } =
						newReactionData.Value;

					if (Number(id) !== ChannelId) {
						return;
					}

					setNewMessages(prev =>
						prev.map(message => {
							if (message.id !== MessageId) {
								return message;
							}
							const reactions = updateReactionsOnCreate(
								message.reactions,
								EmojiId,
								Count,
								UserId,
								user?.userId,
							);
							return {
								...message,
								reactions,
							};
						}),
					);
					setHistoryMessages(prev =>
						prev.map(message => {
							if (message.id !== MessageId) {
								return message;
							}
							const reactions = updateReactionsOnCreate(
								message.reactions,
								EmojiId,
								Count,
								UserId,
								user?.userId,
							);
							return {
								...message,
								reactions,
							};
						}),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_REACTION_UPDATE:
				{
					const editReactionData: {
						Value: ChannelMessageReactionUpdateEvent;
					} = JSON.parse(event.data);

					const {
						ChannelId,
						DeleteReaction,
						CreateReaction,
						MessageId,
						UserId,
					} = editReactionData.Value;

					if (Number(id) !== ChannelId) {
						return;
					}

					setNewMessages(prev =>
						prev.map(message => {
							if (message.id !== MessageId) {
								return message;
							}
							const reactions = updateReactionsOnEdit(
								message.reactions,
								CreateReaction,
								DeleteReaction,
								UserId,
								user?.userId,
							);
							return {
								...message,
								reactions,
							};
						}),
					);
					setHistoryMessages(prev =>
						prev.map(message => {
							if (message.id !== MessageId) {
								return message;
							}
							const reactions = updateReactionsOnEdit(
								message.reactions,
								CreateReaction,
								DeleteReaction,
								UserId,
								user?.userId,
							);
							return {
								...message,
								reactions,
							};
						}),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_REACTION_DELETE:
				{
					const removeReactionData: {
						Value: ChannelMessageReactionMainEvent;
					} = JSON.parse(event.data);

					const { ChannelId, Count, EmojiId, MessageId, UserId } =
						removeReactionData.Value;

					if (Number(id) !== ChannelId) {
						return;
					}

					setNewMessages(prev =>
						prev.map(message => {
							if (message.id !== MessageId) {
								return message;
							}
							const reactions = updateReactionsOnDelete(
								message.reactions,
								EmojiId,
								Count,
								UserId,
								user?.userId,
							);
							return {
								...message,
								reactions,
							};
						}),
					);

					setHistoryMessages(prev =>
						prev.map(message => {
							if (message.id !== MessageId) {
								return message;
							}
							const reactions = updateReactionsOnDelete(
								message.reactions,
								EmojiId,
								Count,
								UserId,
								user?.userId,
							);
							return {
								...message,
								reactions,
							};
						}),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_PIN_ADDED_EVENT:
				{
					const pinMessageData: {
						Value: NewChannelPinEventData;
					} = JSON.parse(event.data);

					const {
						Type,
						ChannelId,
						ChannelMessageId,
						DisplayName,
						IsAdmin,
						PinAt,
						PinnedMessageId,
						SendById,
					} = pinMessageData.Value;

					if (Number(id) !== ChannelId) {
						return;
					}

					const isCurrentUserSender = SendById === user?.userId;

					const messagePayload = {
						id: ChannelMessageId,
						replyToId: null,
						text: null,
						isEdited: false,
						isSeen: false,
						senderId: SendById,
						senderName: DisplayName,
						sentAt: PinAt,
						avatarUrl: null,
						type: Type,
						isAdmin: IsAdmin,
						pinToId: PinnedMessageId,
						isSenderDeleted: false,
					};
					if (isCurrentUserSender) {
						updateLastReadTime(PinAt);
						updateUnreadCount(0);
					}

					// @ts-ignore
					setNewMessages(prev => [{ ...messagePayload }, ...prev]);

					if (!isCurrentUserSender) {
						updateUnreadCount(prev => prev + 1);
						return;
					}
					scrollToBottom();
				}
				break;
			case SOCKET_EVENTS.CHANNEL_BLOCK_USER_EVENT:
				{
					const blockedUserData: {
						Value: { UserId: number; IsBlocked: boolean };
					} = JSON.parse(event.data);
					const { UserId, IsBlocked } = blockedUserData.Value;

					if (UserId === user?.userId) {
						setChatUser({ ...user, isBannedInChannel: IsBlocked });
					}
					setHistoryMessages(prev =>
						prev?.map(msg =>
							msg.senderId === UserId
								? { ...msg, isBannedInChannel: IsBlocked }
								: msg,
						),
					);

					setNewMessages(prev =>
						prev?.map(msg =>
							msg.senderId === UserId
								? { ...msg, isBannedInChannel: IsBlocked }
								: msg,
						),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_BLOCK_FULL_USER:
				{
					const fullyBlockedUserData: {
						Value: { UserId: number };
					} = JSON.parse(event.data);

					const { UserId } = fullyBlockedUserData.Value;

					if (UserId === user?.userId) {
						setChatUser({ ...user, isBannedInChannel: true });
					}

					setHistoryMessages(prev =>
						prev?.filter(msg => msg.senderId !== UserId),
					);
					setNewMessages(prev =>
						prev?.filter(msg => msg.senderId !== UserId),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_UNPIN_EVENT:
				{
					const unPinMessageData: {
						Value: { ChannelId: number; ChannelMessageId: number };
					} = JSON.parse(event.data);

					const { ChannelId, ChannelMessageId } =
						unPinMessageData.Value;

					if (Number(id) !== ChannelId) {
						return;
					}

					setHistoryMessages(prev =>
						prev?.filter(msg => msg.id !== ChannelMessageId),
					);
					setNewMessages(prev =>
						prev?.filter(msg => msg.id !== ChannelMessageId),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_MESSAGE_UNPIN_ALL_EVENT:
				{
					const unPinAllData: {
						Value: {
							ChannelId: number;
							UnpinMessagesIds: number[];
						};
					} = JSON.parse(event.data);

					const { ChannelId, UnpinMessagesIds } = unPinAllData.Value;

					if (Number(id) !== ChannelId) {
						return;
					}

					setHistoryMessages(prev =>
						prev?.filter(msg => !UnpinMessagesIds.includes(msg.id)),
					);
					setNewMessages(prev =>
						prev?.filter(msg => !UnpinMessagesIds.includes(msg.id)),
					);
				}
				break;
			case SOCKET_EVENTS.CHANNEL_DELETE_EVENT:
				{
					shouldCallMarkAsReadRef.current = false;
					setHistoryMessages([]);
					setNewMessages([]);
					navigate({
						pathname: '/messenger/community',
					});
				}
				break;
			default:
				break;
		}
	}, []);

	return {
		handleServerMessageSocketEvent,
		newMessages,
		setNewMessages,
		historyMessages,
		setHistoryMessages,
	};
};
