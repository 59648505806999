export const SaleIcon = () => {
	return (
		<svg
			width="22"
			height="22"
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M7 7H7.01M1 4.2L1 8.67451C1 9.1637 1 9.40829 1.05526 9.63846C1.10425 9.84253 1.18506 10.0376 1.29472 10.2166C1.4184 10.4184 1.59135 10.5914 1.93726 10.9373L9.60589 18.6059C10.7939 19.7939 11.388 20.388 12.0729 20.6105C12.6755 20.8063 13.3245 20.8063 13.927 20.6105C14.612 20.388 15.2061 19.7939 16.3941 18.6059L18.6059 16.3941C19.7939 15.2061 20.388 14.612 20.6105 13.927C20.8063 13.3245 20.8063 12.6755 20.6105 12.0729C20.388 11.388 19.7939 10.7939 18.6059 9.60589L10.9373 1.93726C10.5914 1.59135 10.4184 1.4184 10.2166 1.29472C10.0376 1.18506 9.84253 1.10425 9.63846 1.05526C9.40829 1 9.1637 1 8.67452 1L4.2 1C3.0799 1 2.51984 1 2.09202 1.21799C1.7157 1.40973 1.40973 1.71569 1.21799 2.09202C1 2.51984 1 3.07989 1 4.2ZM7.5 7C7.5 7.27614 7.27614 7.5 7 7.5C6.72386 7.5 6.5 7.27614 6.5 7C6.5 6.72386 6.72386 6.5 7 6.5C7.27614 6.5 7.5 6.72386 7.5 7Z"
				stroke="#0F1B49"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
