import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Button } from '@chakra-ui/react';

type IRef = {
	onReset: () => void;
};

interface IGenerateButton {
	onGenerateDescription: () => void;
	isDisabled: boolean;
}

export const GenerateButton = forwardRef<IRef, IGenerateButton>(
	({ onGenerateDescription, isDisabled }, ref) => {
		const [isGenerated, setIsGenerated] = useState(false);

		const title = isGenerated
			? 'Description generated'
			: 'Generate description';

		useImperativeHandle(ref, () => ({
			onReset: () => {
				setIsGenerated(false);
			},
		}));

		return (
			<Button
				onClick={() => {
					onGenerateDescription();
					setIsGenerated(true);
				}}
				isDisabled={isDisabled || isGenerated}
				alignSelf={'flex-start'}
				variant={'ctrl-secondary'}
				w={{ base: 'full', md: 'auto' }}>
				{title}
			</Button>
		);
	},
);

GenerateButton.displayName = 'GenerateButton';
