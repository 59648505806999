import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ConversationHistoryItem } from '../types';
import { MessengerQueryKeys } from './query-keys';

interface PinnedMessagesProps {
	channelId?: number | null;
	pageParam: number;
}

export const useGetChannelPinnedMessages = (
	channelId?: number | null,
	isEnable?: boolean,
) => {
	const queryClient = useQueryClient();

	if (!isEnable) {
		setTimeout(() => {
			queryClient.resetQueries({
				queryKey: [
					MessengerQueryKeys.GET_CHANNEL_PINNED_MESSAGES,
					channelId,
				],
			});
		}, 100);
	}

	const fetchPinnedMessages = async ({
		channelId = 0,
		pageParam = 1,
	}: PinnedMessagesProps) => {
		const res: ApiResponse<{
			count: number;
			messages: ConversationHistoryItem[];
		}> = await API_CHAT.get('/api/app/channel/messages/pinned', {
			PageSize: 10,
			CurrentPage: pageParam,
			ChannelId: channelId,
		});

		return res.value;
	};

	return useInfiniteQuery({
		queryKey: [MessengerQueryKeys.GET_CHANNEL_PINNED_MESSAGES, channelId],
		queryFn: ({ pageParam }) =>
			fetchPinnedMessages({
				channelId,
				pageParam,
			}),
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.messages?.length === 10
				? allPages.length + 1
				: null;
		},
		enabled: isEnable && Boolean(channelId),
	});
};
