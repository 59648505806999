import React from 'react';

interface BedIconProps {
	size?: string;
}

export const BedIcon: React.FC<BedIconProps> = ({ size = '24px' }) => {
	return (
		<svg
			width={size}
			height={size}
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0_4800_5161"
				maskUnits="userSpaceOnUse"
				x="1"
				y="3"
				width="22"
				height="18">
				<path
					d="M3 17.5V19.5M21 17.5V19.5M4 6C4 5.60218 4.15804 5.22064 4.43934 4.93934C4.72064 4.65804 5.10218 4.5 5.5 4.5H18.5C18.8978 4.5 19.2794 4.65804 19.5607 4.93934C19.842 5.22064 20 5.60218 20 6V11.5H4V6Z"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M14.5 9H9.5C9.10218 9 8.72064 9.15804 8.43934 9.43934C8.15804 9.72064 8 10.1022 8 10.5V11.5H16V10.5C16 10.1022 15.842 9.72064 15.5607 9.43934C15.2794 9.15804 14.8978 9 14.5 9Z"
					fill="#555555"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
				<path
					d="M2 13C2 12.6022 2.15804 12.2206 2.43934 11.9393C2.72064 11.658 3.10218 11.5 3.5 11.5H20.5C20.8978 11.5 21.2794 11.658 21.5607 11.9393C21.842 12.2206 22 12.6022 22 13V17.5H2V13Z"
					stroke="white"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</mask>
			<g mask="url(#mask0_4800_5161)">
				<path d="M0 0H24V24H0V0Z" fill="#10162C" />
			</g>
		</svg>
	);
};
