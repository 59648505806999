import React from 'react';

interface IProps {
	color?: string;
}

export const XCloseIcon: React.FC<IProps> = ({ color }) => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M15 5L5 15M5 5L15 15"
			stroke={color || 'currentColor'}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
