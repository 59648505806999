import { API_IDENTITY } from '../../api/api.base';

interface UserAuthData {
	access_token: string;
	expires_at: number;
	id_token: string;
	profile: Profile;
	refresh_token: string;
	scope: string;
	token_type: string;
	session_state: string | null;
}

interface Profile {
	aud: string;
	email: string;
	exp: number;
	iat: number;
	iss: string;
	name: string;
	oi_au_id: string;
	oi_tkn_id: string;
	role: string;
	sub: string;
}

interface TokenResponse {
	access_token: string;
	expires_in: number;
	id_token: string;
	refresh_token: string;
	token_type: string;
}

export const refreshSessionData = async () => {
	const userInfo = window.sessionStorage.getItem(
		`oidc.user:${import.meta.env.VITE_IDENTITY_BASE_URL}:${
			import.meta.env.VITE_OIDC_CLIENT_ID
		}`,
	);
	if (userInfo) {
		const parsedInfo: UserAuthData = JSON.parse(userInfo);
		const body = {
			grant_type: 'refresh_token',
			refresh_token: parsedInfo.refresh_token,
			scope: parsedInfo.scope,
			client_id: import.meta.env.VITE_OIDC_CLIENT_ID,
		};

		// @ts-ignore
		const resp: TokenResponse = await API_IDENTITY.post(
			`${import.meta.env.VITE_OIDC_AUTHORITY}/connect/token`,
			body,
			{
				headers: {
					'Content-Type': 'application/x-www-form-urlencoded',
				},
			},
		);

		// console.log(resp, 'resp');
		if (resp?.access_token) {
			parsedInfo.access_token = resp.access_token;
			parsedInfo.id_token = resp.id_token;
			parsedInfo.refresh_token = resp.refresh_token;
			// parsedInfo.expires_at = Math.floor(Date.now() / 1000) + 120; // 2 minutes

			window.sessionStorage.setItem(
				`oidc.user:${import.meta.env.VITE_IDENTITY_BASE_URL}:${
					import.meta.env.VITE_OIDC_CLIENT_ID
				}`,
				JSON.stringify(parsedInfo),
			);

			// window.location.reload();
		} else {
			clearSessionStorage();
			window.location.reload();
		}
	}
};

export function clearSessionStorage() {
	window.sessionStorage.removeItem(
		`oidc.user:${import.meta.env.VITE_IDENTITY_BASE_URL}:${
			import.meta.env.VITE_OIDC_CLIENT_ID
		}`,
	);
}
