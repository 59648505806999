import React from 'react';
import { Box, Text, Flex, Divider, SimpleGrid, Center } from '@chakra-ui/react';
import { Loader } from '../Loader/Loader';
import { StateAbbreviationBox } from '../StateAbbrebiationBox/StateAbbreviationBox';
import { IconBuilding } from '../../assets';
import { StatsItemWithIcon } from './StatsItemWithIcon';

interface IRentsProps {
	loading: boolean;
	studio: number;
	br1: number;
	br2: number;
	br3: number;
	br4: number;
	countyName: string;
	stateCode: string;
}

export const Rents: React.FC<Partial<IRentsProps>> = ({
	loading,
	br1,
	br2,
	br3,
	br4,
	studio,
	countyName,
	stateCode,
}) => {
	return (
		<Box maxW={'795px'}>
			<Text
				fontSize="large"
				color={'blue.200'}
				fontWeight={700}
				mb="16px">
				Section 8 Rents
			</Text>
			{loading ? (
				<Center h={'156px'} bg={'white'} borderRadius={'10px'}>
					<Loader spinnerSize="md" />
				</Center>
			) : (
				<Flex
					bg={'white'}
					borderRadius={'10px'}
					flexDirection={'column'}
					gap={'20px'}
					p={'20px'}>
					{stateCode && countyName ? (
						<>
							<Flex gap={'10px'} align={'center'}>
								<StateAbbreviationBox
									fontSize={'14px'}
									boxSize={'30px'}
									stateCode={stateCode}
								/>
								<Text
									fontSize={'18px'}
									fontWeight={'600'}
									color={'blue.200'}>
									{countyName}
								</Text>
							</Flex>
							<Divider />
						</>
					) : null}
					<SimpleGrid
						// mt={'30px'}
						minChildWidth={'120px'}
						alignItems={'center'}
						columnGap={'10px'}
						rowGap={'20px'}>
						<StatsItemWithIcon
							label="Studio"
							value={studio ? `$${studio}` : '-'}
							icon={<IconBuilding />}
						/>
						<StatsItemWithIcon
							label="1 bedroom"
							value={br1 ? `$${br1}` : '-'}
							icon={<IconBuilding />}
						/>
						<StatsItemWithIcon
							label="2 bedrooms"
							value={br2 ? `$${br2}` : '-'}
							icon={<IconBuilding />}
						/>
						<StatsItemWithIcon
							label="3 bedrooms"
							value={br3 ? `$${br3}` : '-'}
							icon={<IconBuilding />}
						/>
						<StatsItemWithIcon
							label="4 bedrooms"
							value={br4 ? `$${br4}` : '-'}
							icon={<IconBuilding />}
						/>
					</SimpleGrid>
				</Flex>
			)}
		</Box>
	);
};
