import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { Alerter } from '../../../../utils';

export const useConnectBusinessEntityStripe = () => {
	const queryClient = useQueryClient();

	return useMutation<
		ApiResponse<string | null>,
		unknown,
		{ id: number; email: string }
	>({
		mutationFn: payload => {
			const { id, email } = payload;
			return API_CTRL.post(
				`/api/app/business-entity/${id}/stripe/connect`,
				{ email },
			);
		},
		onSuccess: (data, payload) => {
			if (!data.success) {
				Alerter.error(data?.errors?.[0]?.message);
				return;
			}

			if (!data.value) {
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.BusinessEntities, false],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.BusinessEntity, payload.id],
				});
				return;
			}

			window.location.href = data.value;
		},
	});
};
