import { useMutation } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { BlockListResponse, SearchWithPaginationPayload } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetBlockList = () => {
	return useMutation<
		ApiResponse<BlockListResponse>,
		unknown,
		SearchWithPaginationPayload
	>({
		mutationFn: data => API_CHAT.get('/api/app/users/block-list', data),
		mutationKey: [MessengerQueryKeys.GET_BLOCK_LIST_USERS],
		onSuccess(data) {
			if (!data.success) {
				return;
			}
		},
		onError(error) {
			console.log(error, 'er');
		},
	});
};
