import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { returnCrimeReportObjFromArr } from '../../../utils';
import { StatBar } from './StatBar';

ChartJS.register(ArcElement, Tooltip, Legend);

interface IStatGaugeItemProps {
	value: ReturnType<typeof returnCrimeReportObjFromArr>;
}

export const StatGaugeItem: React.FC<IStatGaugeItemProps> = ({ value }) => {
	return (
		<Flex gap={'8px'} flexDir={'column'}>
			<Flex>
				<Text fontWeight={600} color={'blue.300'} fontSize={'16px'}>
					{value.name}
				</Text>
				<Text fontWeight={600} color={'blue.300'} fontSize={'16px'}>
					&nbsp;-&nbsp;
				</Text>
				{value.value ? (
					<Text fontSize="16px" fontWeight={700} color={'blue.100'}>
						{value.value}
					</Text>
				) : null}
			</Flex>
			<StatBar styles={value.styles} />
		</Flex>
	);
};
