import { useMutation } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils';

export const useUnBlockUser = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: userId =>
			API_CHAT.delete(
				`/api/app/user/block-list/delete?UserId=${userId}`,
				{},
			),
		onSuccess(data) {
			if (!data.success) {
				return Alerter.error('Error while unblocking');
			}
			Alerter.success('Unblocked');
		},
		onError(error) {
			console.log('useUnBlockUser', error);
		},
	});
};
