import { API_CHAT } from '../../../api/api.base';
import { useMutation } from '@tanstack/react-query';
import { ApiResponse } from '../../../types';
import { AllFriendsListResponse, SearchWithPaginationPayload } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetOnlineFriends = () => {
	return useMutation<
		ApiResponse<AllFriendsListResponse>,
		unknown,
		SearchWithPaginationPayload
	>({
		mutationFn: data => API_CHAT.get('/api/app/friends/online', data),
		mutationKey: [MessengerQueryKeys.GET_ONLINE_FRIENDS],
		onError(error) {
			console.log(error, 'er');
		},
	});
};
