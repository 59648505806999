import React from 'react';
import { IconButton, Flex, Text, chakra } from '@chakra-ui/react';
import { IoMdClose } from 'react-icons/io';

interface IMessageInputReplyProps {
	name: string;
	onCloseReply: VoidFunction;
	isVisible: boolean;
}

export const MessageInputReply: React.FC<IMessageInputReplyProps> = ({
	name,
	onCloseReply,
	isVisible,
}) => {
	return (
		<Flex
			height={isVisible ? '40px' : '0px'}
			visibility={isVisible ? 'visible' : 'hidden'}
			opacity={isVisible ? 1 : 0}
			transition="height .2s ease, visibility .2s ease, opacity .2s ease, padding-top .2s ease, padding-bottom .2s ease"
			alignItems="center"
			justify="space-between"
			p={isVisible ? '10px 20px' : '0px 20px'}
			bg="blue.100"
			borderRadius="10px 10px 0px 0px"
			position="relative"
			zIndex={20}>
			<Text fontSize="14px" color="white">
				Replying to{' '}
				<chakra.span fontWeight={700} color="success">
					{name}
				</chakra.span>
			</Text>
			<IconButton
				aria-label="Cancel reply"
				variant="ghost"
				minW="20px"
				colorScheme="blackAlpha"
				onClick={onCloseReply}>
				<IoMdClose color="white" size={20} />
			</IconButton>
		</Flex>
	);
};
