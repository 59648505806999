import { useMutation } from '@tanstack/react-query';
import { Alerter } from '../../../utils';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';

export const useAddFriendByNickname = () => {
	return useMutation<ApiResponseEmpty, unknown, string>({
		mutationFn: (nickname: string) =>
			API_CHAT.post('/api/app/friend/new-by-nickname', { nickname }),

		onError(error) {
			Alerter.error(error as any);
		},
	});
};
