import React, { ErrorInfo, ReactNode } from 'react';
import { Center, Heading, Text } from '@chakra-ui/react';

interface ErrorBoundaryProps {
	children?: ReactNode;
}

interface ErrorBoundaryState {
	hasError: boolean;
}

export class ErrorBoundary extends React.Component<
	ErrorBoundaryProps,
	ErrorBoundaryState
> {
	constructor(props: ErrorBoundaryProps) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError(error: Error) {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.log(error, errorInfo, 'componentDidCatch error');
	}

	render() {
		const { hasError } = this.state;
		const { children } = this.props;
		if (hasError) {
			return (
				<Center flexDirection={'column'}>
					<Heading as={'h1'}>Something went wrong :(</Heading>
					<Text>Reload the page or try again later.</Text>
				</Center>
			);
		}

		return children;
	}
}
