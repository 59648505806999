import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from '../../../types';
import { API_IDENTITY } from '../../../api/api.base';
import { QueryKeys } from '../../../constants';

export const useCheckTOSStatusQuery = (isGuest: boolean) => {
	return useQuery<
		ApiResponse<{
			isAgreementSigned: boolean;
			isCampaignFieldsSet: boolean;
		}>
	>({
		queryKey: [QueryKeys.CHECK_TOS_STATUS],
		queryFn: () =>
			API_IDENTITY.get('/api/user/current/agreement-status', {}),
		enabled: !isGuest,
	});
};
