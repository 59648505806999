import { useQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { SearchSuggestionsResponse } from '../types';
import { MessengerQueryKeys } from './query-keys';

interface IPayload {
	channelId: number;
	search?: string;
	pageSize: number;
	isEnabled: boolean;
}

export const useGetChannelSearchSuggestions = ({
	channelId,
	search,
	pageSize,
	isEnabled,
}: IPayload) => {
	return useQuery<ApiResponse<SearchSuggestionsResponse>>({
		queryKey: [
			MessengerQueryKeys.CHANNEL_SEARCH_SUGGESTIONS,
			channelId,
			search,
			pageSize,
		],
		queryFn: () =>
			API_CHAT.get('/api/app/channel/users/suggestions', {
				currentPage: 1,
				channelId,
				search,
				pageSize,
			}),
		enabled: isEnabled,
	});
};
