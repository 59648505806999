import * as Ably from 'ably';
import { API_CHAT } from '../api/api.base';

// export const ablyClient = new Ably.Realtime({
// 	logLevel: 2,
// 	authCallback: async (data, callback) => {
// 		let tokenRequest;
// 		try {
// 			tokenRequest = await API_CHAT.post(
// 				'/api/app/auth/ably/user-channels',
// 				{},
// 			);
// 		} catch (err) {
// 			callback(err, null);
// 			return;
// 		}
// 		console.log('tokenRequest.data', tokenRequest);
// 		callback(null, tokenRequest);
// 	},
// 	disconnectedRetryTimeout: 5000,
// 	suspendedRetryTimeout: 10000,
// });

export const getAblyClient = (userId: number) => {
	return new Ably.Realtime({
		logLevel: 2,
		clientId: userId.toString(),
		authCallback: async (data, callback) => {
			let tokenRequest: Ably.TokenRequest;
			try {
				// @ts-ignore
				tokenRequest = await API_CHAT.post(
					'/api/app/auth/ably/user-channels',
					{},
				);
			} catch (err) {
				callback(err as any, null);
				return;
			}
			callback(null, tokenRequest);
		},
		disconnectedRetryTimeout: 5000,
		suspendedRetryTimeout: 10000,
		realtimeRequestTimeout: 20000,
	});
};
