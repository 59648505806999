import { capitalizeFirstLetter } from './capitalizeFirstLetter';

interface CrimeReportStylesReturnType {
	width: string;
	backgroundColor: string;
}

export const getStylePropsForCrimeReportItem = (
	value: string,
): CrimeReportStylesReturnType => {
	switch (value) {
		case 'Lowest':
			return {
				width: '60px',
				backgroundColor: '#36D27A',
			};
		case 'Low':
			return {
				width: '90px',
				backgroundColor: '#36D27A',
			};
		case 'Below Average':
			return {
				width: '120px',
				backgroundColor: '#36D27A',
			};
		case 'Average':
			return {
				width: '150px',
				backgroundColor: '#ECCE2E',
			};
		case 'Above Average':
			return {
				width: '180px',
				backgroundColor: '#ECCE2E',
			};
		case 'High':
			return {
				width: '210px',
				backgroundColor: '#F35050',
			};
		case 'Highest':
			return {
				width: '240px',
				backgroundColor: '#F35050',
			};
		default:
			return {
				width: '0px',
				backgroundColor: '#10162C',
			};
	}
};

export enum CrimeReportItemNames {
	OVERALL = 'overall',
	VIOLENT = 'violent',
	MURDER = 'murder',
	RAPE = 'rape',
	ROBBERY = 'robbery',
	ASSAULT = 'assault',
	PROPERTY = 'property',
	BURGLARY = 'burglary',
	LARCENY = 'larceny',
	MOTORVEHICLETHEFT = 'motorvehicletheft',
	ARSON = 'arson',
}

type CrimeReportObj = {
	name: string;
	value: string;
};

interface CrimeReportObjReturnType {
	name: string;
	value: string;
	styles: CrimeReportStylesReturnType;
}

export const returnCrimeReportObjFromArr = (
	items: Array<CrimeReportObj>,
	objectNameToReturn: CrimeReportItemNames,
): CrimeReportObjReturnType => {
	const foundItem = items.find(it => it.name === objectNameToReturn);

	if (foundItem) {
		return {
			value: foundItem.value,
			name: capitalizeFirstLetter(objectNameToReturn),
			styles: getStylePropsForCrimeReportItem(foundItem.value),
		};
	}

	return {
		name: capitalizeFirstLetter(objectNameToReturn),
		value: '',
		styles: getStylePropsForCrimeReportItem(''),
	};
};
