import { useEffect, useMemo } from 'react';
import {
	useDefaultUserStore,
	useNotificationsPermissionsStore,
} from '../store';
import { isSupported } from 'firebase/messaging';
import { useGetAndSaveDeviceToken } from './useGetAndSaveDeviceToken';
import { Claim } from '../types';

export const useRegisterNotifications = () => {
	const { user } = useDefaultUserStore();

	const { setShowNotificationsPrompt } = useNotificationsPermissionsStore();
	const getAndSaveDeviceToken = useGetAndSaveDeviceToken();

	const isUserInCommunity = useMemo(
		() =>
			!!user?.claimsIds?.includes(Claim.Messenger) &&
			!!user?.isCommunityInstructionCompleted &&
			!!user?.isCommunityAvailable,
		[
			user?.claimsIds,
			user?.isCommunityInstructionCompleted,
			user?.isCommunityAvailable,
		],
	);

	useEffect(() => {
		if (!('Notification' in window)) {
			// Check if the browser supports notifications
			console.log('this browser does not support web push api');
			return;
		}
		if (!isUserInCommunity) {
			return;
		}

		isSupported()
			.then(res => {
				if (res) {
					const isNotificationRequestWasShown = localStorage.getItem(
						'notificationPermissionShown',
					);

					if (
						Notification.permission === 'granted' &&
						isNotificationRequestWasShown
					) {
						console.log('permission already granted, skipping');
						getAndSaveDeviceToken();
						return;
					}
					if (!isNotificationRequestWasShown) {
						setShowNotificationsPrompt(true);
					}
				}
			})
			.catch(err => {
				console.log(err, 'is supported err');
			});
	}, [isUserInCommunity]);
};
