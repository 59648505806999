export const RulerIcon = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M4.045 18.508C2.682 17.145 2 16.463 2 15.616C2 14.768 2.682 14.086 4.045 12.723L12.723 4.045C14.087 2.682 14.768 2 15.616 2C16.463 2 17.145 2.682 18.508 4.045L19.955 5.492C21.318 6.855 22 7.537 22 8.384C22 9.231 21.318 9.914 19.955 11.277L11.277 19.955C9.913 21.318 9.23 22 8.384 22C7.538 22 6.855 21.318 5.492 19.955L4.045 18.508Z"
				stroke="#10162C"
				strokeWidth="2"
			/>
			<path
				d="M8.46368 8.46368L9.87968 9.87968M12.7067 4.22168L14.1207 5.63568M4.22168 12.7067L5.63568 14.1207M6.34268 10.5857L8.46468 12.7067M10.5857 6.34268L12.7067 8.46368"
				stroke="#10162C"
				strokeWidth="2"
				strokeLinecap="round"
			/>
		</svg>
	);
};
