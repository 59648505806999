import React from 'react';
import { Flex, FlexProps, Text } from '@chakra-ui/react';

interface IProps extends FlexProps {
	value: number;
}

export const BadgeCounter: React.FC<IProps> = ({ value, ...rest }) => {
	return (
		<Flex
			minW={'24px'}
			p={'5px'}
			h={'24px'}
			justify="center"
			alignItems="center"
			borderRadius="100px"
			bg="blue.50"
			flexShrink={0}
			{...rest}>
			<Text color="white" variant={'bodySmall'}>
				{value}
			</Text>
		</Flex>
	);
};
