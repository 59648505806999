import React, { useState } from 'react';
import { Avatar, Box, Button, SkeletonCircle, Text } from '@chakra-ui/react';

interface IUserSuggestionItem {
	avatarUrl: string | null;
	name: string;
	username: string;
	onClick: VoidFunction;
}

export const UserSuggestionItem: React.FC<IUserSuggestionItem> = ({
	avatarUrl,
	name,
	username,
	onClick,
}) => {
	const [isLoading, setIsLoading] = useState(!!avatarUrl);
	return (
		<Button
			variant="ghost"
			justifyContent="flex-start"
			alignItems="center"
			w="full"
			p="8px 10px"
			borderRadius="4px"
			columnGap="5px"
			bg="transparent"
			onClick={onClick}
			overflowX="hidden"
			_hover={{
				bg: 'blue.200',
			}}>
			<Box position="relative">
				{isLoading ? (
					<SkeletonCircle
						boxSize="24px"
						position="absolute"
						zIndex={4}
						opacity={1}
					/>
				) : null}
				<Avatar
					width="24px"
					height="24px"
					name={name}
					src={avatarUrl || ''}
					onLoad={() => setIsLoading(false)}
					onError={() => setIsLoading(false)}
					sx={{
						'>div': {
							fontSize: '12px',
							lineHeight: '24px',
						},
					}}
				/>
			</Box>
			<Text
				color="white"
				fontWeight={600}
				flexShrink={1}
				isTruncated={true}>
				{name}
			</Text>
			<Text color="inactiveText" flexShrink={1} isTruncated={true}>
				{username}
			</Text>
		</Button>
	);
};
