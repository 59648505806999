import { useMutation } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { Alerter } from '../../../utils';

export const useBlockUser = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: (userId: number) =>
			API_CHAT.post('/api/app/user/block-list/add', { userId: userId }),
		onSuccess(data) {
			if (!data.success) {
				Alerter.error('Error while blocking');
				return;
			}

			Alerter.success('User blocked');
		},
		onError(error) {
			console.log('useBlockUser', error);
		},
	});
};
