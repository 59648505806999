import { getToken } from 'firebase/messaging';
import { Alerter } from '../utils';
import { useSaveDeviceToken } from '../pages/Messenger/queries';
import { useNotificationsPermissionsStore } from '../store';
import { messaging } from '../messaging_init';

export const useGetAndSaveDeviceToken = () => {
	const saveDeviceTokenMutation = useSaveDeviceToken();
	const { setToken } = useNotificationsPermissionsStore();

	const getAndSaveDeviceToken = async () => {
		if (messaging) {
			getToken(messaging, {
				vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
			})
				.then(currentToken => {
					if (currentToken) {
						saveDeviceTokenMutation.mutate(currentToken, {
							onSuccess(data, variables) {
								if (data.success) {
									setToken(variables);
								}
							},
						});
					} else {
						// Show permission request UI
						console.log(
							'No registration token available. Request permission to generate one. 1',
						);
						// ...
					}
				})
				.catch(err => {
					console.log("first try didn't went");
					// for some reason it may not work from first try
					getToken(messaging!, {
						vapidKey: import.meta.env.VITE_FIREBASE_VAPID_KEY,
					})
						.then(currentToken => {
							if (currentToken) {
								// console.log(currentToken, 'current token');
								saveDeviceTokenMutation.mutate(currentToken, {
									onSuccess(data, variables, context) {
										if (data.success) {
											setToken(variables);
										}
									},
								});
							} else {
								// Show permission request UI
								console.log(
									'No registration token available. Request permission to generate one.',
								);
								// ...
							}
						})
						.catch(err => {
							console.log(
								'An error occurred while retrieving token. ',
								err,
							);
							Alerter.error(
								'An error occurred while setting up notifications',
							);
						});
				});
		}
	};

	return getAndSaveDeviceToken;
};
