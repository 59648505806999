import React from 'react';
import { Box, Center, Divider, Flex, Text } from '@chakra-ui/react';
import { Loader } from '../Loader/Loader';
import { StatGaugeItem } from './StatGaugeItem/StatGaugeItem';
import { CrimeReportItemNames, returnCrimeReportObjFromArr } from '../../utils';

interface ICrimeReportProps {
	loading: boolean;
	items: { name: string; value: string }[];
}

export const CrimeReport: React.FC<ICrimeReportProps> = ({
	loading,
	items,
}) => {
	const OVERALL_STAT_ITEM = returnCrimeReportObjFromArr(
		items,
		CrimeReportItemNames.OVERALL,
	);
	return (
		<Box maxW={'684px'}>
			<Text
				fontSize="large"
				color={'blue.200'}
				fontWeight={700}
				mb="16px">
				Crime Report
			</Text>
			<Box bg="white" p={'20px'} borderRadius={'10px'}>
				{loading ? (
					<Center h={'325px'} bg={'white'} borderRadius={'10px'}>
						<Loader spinnerSize="md" />
					</Center>
				) : (
					<>
						<Flex gap={'10px'}>
							<Text
								fontWeight={600}
								color={'blue.300'}
								fontSize={'18px'}>
								Overall:
							</Text>
							<Text
								fontWeight={600}
								fontSize={'18px'}
								color={
									OVERALL_STAT_ITEM.styles.backgroundColor
								}>
								{OVERALL_STAT_ITEM?.value || '-'}
							</Text>
						</Flex>
						<Flex
							mt="24px"
							justify={'stretch'}
							flexDir={{ base: 'column', md: 'row' }}>
							<Flex
								flexDirection={'column'}
								gap={'24px'}
								w={'302px'}>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.VIOLENT,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.MURDER,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.RAPE,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.ROBBERY,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.ASSAULT,
									)}
								/>
							</Flex>
							<Divider
								orientation="vertical"
								height="auto"
								alignSelf="stretch"
								mx={'20px'}
							/>
							<Flex
								mt={{ base: '24px', md: '0px' }}
								flexDirection={'column'}
								gap={'24px'}
								w={'302px'}>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.PROPERTY,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.BURGLARY,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.LARCENY,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.MOTORVEHICLETHEFT,
									)}
								/>
								<StatGaugeItem
									value={returnCrimeReportObjFromArr(
										items,
										CrimeReportItemNames.ARSON,
									)}
								/>
							</Flex>
						</Flex>
					</>
				)}
			</Box>
		</Box>
	);
};
