import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(switchAnatomy.keys);

const smallOutlined = definePartsStyle({
	track: {
		bg: 'transparent',
		border: '2px solid #4A5991',
	},
	thumb: {
		bg: '#4A5991',
	},
});

const green = definePartsStyle({
	track: {
		bg: 'transparent',
		border: '2px solid #4A5991',
		_checked: {
			borderColor: '#08BB4B',
		},
	},
	thumb: {
		bg: '#4A5991',
		_checked: {
			bg: '#08BB4B',
		},
	},
});

const green2 = definePartsStyle({
	track: {
		bg: 'dropBlue',
		_checked: {
			bg: 'success',
		},
	},
	thumb: {
		boxShadow: '0px 3px 1px #0000000F, 0px 3px 8px #00000026',
		bg: 'white',
	},
});

export const Switch = defineMultiStyleConfig({
	baseStyle: {
		track: {
			_focusVisible: {
				boxShadow: 'none',
			},
		},
		thumb: {
			_focusVisible: {
				boxShadow: 'none',
			},
		},
	},
	variants: { smallOutlined, green, green2 },
	sizes: {
		xl: {
			track: {
				width: '47px',
				height: '27px',
			},
			thumb: {
				width: '27px',
				height: '27px',
				transform: 'translateX(1px)',
				_checked: {
					transform: 'translateX(19px)',
				},
			},
		},
	},
});
