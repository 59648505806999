import React from 'react';
import { Box } from '@chakra-ui/react';
import {
	useChatUserStore,
	useMessengerSidebarsOpenStore,
} from '../../../../store';
import { SearchResults } from '../../common-components';
import { ChatType, UserByIdData } from '../../types';
import { FriendInfoSidebar } from './FriendInfoSidebar';

interface IDirectRightSidebar {
	conversationId: number;
	receiver?: UserByIdData;
	receiverLoading: boolean;
}

export const DirectRightSidebar: React.FC<IDirectRightSidebar> = ({
	receiver,
	receiverLoading,
	conversationId,
}) => {
	const { user } = useChatUserStore();
	const { isOpenDirectFriendInfoSidebar, isOpenSearchResultSidebar } =
		useMessengerSidebarsOpenStore();
	return (
		<React.Fragment>
			<Box
				width="340px"
				height="calc(100vh - 60px)"
				display={isOpenDirectFriendInfoSidebar ? 'block' : 'none'}
				bg="blue.200"
				overflowY="auto">
				<FriendInfoSidebar
					isLoaded={!receiverLoading}
					isCurrentUserAdmin={!!user?.isAdmin}
					{...receiver}
				/>
			</Box>
			<Box
				width="400px"
				display={isOpenSearchResultSidebar ? 'block' : 'none'}
				bg="dropBlue"
				height="calc(100vh - 60px)">
				<SearchResults id={conversationId} chatType={ChatType.DIRECT} />
			</Box>
		</React.Fragment>
	);
};
