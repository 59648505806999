import { useQuery } from '@tanstack/react-query';
import { ApiResponse } from '../../../types';
import { API_IDENTITY } from '../../../api/api.base';
import { QueryKeys } from '../../../constants';

export const useGetPandaDocDocumentQuery = () => {
	return useQuery<ApiResponse<string>>({
		queryKey: [QueryKeys.GET_PANDA_DOC],
		queryFn: () => API_IDENTITY.post('api/user/agreement/generate', {}),
	});
};
