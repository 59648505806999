import React from 'react';
import { Box, Button, Divider, Text } from '@chakra-ui/react';
import { DropdownMenuContent } from '../../../pages/Messenger/common-components/Menu/Menu';
import { userMenuItems } from '../../../constants';
import { NavItem } from './NavItem';
import { LogoutIcon } from '../../../assets';
import { useIsGuest } from '../../../hooks';

interface UserMenuProps {
	onSignOut: VoidFunction;
}

export const UserMenu: React.FC<UserMenuProps> = ({ onSignOut }) => {
	const isGuest = useIsGuest();
	return (
		<DropdownMenuContent
			align={'start'}
			sideOffset={10}
			style={{ width: '260px' }}>
			{userMenuItems
				.filter(item => !(isGuest && item.text === 'Billing History'))
				.map((item, index) => (
					<Box
						key={index}
						_hover={{ bg: 'blue.100' }}
						borderRadius={'10px'}>
						<NavItem
							onAction={() => {}}
							isActive={location.pathname === item.link}
							key={item.text}
							icon={item.icon}
							isDisabled={false}
							link={item.link}
							menuExpanded={true}
							fontSize={'16px'}
							fontWeight={'600'}
							isInAccordion={item.accordion}
							heightPadding={'16px'}>
							{item.text}
						</NavItem>
					</Box>
				))}
			<Divider m={'15px 0 25px 0'} border={'1px solid #1C2959'} />
			<Button
				onClick={onSignOut}
				h={'60px'}
				w={'100%'}
				border={'2px solid #08BB4B'}
				bg={'inherit'}
				_hover={{ bg: 'inherit' }}>
				<LogoutIcon />
				<Text fontWeight={'700'} ml={'10px'} color={'white'}>
					Log Out
				</Text>
			</Button>
		</DropdownMenuContent>
	);
};
