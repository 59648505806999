import { FC } from 'react';

interface PlusIconProps {
	strokeColor?: string;
	size?: string | number;
}

export const PlusIcon: FC<PlusIconProps> = ({
	strokeColor = 'currentColor',
	size,
}) => (
	<svg
		width={size || '20'}
		height={size || '20'}
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M10.0003 4.1665V15.8332M4.16699 9.99984H15.8337"
			stroke={strokeColor}
			strokeWidth="2"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);
