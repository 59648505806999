export enum SearchOptionsViewEnum {
	None = 'none',
	Default = 'default',
	From = 'from',
	Mentions = 'mentions',
	Has = 'has',
	During = 'during',
	Pinned = 'pinned',
	User = 'user',
	AllCategories = 'all categories',
	// Completed = 'completed',
}

export type SearchOptionType<T> = {
	label: string;
	value: T;
};

export enum SearchType {
	Messages = 'messages',
	Users = 'users',
}
