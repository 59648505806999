/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import { useInfiniteQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { MESSAGES_PAGE_SIZE } from '../../../constants';
import { ApiResponse } from '../../../types';
import { ConversationHistoryItem } from '../types';
import { checkIfHalfHasMore } from '../utils';
import { MessengerQueryKeys } from './query-keys';

const fetchMessages = async ({
	pageParam = { direction: 'prev', value: 0 },
	conversationId = 0,
}) => {
	const res: ApiResponse<{
		count: number;
		messages: ConversationHistoryItem[];
		userLastReadDateTime: string;
		unreadCount: number;
	}> = await API_CHAT.get('/api/app/conversation/messages/private', {
		pageSize: MESSAGES_PAGE_SIZE,
		conversationId,
		thresholdMessageId: pageParam.value ? pageParam.value : undefined,
		isAsc: pageParam.value === 0 ? false : pageParam.direction === 'prev',
	});

	const orientedMessages =
		pageParam.direction === 'prev'
			? [...res.value.messages].reverse()
			: res.value.messages;

	return (
		{
			...res?.value,
			messages: orientedMessages,
			lastMessageId: orientedMessages.at(-1)?.id,
			firstMessageId: orientedMessages.at(0)?.id,
			direction: pageParam.direction,
		} || {
			count: 0,
			messages: [],
			unreadCount: 0,
			userLastReadDateTime: new Date().toISOString(),
		}
	);
};

export const useGetDirectMessages = (conversationId?: number | null) => {
	return useInfiniteQuery({
		queryKey: [MessengerQueryKeys.GET_DIRECT_MESSAGES, conversationId],
		initialPageParam: { direction: 'next', value: 0 },
		queryFn: ({ pageParam }) =>
			fetchMessages({
				pageParam,
				conversationId: conversationId || 0,
			}),

		getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
			let isHalfHasMore = false;
			let result =
				firstPage?.messages?.length === MESSAGES_PAGE_SIZE &&
				firstPage.direction === 'prev'
					? {
							direction: 'prev',
							value: +(firstPage.firstMessageId || 0),
					  }
					: null;
			if (!result && firstPageParam.direction === 'center') {
				isHalfHasMore = checkIfHalfHasMore({
					messageId: +firstPageParam.value,
					direction: 'prev',
					messages: firstPage.messages,
				});
				if (isHalfHasMore) {
					// console.log(
					// 	'isHalfHasMore prev',
					// 	firstPage,
					// 	firstPageParam,
					// );
					result = {
						direction: 'prev',
						value: +(firstPage.firstMessageId || 0),
					};
				}
			}
			// console.log(
			// 	{ result, firstPage, firstPageParam, isHalfHasMore, allPages },
			// 	'getPreviousPageParam result',
			// );
			return result;
		},
		// Scroll down
		getNextPageParam: (lastPage, allPages, lastPageParam) => {
			let isHalfHasMore = false;
			let result =
				lastPage?.messages?.length >= MESSAGES_PAGE_SIZE
					? {
							direction: 'next',
							value: +(lastPage?.lastMessageId || 0),
					  }
					: null;
			if (!result && lastPageParam.direction === 'center') {
				isHalfHasMore = checkIfHalfHasMore({
					messageId: +lastPageParam.value,
					direction: 'next',
					messages: lastPage.messages,
				});
				if (isHalfHasMore) {
					result = {
						direction: 'next',
						value: +(lastPage.lastMessageId || 0),
					};
				}
			}
			// console.log(
			// 	{ result, lastPage, lastPageParam, isHalfHasMore, allPages },
			// 	'getNextPageParam result',
			// );
			return result;
		},
		enabled: !!conversationId,
	});
};
