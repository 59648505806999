import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { CruzQueryKeys } from '../query-keys';

export const useCreateTenant = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, FormData>({
		mutationFn: payload => API_CTRL.post('api/app/tenants', payload),
		onSuccess: (data, payload) => {
			if (data.success) {
				Alerter.success('Created successfully');
				const propertyId = payload.get('PropertyId');
				const unitId = payload.get('UnitId');
				queryClient.invalidateQueries({
					queryKey: [
						CruzQueryKeys.PropertyDetails,
						Number(propertyId),
					],
				});
				queryClient.invalidateQueries({
					queryKey: [
						CruzQueryKeys.PropertyUnitDetails,
						Number(unitId),
					],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.CurrentTenants],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.ArchivedTenants],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.OccupiedPropertySelector],
				});
			} else {
				Alerter.error(data.errors[0].message);
			}
		},
	});
};
