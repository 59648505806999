import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { MessengerQueryKeys } from './query-keys';
import { ChannelUsersResponse, UserChannelData } from '../types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useServerUsersAllList } from '../../../store';

const PAGE_SIZE = 50;

const fetchUsers = async ({
	pageParam = 1,
	searchQuery = '',
	filter,
	setAllChannelUsers,
	setAllChannelUsersCount,
	addToAllChannelUsers,
}: {
	searchQuery: string;
	pageParam: number;
	filter?: number;
	setAllChannelUsers: (users: UserChannelData[]) => void;
	setAllChannelUsersCount: (count: number) => void;
	addToAllChannelUsers: (users: UserChannelData[]) => void;
}) => {
	const res: ApiResponse<ChannelUsersResponse> = await API_CHAT.get(
		'/api/app/channel/users-all',
		{
			PageSize: PAGE_SIZE,
			CurrentPage: pageParam,
			Search: searchQuery || undefined,
			Filter: filter,
		},
	);
	if (pageParam === 1) {
		setAllChannelUsers(res?.value?.users || []);
	}
	if (pageParam > 1) {
		addToAllChannelUsers(res?.value?.users || []);
	}

	setAllChannelUsersCount(res?.value?.count || 0);
	return { ...res.value, pageParam };
};

export const useGetChannelAllUsers = (
	debouncedSearchQuery: string,
	isAdmin: boolean,
	isEnabled: boolean,
	channelId?: number,
	filter?: number,
) => {
	const {
		setAllChannelUsers,
		setAllChannelUsersCount,
		addToAllChannelUsers,
	} = useServerUsersAllList();

	return useInfiniteQuery({
		queryKey: [
			MessengerQueryKeys.GET_ALL_CHANNEL_USERS,
			debouncedSearchQuery,
			filter,
			// channelId,
		],
		initialPageParam: 1,
		queryFn: ({ pageParam }) =>
			fetchUsers({
				searchQuery: debouncedSearchQuery,
				pageParam,
				filter,
				setAllChannelUsers,
				setAllChannelUsersCount,
				addToAllChannelUsers,
			}),
		getNextPageParam: lastPage => {
			return lastPage?.users?.length === PAGE_SIZE
				? lastPage.pageParam + 1
				: null;
		},
		enabled: isAdmin && !!channelId && isEnabled,
	});
};
