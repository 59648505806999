import React, { FC, PropsWithChildren } from 'react';
import { AuthProvider, AuthProviderProps } from 'oidc-react';
import * as Sentry from '@sentry/react';
import { deleteToken, getMessaging } from 'firebase/messaging';
import { OIDC_CONFIG } from '../../constants';
import { userManager } from '../../utils';
import { ProtectedArea } from '../../components';
import { getValueFromLS, storeValueToLS } from '../../utils/localStorage';

const oidcConfig: AuthProviderProps = {
	...OIDC_CONFIG,
	onSignIn: () => {
		window.location.href =
			getValueFromLS<string>('afterLoginRedirect') || '/';
	},
	onSignOut: () => {
		console.log('SIGN-OUT');
		userManager
			.signoutRedirect({
				id_token_hint: undefined,
				extraQueryParams: {
					returnUrl: import.meta.env.VITE_OIDC_REDIRECT_URI,
				},
			})
			.catch((e: Error) => {
				console.log('LOGOUT ERROR');
				console.log(e?.message);
				console.log(e?.stack);
				Sentry.captureException({
					info: {
						section: 'oidc auth',
						message: e?.message,
						stacktrace: e?.stack,
					},
				});
				// location.href = `${
				// 	import.meta.env.VITE_OIDC_AUTHORITY
				// }/account/logout?returnUrl=${
				// 	import.meta.env.VITE_OIDC_REDIRECT_URI
				// }`;
			});
		try {
			deleteToken(getMessaging());
		} catch (error) {
			console.log(error, 'deleteToken index');
		}
	},
	autoSignOut: false,
};

export const OidcAuth: FC<PropsWithChildren> = ({ children }) => {
	setTimeout(() => {
		if (window.location.href.includes('/success')) {
			window.location.href = '/';
			Sentry.captureMessage('REDIRECTING');
		}
	}, 3000);

	if (
		window.location.pathname !== '/' &&
		!window.location.pathname.includes('/success')
	) {
		// console.log(window.location.pathname, 'STORE');
		storeValueToLS<string>('afterLoginRedirect', window.location.pathname);
	}

	return (
		<AuthProvider {...oidcConfig} userManager={userManager}>
			<ProtectedArea>{children}</ProtectedArea>
		</AuthProvider>
	);
};
