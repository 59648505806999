import { useQuery } from '@tanstack/react-query';
import { ApiResponse, PreciselyAutocompleteItem } from '../../../types';
import API from '../../../api/api.base';
import { useIsGuest } from '../../../hooks';
import { QueryKeys } from '../../../constants';

export const usePropertyAutocomplete = (debouncedSearchQuery: string) => {
	const isGuest = useIsGuest();
	return useQuery<
		ApiResponse<{
			models: PreciselyAutocompleteItem[];
		} | null>
	>({
		queryKey: [QueryKeys.GET_PROPERTY_RESEARCH, debouncedSearchQuery],
		queryFn: () =>
			API.get(
				`/api/app/${
					isGuest ? 'guest/' : ''
				}precisely/property/autocomplete`,
				{
					Address: debouncedSearchQuery,
				},
			),
		enabled: !!debouncedSearchQuery,
		refetchOnMount: false,
	});
};
