import React from 'react';
import { Box, Text, Button, Center, useDisclosure } from '@chakra-ui/react';
import { EditPencilIcon } from '../../../../assets';
import { ManageChannelModal } from '../../modals';
import { useDefaultUserStore } from '../../../../store';
import { ServerChannelData } from '../../types';

interface IStartOfServerChatProps {
	channelData?: ServerChannelData | null;
}

export const StartOfServerChat: React.FC<IStartOfServerChatProps> = ({
	channelData,
}) => {
	const { isAdmin } = useDefaultUserStore();

	const { isOpen, onClose, onOpen } = useDisclosure();

	if (!channelData) {
		return null;
	}

	return (
		<>
			<Box mb="20px" mt="auto">
				<Center
					boxSize="100px"
					borderRadius="50%"
					bg="dropBlue"
					mb="20px">
					<Box boxSize="20px" bg="white" borderRadius="50%" />
				</Center>
				<Text
					fontSize="25px"
					lineHeight="30px"
					color="blue.200"
					fontWeight={700}
					mb="15px">
					{channelData?.name || ''}
				</Text>
				{channelData?.topic ? (
					<Box mb="15px">
						{channelData.topic
							.split('\n')
							.map((paragraph, index) => (
								<Text key={index} color="blue.200" mb="12px">
									{paragraph}
								</Text>
							))}
					</Box>
				) : null}
				{isAdmin ? (
					<Button
						leftIcon={<EditPencilIcon width="15" height="15" />}
						variant="link"
						color="success"
						fontSize="14px"
						fontWeight={700}
						onClick={onOpen}>
						Edit channel
					</Button>
				) : null}
			</Box>
			<ManageChannelModal
				isOpen={isOpen}
				onClose={onClose}
				channel={channelData}
				categoryName={null}
				categoryId={null}
			/>
		</>
	);
};
