import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { DEAL_EXPLORER_FILTERS_KEY } from '../constants';
import { DealExplorerFilterState } from '../pages/DealExplorer/types';

interface IState extends DealExplorerFilterState {
	persistFiltersState: (updates: Partial<DealExplorerFilterState>) => void;
	resetFiltersState: () => void;
}

export const initialDealExplorerFilersState: DealExplorerFilterState = {
	state: null,
	county: null,
	city: null,
	propertyStatus: 1,
	minPrice: '',
	maxPrice: '150000',
	minCashFlow: '250',
	sellerFinancingChecked: false,
	showFavourite: false,
	bedrooms: ['-1'],
};

export const usePersistDealExplorerStore = create(
	persist<IState>(
		set => ({
			...initialDealExplorerFilersState,
			persistFiltersState: updates =>
				set(state => ({ ...state, ...updates })),
			resetFiltersState: () => {
				set(initialDealExplorerFilersState);
			},
		}),
		{
			name: DEAL_EXPLORER_FILTERS_KEY,
			version: 1,
		},
	),
);
