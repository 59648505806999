import { ApiResponse } from '../types';
import { API_CHAT } from '../api/api.base';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '../constants';

export const useGetUnreadSidebarCounter = () => {
	return useQuery<
		ApiResponse<{
			amountUnreadCommunity: number;
			amountUnreadAdminMessages: number;
		}>
	>({
		queryKey: [QueryKeys.UNREAD_SIDEBAR_COUNTER],
		queryFn: () => API_CHAT.get('/api/app/counter/sidebar', {}),
		initialData: () => {
			return {
				value: {
					amountUnreadCommunity: 0,
					amountUnreadAdminMessages: 0,
				},
				errors: [],
				messages: [],
				status: 200,
				success: true,
			};
		},
	});
};
