import React, { useEffect, useMemo } from 'react';
import { Text, Center } from '@chakra-ui/react';
import { useChannel } from 'ably/react';
import { DirectFriendBase, PendingControls } from '../components';
import { Loader } from '../../../../components';
import { useChatUserStore } from '../../../../store';
import { useGetPendingFriends } from '../../queries';
import { usePolling } from '../../hooks';
import { useHandlePendingTabSocketEvents } from '../hooks';
import { filterAblyErrorsToSentry } from '../../../../utils';

interface IPendingTabProps {
	index: number;
}

export const PendingTab: React.FC<IPendingTabProps> = ({ index }) => {
	const { user } = useChatUserStore();

	const {
		handlePendingTabSocketEvents,
		incoming,
		outgoing,
		setIncoming,
		setOutgoing,
	} = useHandlePendingTabSocketEvents();

	useChannel(
		{
			channelName: `private-user:${user?.userId}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			handlePendingTabSocketEvents(message);
		},
	);

	const { isPending: loading, mutateAsync: getPendingUsers } =
		useGetPendingFriends();

	const userIds = useMemo(() => {
		const incomingIds = incoming.map(friend => friend.senderId);
		const outgoingIds = outgoing.map(friend => friend.receiverId);
		return [...incomingIds, ...outgoingIds];
	}, [incoming.length, outgoing.length]);

	const currentPresenceStatus = usePolling(userIds, index === 2);

	const onGetPendingUsers = async () => {
		try {
			const res = await getPendingUsers();
			if (!res.value || !res.success) {
				return;
			}

			setOutgoing(res.value.outcomeFriendRequests);
			setIncoming(res.value.incomeFriendRequests);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (user?.userId && index === 2) {
			onGetPendingUsers();
		}
	}, [user?.userId, index]);

	if (loading) {
		return <Loader centerHeight="500px" />;
	}

	if (incoming?.length === 0 && outgoing?.length === 0) {
		return (
			<Center height="500px">
				<Text color="blue.50">No friend requests</Text>
			</Center>
		);
	}

	return (
		<>
			{incoming?.length ? (
				<>
					<Text
						mt="20px"
						mb="10px"
						color="blue.200"
						fontSize="16px"
						fontWeight={700}
						textTransform="uppercase">
						Incoming - {incoming.length}
					</Text>
					{incoming?.map((elem, index) => (
						<DirectFriendBase
							controls={
								<PendingControls
									variant="incoming"
									requestId={elem.requestId}
								/>
							}
							id={elem.senderId}
							userStatus={
								currentPresenceStatus
									? currentPresenceStatus[`${elem.senderId}`]
									: elem.senderUserStatus
							}
							key={index}
							name={elem.senderDisplayName}
							photoURL={elem.senderAvatarUrl}
							isAdmin={elem.senderIsAdmin}
							email={elem.senderEmail}
						/>
					))}
				</>
			) : null}
			{outgoing?.length ? (
				<>
					<Text
						mt="20px"
						mb="10px"
						color="blue.200"
						fontSize="16px"
						fontWeight={700}
						textTransform="uppercase">
						Outgoing - {outgoing.length}
					</Text>
					{outgoing.map((elem, index) => (
						<DirectFriendBase
							controls={
								<PendingControls
									variant="outgoing"
									requestId={elem.requestId}
								/>
							}
							id={elem.receiverId}
							key={index}
							name={elem.receiverDisplayName}
							photoURL={elem.receiverAvatarUrl}
							isAdmin={elem.receiverIsAdmin}
							email={elem.receiverEmail}
							userStatus={
								currentPresenceStatus
									? currentPresenceStatus[
											`${elem.receiverId}`
									  ]
									: elem.receiverUserStatus
							}
						/>
					))}
				</>
			) : null}
		</>
	);
};
