import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { CruzQueryKeys } from '../query-keys';

interface IPayload {
	propertyId: number;
	data: FormData;
}

export const useUpdateProperty = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, IPayload>({
		mutationFn: payload =>
			API_CTRL.put(
				`/api/app/properties/${payload.propertyId}/update`,
				payload.data,
			),
		onSuccess: (response, { propertyId }) => {
			if (response.success) {
				Alerter.success('Updated successfully');
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.PropertiesMoveInReady],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.PropertiesUnderConstruction],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.PropertyExtendInfo, propertyId],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.PropertyDetails, propertyId],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.OccupiedPropertySelector],
				});
			} else {
				Alerter.error(response.errors?.[0]?.message);
			}
		},
	});
};
