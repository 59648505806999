import { useMutation } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { FriendsRequestListResponse } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetPendingFriends = () => {
	return useMutation<ApiResponse<FriendsRequestListResponse>, unknown>({
		mutationFn: () => API_CHAT.get('/api/app/friends/requests', {}),
		mutationKey: [MessengerQueryKeys.GET_FRIENDS_REQUESTS],
		onSuccess(data) {
			if (!data.success) {
				return;
			}
		},
		onError(error) {
			console.log(error, 'er');
		},
	});
};
