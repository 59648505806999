import { FC } from 'react';
import { Flex, Text } from '@chakra-ui/react';

interface StatsItemWithIconProps {
	icon: JSX.Element;
	label: string;
	value: string;
}

export const StatsItemWithIcon: FC<StatsItemWithIconProps> = ({
	icon,
	value,
	label,
}) => {
	return (
		<Flex alignItems={'start'} gap={'10px'}>
			{icon}
			<Flex flexDirection={'column'} gap={'8px'}>
				<Text fontWeight={'600'} color={'blue.300'} fontSize={'16px'}>
					{label}
				</Text>
				<Text fontWeight={'700'} color={'blue.100'} fontSize={'18px'}>
					{value}
				</Text>
			</Flex>
		</Flex>
	);
};
