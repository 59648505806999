import { MESSAGES_PAGE_SIZE } from '../../../constants';
import { ConversationHistoryItem } from '../types';

export const checkIfHalfHasMore = (data: {
	messages: ConversationHistoryItem[];
	messageId: number;
	direction: 'prev' | 'next';
}) => {
	const messageIndex = data.messages.findIndex(
		elem => elem.id === data.messageId,
	);
	const lengthToStart = data.messages.slice(0, messageIndex + 1).length;
	const lengthToEnd = data.messages.slice(messageIndex + 1).length;
	if (data.direction === 'prev') {
		return lengthToStart >= MESSAGES_PAGE_SIZE / 2;
	}

	if (data.direction === 'next') {
		return lengthToEnd >= MESSAGES_PAGE_SIZE / 2;
	}
	return false;
};
