import React from 'react';
import { HStack, Button, Text } from '@chakra-ui/react';
import { XCircleIcon } from '../../../../../../assets';
import { useUnBlockUser } from '../../../../queries';

interface IBlockedControlsProps {
	blockedUserId: number;
}

export const BlockedControls: React.FC<IBlockedControlsProps> = ({
	blockedUserId,
}) => {
	const { isPending: unBlockLoading, mutateAsync: unBlockUser } =
		useUnBlockUser();
	const onUnblock = async () => {
		try {
			await unBlockUser(blockedUserId);
		} catch (error) {
			console.log(error, 'cancel error');
		}
	};

	return (
		<HStack>
			<Button
				colorScheme="blue"
				bg="blue.100"
				isLoading={unBlockLoading}
				onClick={onUnblock}>
				Unblock
			</Button>
			<HStack color="dangerButton.500" px="20px">
				<Text fontSize="16px" color="inherit">
					Blocked
				</Text>
				<XCircleIcon />
			</HStack>
		</HStack>
	);
};
