import React, { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';
import { SideBar } from './components';
import { useDefaultUserStore, useSidebarOpenStore } from '../../store';
import { Realtime } from 'ably';
import { AblyProvider, ChannelProvider } from 'ably/react';
import { getAblyClient } from '../../constants';
import * as Sentry from '@sentry/react';
import { Loader } from '../Loader/Loader';

export const DashboardLayout: React.FC = () => {
	const { isOpen, onToggle } = useSidebarOpenStore();

	// TEST
	const [socketClient, setSocketClient] = useState<Realtime | null>(null);

	const { user } = useDefaultUserStore();

	useEffect(() => {
		if (user?.userId) {
			const realtimeClient = getAblyClient(user?.userId);
			setSocketClient(realtimeClient);

			realtimeClient.connection.on('failed', err => {
				Sentry.captureException(err);
			});
		}
	}, [user?.userId]);

	// TEST

	if (!user?.userId || !socketClient) {
		return <Loader centerHeight={'100vh'} centerProps={{ w: '100%' }} />;
	}

	return (
		<AblyProvider client={socketClient}>
			<Box minH="100vh" background={'background'}>
				<ChannelProvider channelName={`unread-count:${user.userId}`}>
					<SideBar
						menuExpanded={isOpen}
						toggleMenu={onToggle}
						userId={user.userId}
					/>
				</ChannelProvider>
				<Box
					ml={{ base: 0, md: isOpen ? '250px' : '120px' }}
					transition=".3s"
					bg="mainBg"
					minH="100vh">
					<Outlet />
				</Box>
			</Box>
		</AblyProvider>
	);
};
