import { useQuery } from '@tanstack/react-query';
import { API_CTRL } from '../../../../api/api.base';
import { ApiResponse } from '../../../../types';
import { CruzQueryKeys } from '../query-keys';
import { BusinessEntityDetails } from '../../types';

interface IPayload {
	id?: number;
	isEnabled: boolean;
}

export const useGetBusinessEntity = (payload: IPayload) => {
	return useQuery({
		queryKey: [CruzQueryKeys.BusinessEntity, payload.id],
		queryFn: (): Promise<ApiResponse<BusinessEntityDetails>> =>
			API_CTRL.get(`/api/app/business-entity/${payload.id}/info`, {}),
		enabled: payload.isEnabled,
	});
};
