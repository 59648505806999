import { Box, Grid, HStack, IconButton, Text, VStack } from '@chakra-ui/react';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { NotificationLogoIcon, XCloseIcon } from '../../../assets';

interface IMessengerNotificationBodyProps {
	senderName: string;
	type: string;
	time: string;
	senderId?: number;
	channelName?: string;
	channelId?: number;
	conversationId?: number;
	message?: string;
	onClose: VoidFunction;
	redirectLink?: string;
}

export const MessengerNotificationBody: React.FC<
	IMessengerNotificationBodyProps
> = ({
	channelId,
	senderName,
	channelName,
	message,
	time,
	onClose,
	redirectLink,
	// conversationId,
	// senderId,
}) => {
	const navigate = useNavigate();

	const onClickRedirect = useCallback(() => {
		if (!redirectLink) {
			return;
		}
		navigate(redirectLink);
		onClose();
	}, [onClose, navigate, redirectLink]);

	return (
		<Grid
			w="448px"
			bg="white"
			borderRadius="10px"
			overflow="hidden"
			templateColumns="60px 1fr"
			boxShadow="0px 12px 24px #00000033, 0px 4px 8px #00000033"
			cursor="pointer"
			onClick={onClickRedirect}>
			<VStack justify="center" bg="mainBg" px="10px" h="full" maxW="60px">
				<Box minW="40px" w="40px">
					<NotificationLogoIcon />
				</Box>
			</VStack>
			<HStack p={4} align="flex-start" spacing={4}>
				<VStack flex={1} maxW="300px" spacing={1} align="flex-start">
					<HStack w="full" align="flex-start" justify="space-between">
						<VStack align="flex-start" spacing={1}>
							{channelId ? (
								<Text
									color="blue.50"
									maxW="210px"
									fontWeight={600}
									isTruncated={true}>
									{channelName}
								</Text>
							) : null}
							<Text
								color="blue.100"
								maxW="210px"
								fontWeight={600}
								isTruncated={true}>
								{senderName}
							</Text>
						</VStack>
						<Text
							color="inactiveText"
							fontSize="14px"
							lineHeight="19px">
							{dayjs(time).format('hh:mm A')}
						</Text>
					</HStack>
					<Text lineHeight="24px" noOfLines={2}>
						{message || 'You have a new message'}
					</Text>
				</VStack>
				<IconButton
					aria-label="close-notification"
					variant="solid"
					bg="mainBg"
					borderRadius="4px"
					color="blue.100"
					onClick={ev => {
						ev.stopPropagation();
						onClose();
					}}
					icon={<XCloseIcon />}
				/>
			</HStack>
		</Grid>
	);
};
