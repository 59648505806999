import { useQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { ReactionsModalListResponse } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetChannelMessageReactionsPreview = (
	messageId: number | null,
	enableFetch: boolean,
) => {
	return useQuery<ApiResponse<ReactionsModalListResponse>>({
		queryKey: [
			MessengerQueryKeys.GET_CHANNEL_MESSAGE_REACTIONS_PREVIEW,
			messageId,
		],
		queryFn: () =>
			API_CHAT.get('/api/app/channel/messages/reaction', {
				messageId,
				currentPage: 1,
				pageSize: 6,
			}),
		enabled: enableFetch && !!messageId,
	});
};
