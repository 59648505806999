import { useMutation } from '@tanstack/react-query';
import { Alerter } from '../../../utils';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';

export const useAcceptFriendRequest = () => {
	return useMutation<ApiResponseEmpty, unknown, number>({
		mutationFn: (userId: number) =>
			API_CHAT.post('/api/app/friend/accept-request', {
				friendRequestId: userId,
			}),
		onSuccess() {
			Alerter.success('Friend request approved successfully!');
		},

		onError(error) {
			console.log('useAcceptFriendRequest', error);
			Alerter.error('Error while approving');
		},
	});
};
