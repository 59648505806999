import {
	Box,
	Button,
	Center,
	Flex,
	HStack,
	IconButton,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import React from 'react';
import {
	DownloadIcon,
	FileIcon,
	InfoCircleIcon,
	TrashIcon,
} from '../../../../../assets';
import { UploadFileModal } from '../../UploadFileModal/UploadFileModal';
import { formatFileSize } from '../../../../../utils';
import { colors } from '../../../../../theme';

interface IProps {
	setFileToUpload: (value: File) => void;
	file?: File;
	isDisabled: boolean;
	removeFile: VoidFunction;
	leaseInPastWarningMessage: string;
}

export const UploadLeaseAgreement: React.FC<IProps> = ({
	setFileToUpload,
	file,
	isDisabled,
	removeFile,
	leaseInPastWarningMessage,
}) => {
	const {
		isOpen: isOpenUploadModal,
		onClose: onCloseUploadModal,
		onOpen: onOpenUploadModal,
	} = useDisclosure();

	return (
		<Box alignSelf={'flex-start'} w={{ base: 'full', md: 'auto' }}>
			<Text
				variant={'bodyMedium'}
				mb={'16px'}
				color="blue.100"
				fontWeight={600}>
				Lease Agreement
			</Text>
			{file ? (
				<Flex direction="column" align="flex-start" gap={2}>
					<Flex
						w={{ base: 'full', md: '352px' }}
						borderRadius={'8px'}
						border={'1px solid #E4EAF5'}
						px={'16px'}
						py={'8px'}
						justify={'space-between'}
						align={'center'}>
						<HStack spacing={'8px'}>
							<Center
								borderRadius={'full'}
								bg={'lightBlue.100'}
								minW={'40px'}
								minH={'40px'}>
								<FileIcon strokeColor={'#0F1B49'} />
							</Center>
							<Box>
								<Text
									noOfLines={1}
									fontWeight={600}
									color={'blue.50'}
									fontSize="14px"
									lineHeight="21px">
									{file.name}
								</Text>
								<Text
									variant={'bodySmall'}
									color={'inactiveText'}>
									{formatFileSize(file.size)}
								</Text>
							</Box>
						</HStack>
						<IconButton
							onClick={removeFile}
							variant={'ghost'}
							size={'sm'}
							aria-label={'remove'}
							icon={
								<TrashIcon
									height={'22'}
									width={'22'}
									strokeColor={'#4A5991'}
								/>
							}
						/>
					</Flex>
					{leaseInPastWarningMessage ? (
						<HStack spacing={1}>
							<Box boxSize="16px">
								<InfoCircleIcon
									width={16}
									height={16}
									fill={colors.inactiveText}
								/>
							</Box>
							<Text variant="bodySmall" color="inactiveText">
								{leaseInPastWarningMessage}
							</Text>
						</HStack>
					) : null}
				</Flex>
			) : (
				<Button
					isDisabled={isDisabled}
					onClick={onOpenUploadModal}
					variant={'ctrl-secondary'}
					w={{ base: 'full', md: '148px' }}
					borderRadius="8px"
					leftIcon={<DownloadIcon color={'currentcolor'} />}>
					Upload File
				</Button>
			)}
			<UploadFileModal
				setFileToUpload={setFileToUpload}
				isOpen={isOpenUploadModal}
				onClose={onCloseUploadModal}
			/>
		</Box>
	);
};
