import { API_CTRL } from '../../../../api/api.base';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { ApiResponseEmpty } from '../../../../types';
import { Alerter } from '../../../../utils';
import { CruzQueryKeys } from '../query-keys';

export const useCreateInvoice = () => {
	const queryClient = useQueryClient();
	return useMutation<ApiResponseEmpty, unknown, FormData>({
		mutationFn: payload => API_CTRL.post('/api/app/invoices', payload),
		onSuccess: response => {
			if (response.success) {
				Alerter.success('Created successfully');
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.Invoices],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.InvoiceOverview],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.TenantInvoiceOverview],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.TenantInvoices],
				});
			} else {
				Alerter.error(response.errors[0].message);
			}
		},
	});
};
