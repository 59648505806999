/* eslint-disable indent */
import React from 'react';
import {
	Box,
	Center,
	Flex,
	FlexProps,
	HStack,
	Icon,
	Link,
	Text,
	Tooltip,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { navItemColorPicker } from '../../../utils';
import { BadgeCounter } from '../../BadgeCounter/BadgeCounter';

interface NavItemProps extends FlexProps {
	icon: React.FC;
	children: React.ReactNode;
	link: string;
	isActive: boolean;
	menuExpanded: boolean;
	isDisabled: boolean;
	onAction?: VoidFunction;
	isInAccordion?: boolean;
	fontSize?: string;
	fontWeight?: string;
	disabledBadgeText?: string;
	showAvailableBadge?: boolean;
	heightPadding: string;
	unreadCount?: number;
}
// TODO: refactoring

export const NavItem: React.FC<NavItemProps> = ({
	icon,
	children,
	link,
	isActive,
	menuExpanded,
	isDisabled,
	onAction,
	isInAccordion = true,
	fontSize,
	fontWeight,
	disabledBadgeText,
	showAvailableBadge,
	heightPadding,
	unreadCount,
}) => {
	return (
		<Flex w="100%" justify={menuExpanded ? 'flex-start' : 'center'}>
			<Link
				pos={'relative'}
				cursor={isDisabled ? 'not-allowed' : 'pointer'}
				onClick={onAction}
				as={isDisabled ? undefined : RouterLink}
				to={link}
				width={menuExpanded ? 'inherit' : 'auto'}
				transition=".3s"
				borderRadius={8}
				color="white"
				bg={navItemColorPicker(isActive, menuExpanded, isInAccordion)}
				_hover={{
					bg: isInAccordion ? 'blue.100' : 'blue.200',
					color: 'white',
				}}
				p={heightPadding}>
				{isDisabled && disabledBadgeText ? (
					<Box
						mt={'auto'}
						fontSize={'8px'}
						pos={'absolute'}
						top={'-3px'}
						left={menuExpanded ? '' : '1px'}
						borderRadius={'5px'}
						padding={'1px 2px'}
						bg={'#4A5991'}
						color={'white'}>
						{disabledBadgeText}
					</Box>
				) : null}

				{showAvailableBadge ? (
					<Box
						fontSize={'9px'}
						pos={'absolute'}
						top={'-3px'}
						left={menuExpanded ? '' : '1px'}
						borderRadius={'5px'}
						padding={'2px 4px'}
						bg={'success'}
						color={'white'}>
						Available
					</Box>
				) : null}
				<HStack pos={'relative'}>
					<Tooltip
						fontSize="md"
						label={!menuExpanded ? children : ' '}
						placement="bottom"
						shouldWrapChildren={true}>
						<Center
							minW="20px"
							color={
								isDisabled
									? 'gray.500'
									: showAvailableBadge
									? 'success'
									: 'white'
							}>
							{icon ? (
								<Icon
									as={icon}
									color={
										isDisabled
											? 'gray.500'
											: showAvailableBadge
											? 'success'
											: 'white'
									}
									fontSize="22px"
									justifyContent="center"
								/>
							) : null}
						</Center>
					</Tooltip>
					{menuExpanded ? (
						<Text
							pos={'relative'}
							pl={'5px'}
							whiteSpace="nowrap"
							pr={10}
							fontSize={
								fontSize
									? fontSize
									: isInAccordion
									? '14px'
									: '16px'
							}
							color={
								isDisabled
									? 'gray.500'
									: showAvailableBadge
									? 'success'
									: 'white'
							}
							fontWeight={
								fontWeight
									? fontWeight
									: isInAccordion
									? isActive
										? 700
										: 500
									: 600
							}>
							{children}
						</Text>
					) : null}
					{unreadCount && menuExpanded ? (
						<BadgeCounter
							pos={'absolute'}
							right={0}
							value={unreadCount}
						/>
					) : null}

					{unreadCount && !menuExpanded ? (
						<Box
							pos={'absolute'}
							top={'-4px'}
							right={'-4px'}
							boxSize={'8px'}
							bg={'inactiveBlue'}
							borderRadius={'50%'}
						/>
					) : null}
				</HStack>
			</Link>
		</Flex>
	);
};
