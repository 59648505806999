/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable indent */
import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading } from '@chakra-ui/react';
import { useDebounce } from '../../hooks';
import { AutoComplete, CrimeReport, Rents } from '../../components';
import { PreciselyAutocompleteItem } from '../../types';
import { PropertyAttributes } from './components';
import { usePropertyAutocomplete, usePropertyDetails } from './queries';
import GuestModal from '../../modals/Guest/Guest.modal';
import propertyLookupScreen from '../../assets/images/property_lookup_screen.png';

const PropertyLookup: React.FC = () => {
	const [addressQuery, setAddressQuery] = useState('');
	const [address, setAddress] = useState<PreciselyAutocompleteItem>({
		coordinates: [0, 0],
		text: '',
	});
	const [isModalOpen, setIsModalOpen] = useState(false);

	const debouncedSearchQuery = useDebounce(addressQuery, 500);

	const {
		data: userPropertyAutocompleteData,
		isLoading: userPropertyAutocompleteLoading,
	} = usePropertyAutocomplete(debouncedSearchQuery);

	const {
		data: userPropertyDetailsData,
		isLoading: userPropertyDetailsLoading,
	} = usePropertyDetails(address);

	const handleModalClose = () => {
		setIsModalOpen(false);
	};

	useEffect(() => {
		if (userPropertyAutocompleteData?.errors?.length) {
			const showGuestModal = userPropertyAutocompleteData?.errors?.some(
				error =>
					error?.message?.includes(
						'Trial users can only perform 2 requests!',
					),
			);
			showGuestModal && setIsModalOpen(true);
		}
	}, [userPropertyAutocompleteData]);

	return (
		<Box p={{ base: '15px', md: '24px 59px' }}>
			<Flex flexDirection={'column'} gap={'32px'}>
				<Box>
					<Heading
						fontSize="xl"
						fontWeight={'700'}
						mb={'24px'}
						color={'blue.200'}>
						Property Lookup
					</Heading>
					<AutoComplete
						label=""
						// value={autocompleteValue}
						placeholder="Search by property address"
						setValue={setAddressQuery}
						isLoading={userPropertyAutocompleteLoading}
						onSelectItem={item => {
							const property =
								userPropertyAutocompleteData?.value?.models?.[
									item.id
								];

							if (item && property) {
								setAddress(property);
							} else {
								setAddress({
									coordinates: [0, 0],
									text: '',
								});
							}
						}}
						items={userPropertyAutocompleteData?.value?.models?.map(
							(elem, index) => ({
								id: index,
								name: elem.text,
							}),
						)}
					/>
				</Box>
				<GuestModal
					onClose={handleModalClose}
					isOpen={isModalOpen}
					title={'Looking for '}
					underlineTitle={'more property details?'}
					highlightedSubtitle={'Our FREE webinar '}
					secondSubtitle={'can help you explore further!'}
					buttonName={'Join us'}
					imageScreen={propertyLookupScreen}
				/>
				<CrimeReport
					loading={userPropertyDetailsLoading}
					items={
						userPropertyDetailsData?.value?.crimes?.length
							? userPropertyDetailsData?.value?.crimes[0].crimeIndexTheme?.indexVariable?.map(
									elem => ({
										name: elem.name,
										value: elem.category,
									}),
							  )
							: []
					}
				/>
				<Rents
					loading={userPropertyDetailsLoading}
					studio={userPropertyDetailsData?.value?.fmr0 || 0}
					br1={userPropertyDetailsData?.value?.fmr1 || 0}
					br2={userPropertyDetailsData?.value?.fmr2 || 0}
					br3={userPropertyDetailsData?.value?.fmr3 || 0}
					br4={userPropertyDetailsData?.value?.fmr4 || 0}
					countyName={
						userPropertyDetailsData?.value?.countyName || ''
					}
					stateCode={userPropertyDetailsData?.value?.stateCode || ''}
				/>
				<PropertyAttributes
					loading={userPropertyDetailsLoading}
					data={userPropertyDetailsData?.value?.attributes}
				/>
			</Flex>
		</Box>
	);
};

export default PropertyLookup;
