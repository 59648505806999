import { useMutation } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponseEmpty } from '../../../types';
import { MessengerQueryKeys } from './query-keys';

export const useUpdateChannelMessageReaction = () => {
	return useMutation<
		ApiResponseEmpty,
		unknown,
		{ channelMessageId: number; reactionEmojiId: number }
	>({
		mutationKey: [MessengerQueryKeys.UPDATE_CHANNEL_MESSAGE_REACTION],
		mutationFn: payload =>
			API_CHAT.put('/api/app/channel/messages/reaction', payload),
	});
};
