import React, { PropsWithChildren } from 'react';
import { Text, VStack } from '@chakra-ui/react';

interface ISectionWrapper extends PropsWithChildren {
	title?: string;
}

export const SectionWrapper: React.FC<ISectionWrapper> = ({
	title,
	children,
}) => {
	return (
		<VStack
			spacing={4}
			w="full"
			align="flex-start"
			p="16px"
			bg="blue.100"
			boxShadow="0px 4px 6px #00000033, 0px 0px 2px #00000033">
			{title ? (
				<Text
					color="white"
					fontWeight={700}
					px="10px"
					textTransform="uppercase">
					{title}
				</Text>
			) : null}
			<VStack spacing="5px" align="flex-start" w="full">
				{children}
			</VStack>
		</VStack>
	);
};
