import React, { useEffect } from 'react';
import { SidebarContent } from './SideBarContent';
import { MobileNav } from './MobileNav';
import { Drawer, DrawerContent, useDisclosure } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import { MenuContent } from './MenuContent';
import {
	useGetCurrentUser,
	useGetUnreadSidebarCounter,
	useHandleUnreadSocketEvent,
} from '../../../hooks';
import { useChannel } from 'ably/react';
import { filterAblyErrorsToSentry } from '../../../utils';

interface ISideBarProps {
	menuExpanded: boolean;
	toggleMenu: VoidFunction;
	userId: number;
}

export const SideBar: React.FC<ISideBarProps> = ({
	menuExpanded,
	toggleMenu,
	userId,
}) => {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const location = useLocation();

	const { data, isLoading } = useGetCurrentUser();

	const { data: unread } = useGetUnreadSidebarCounter();

	// const setSidebarUnreadData = useUnreadSidebarCounterStore(
	// 	state => state.setSidebarUnreadData,
	// );

	const { handleUnreadSocketEvent } = useHandleUnreadSocketEvent();

	useChannel(
		{
			channelName: `unread-count:${userId}`,
			onChannelError(error) {
				filterAblyErrorsToSentry(error);
			},
			onConnectionError(error) {
				filterAblyErrorsToSentry(error);
			},
		},
		handleUnreadSocketEvent,
	);

	useEffect(() => {
		onClose();
	}, [location.pathname]);

	// useEffect(() => {
	// 	if (unreadData?.value) {
	// 		setSidebarUnreadData(unreadData.value);
	// 	}
	// }, [isRefetching]);

	return (
		<>
			<SidebarContent
				data={data}
				isLoading={isLoading}
				onClose={onClose}
				display={{ base: 'none', md: 'flex' }}
				menuExpanded={menuExpanded}
				toggleMenu={toggleMenu}
				amountUnreadAdminMessages={
					unread?.value?.amountUnreadAdminMessages || 0
				}
				amountUnreadCommunity={
					unread?.value?.amountUnreadCommunity || 0
				}
			/>
			<Drawer
				autoFocus={false}
				isOpen={isOpen}
				placement="left"
				onClose={onClose}
				returnFocusOnClose={false}
				onOverlayClick={onClose}
				size="full">
				<DrawerContent>
					<MenuContent
						amountUnreadAdminMessages={
							unread?.value?.amountUnreadAdminMessages || 0
						}
						amountUnreadCommunity={
							unread?.value?.amountUnreadCommunity || 0
						}
						onClose={onClose}
						isLoading={isLoading}
						data={data}
					/>
				</DrawerContent>
			</Drawer>
			<MobileNav display={{ base: 'flex', md: 'none' }} onOpen={onOpen} />
		</>
	);
};
