import React, { useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import {
	getBusinessEntityStripeReconnectLink,
	useConnectBusinessEntityStripe,
} from '../../../queries/business-entity';
import {
	EmailValidationPattern,
	inputLabelStyles,
	inputStyles,
	toolTipStyles,
} from '../../../constants';
import { InputText } from '../../../../../components';
import { useQueryClient } from '@tanstack/react-query';
import { CruzQueryKeys } from '../../../queries/query-keys';
import { WarningIcon } from '../../../../../assets';

interface IStripeStatusConnection {
	id: number;
	isCompleted: boolean;
	isAdded: boolean;
	stripeEmail: string | null;
	onOpenDisconnectDialog?: VoidFunction;
}

export const StripeStatusConnection: React.FC<IStripeStatusConnection> = ({
	isCompleted,
	isAdded,
	id,
	stripeEmail,
	onOpenDisconnectDialog,
}) => {
	const queryClient = useQueryClient();
	const connectStripeMutation = useConnectBusinessEntityStripe();

	const [loading, setLoading] = useState(false);
	const [email, setEmail] = useState(stripeEmail || '');

	const onReconnectStripe = async (id: number) => {
		try {
			setLoading(true);

			const res = await getBusinessEntityStripeReconnectLink(id);
			if (!res?.success) {
				return;
			}

			if (!res?.value) {
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.BusinessEntity, id],
				});
				queryClient.invalidateQueries({
					queryKey: [CruzQueryKeys.BusinessEntities, false],
				});
				return;
			}
			window.location.href = res.value;
		} catch (error) {
			console.log('onGetStripeLink error: ', error);
		} finally {
			setLoading(false);
		}
	};

	const onConnectStripe = (id: number, email: string) => {
		if (!email || !isEmailValid) {
			return;
		}
		connectStripeMutation.mutate({ id, email });
	};

	const isEmailValid = useMemo(() => {
		const regex = new RegExp(EmailValidationPattern);
		return regex.test(email);
	}, [email]);

	useEffect(() => {
		if (stripeEmail && !email) {
			setEmail(stripeEmail);
		}
	}, [stripeEmail]);

	return (
		<Flex direction={{ base: 'column', md: 'row' }} gap={'16px'}>
			<Box flex={1}>
				<InputText
					value={email}
					onChange={e => setEmail(e.target.value)}
					{...inputStyles}
					placeholder={'Enter Email'}
					isDisabled={isAdded}
					formLabelProps={inputLabelStyles}
				/>
			</Box>

			<Flex
				direction={{ base: 'column', md: 'row' }}
				align="center"
				gap={2}
				flex={1}>
				{!isCompleted && isAdded ? (
					<HStack>
						<Tooltip
							label="Account requires updates. Charges and payouts were paused"
							{...toolTipStyles}
							textAlign="center">
							<Box w="20px" minW="20px" h="20px">
								<WarningIcon />
							</Box>
						</Tooltip>
						<Text
							display={{ base: 'block', md: 'none' }}
							variant="bodySmall">
							Account requires updates. Charges and payouts were
							paused
						</Text>
					</HStack>
				) : null}

				{!isCompleted ? (
					<Button
						h={'40px'}
						bg={'inactiveBlue'}
						w={{ base: '100%', md: '160px' }}
						minW={'160px'}
						borderRadius={'8px'}
						fontSize={'14px'}
						lineHeight={'17px'}
						color={'white'}
						fontWeight={600}
						isLoading={connectStripeMutation.isPending || loading}
						isDisabled={!isEmailValid}
						onClick={() => {
							if (!isCompleted && !isAdded) {
								onConnectStripe(id, email);
								return;
							}
							if (!isCompleted && isAdded) {
								onReconnectStripe(id);
								return;
							}
						}}
						_hover={{
							bg: 'inactiveBlue',
							_disabled: {
								opacity: 0.4,
								cursor: 'not-allowed',
							},
						}}
						_active={{
							bg: 'inactiveBlue',
						}}
						_disabled={{
							opacity: 0.4,
							cursor: 'not-allowed',
						}}>
						{isAdded ? 'Reconnect' : 'Connect'}
					</Button>
				) : null}
				{isAdded ? (
					<Button
						h={'40px'}
						bg={'valueRed'}
						w={{ base: '100%', md: '160px' }}
						minW={'160px'}
						borderRadius={'8px'}
						fontSize={'14px'}
						lineHeight={'17px'}
						color={'white'}
						fontWeight={600}
						isDisabled={!isEmailValid}
						onClick={onOpenDisconnectDialog}
						_hover={{
							bg: 'valueRed',
							_disabled: {
								opacity: 0.4,
								cursor: 'not-allowed',
							},
						}}
						_active={{
							bg: 'valueRed',
						}}
						_disabled={{
							opacity: 0.4,
							cursor: 'not-allowed',
						}}>
						Disconnect
					</Button>
				) : null}
			</Flex>
		</Flex>
	);
};
