import { BillingCycleEnum, InvoiceTypeEnum, TenantStatus } from '../types';

export const INVOICE_TYPE = [
	{
		value: 0,
		label: 'All Types',
	},
	{
		value: InvoiceTypeEnum.Private,
		label: 'Private',
	},
	{
		value: InvoiceTypeEnum.Trillium,
		label: 'Trillium',
	},
	{
		value: InvoiceTypeEnum.Section8,
		label: 'Section 8',
	},
	{
		value: InvoiceTypeEnum.SecurityDeposit,
		label: 'Security Deposit',
	},
];

export const TENANT_STATUS_TYPE = [
	{
		value: 0,
		label: 'All Types',
	},
	{
		value: TenantStatus.Active,
		label: 'Active',
	},
	{
		value: TenantStatus.Pending,
		label: 'Pending',
	},
	{
		value: TenantStatus.LeaseExpired,
		label: 'Expired',
	},
];

export const BASE_BILLING_CYCLE = {
	amount: null,
	invoiceName: '',
	invoiceType: null,
	invoiceDate: '',
	dueDays: undefined,
	paymentMethod: null,
	billingCycle: null,
	id: 0,
};

export const defaultTenantValues = {
	firstName: '',
	lastName: '',
	email: '',
	phone: '',
	property: null,
	unit: null,
	leaseStartDate: '',
	leaseEndDate: '',
	billingCycles: [],
	leaseAgreement: undefined,
	// isSentLeaseAgreement: false,
};

export const BILLING_CYCLE_OPTIONS = [
	{
		label: 'One-off',
		value: BillingCycleEnum.OneOff,
	},
	{
		label: 'Monthly',
		value: BillingCycleEnum.Monthly,
	},
	{
		label: 'Quarterly',
		value: BillingCycleEnum.Quarterly,
	},
	{
		label: 'Annually',
		value: BillingCycleEnum.Annually,
	},
];
