import React from 'react';
import { Button, ButtonProps, Text, chakra } from '@chakra-ui/react';

interface IMenuButton {
	highlightedLabel?: string;
	label: string;
	buttonProps?: ButtonProps;
}

export const MenuButton = React.forwardRef<HTMLButtonElement, IMenuButton>(
	({ highlightedLabel, label, buttonProps }, ref) => {
		return (
			<Button
				ref={ref}
				variant="ghost"
				justifyContent="flex-start"
				borderRadius="4px"
				w="full"
				bg="transparent"
				px="10px"
				_hover={{
					bg: 'blue.200',
				}}
				{...buttonProps}>
				<Text color="white">
					{highlightedLabel ? (
						<React.Fragment>
							<chakra.span fontWeight={600}>
								{highlightedLabel}:
							</chakra.span>{' '}
						</React.Fragment>
					) : null}
					{label}
				</Text>
			</Button>
		);
	},
);

MenuButton.displayName = 'MenuButton';
