import React, { useEffect, useMemo } from 'react';
import { Text, Center } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useChannel } from 'ably/react';
import { DirectFriendBase, BlockedControls } from '../components';
import { Loader } from '../../../../components';
import { useChatUserStore } from '../../../../store';
import { useGetBlockList } from '../../queries';
import { usePolling } from '../../hooks';
import { useHandleBlockedTabSocketEvents } from '../hooks';
import { filterAblyErrorsToSentry } from '../../../../utils';

interface IBlockedTabProps {
	index: number;
}

export const BlockedTab: React.FC<IBlockedTabProps> = ({ index }) => {
	const { blockedUsers, handleBlockedTabSocketEvents, setBlockedUsers } =
		useHandleBlockedTabSocketEvents();

	const { user } = useChatUserStore();

	useChannel(
		{
			channelName: `private-user:${user?.userId}`,
			onChannelError: err => {
				filterAblyErrorsToSentry(err);
			},
			onConnectionError: err => {
				filterAblyErrorsToSentry(err);
			},
		},
		message => {
			handleBlockedTabSocketEvents(message);
		},
	);

	const { isPending: loading, mutateAsync: getBlockListUsers } =
		useGetBlockList();

	const userIds = useMemo(() => {
		return blockedUsers.map(friend => friend.userId);
	}, [blockedUsers.length]);

	const currentPresenceStatus = usePolling(userIds, index === 3);

	const onGetBlockListUsers = async () => {
		try {
			const res = await getBlockListUsers({
				currentPage: 1,
				pageSize: 100,
			});

			if (!res.value || !res.success) {
				return;
			}

			setBlockedUsers(res.value.users);
		} catch (e) {
			console.log(e);
		}
	};

	useEffect(() => {
		if (user?.userId && index === 3) {
			onGetBlockListUsers();
		}
	}, [user?.userId, index]);

	return (
		<>
			{loading ? (
				<Loader centerHeight="500px" />
			) : (
				<>
					{blockedUsers.length ? (
						<>
							<Text
								mt="20px"
								mb="10px"
								color="blue.200"
								fontSize="16px"
								fontWeight={700}>
								BLOCKED - {blockedUsers.length}
							</Text>
							<InfiniteScroll
								dataLength={blockedUsers.length}
								next={() => {
									// getMoreBlockedUsers();
								}}
								hasMore={false}
								height={'calc(100vh - 120px)'}
								loader={<Loader spinnerSize="md" />}>
								{blockedUsers.map(elem => (
									<DirectFriendBase
										name={elem.displayName}
										photoURL={elem.avatarStoragePath}
										key={elem.userId}
										id={elem.userId}
										isAdmin={elem.isAdmin}
										email={elem.email}
										userStatus={
											currentPresenceStatus
												? currentPresenceStatus[
														`${elem.userId}`
												  ]
												: elem.userStatus
										}
										controls={
											<BlockedControls
												blockedUserId={+elem.userId}
											/>
										}
									/>
								))}
							</InfiniteScroll>
						</>
					) : (
						<Center height="500px">
							<Text color="blue.50">No blocked users</Text>
						</Center>
					)}
				</>
			)}
		</>
	);
};
