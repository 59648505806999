import React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { ChannelProvider } from 'ably/react';
import { Roles, ChannelHeader } from './components';
import {
	useMessengerSidebarsOpenStore,
	useChatUserStore,
} from '../../../store';
import { ServerMessagesCore } from './ServerMessagesCore';
import { useGetChannelById } from '../queries';
import { SearchResults } from '../common-components';
import { ChatType } from '../types';

export const ServerMessages: React.FC = () => {
	const { id } = useParams();

	const { user } = useChatUserStore();

	const { isOpenMembersSidebar, isOpenSearchResultSidebar } =
		useMessengerSidebarsOpenStore();

	const { data: channelInfo, isLoading: loadingChannelInfo } =
		useGetChannelById(Number(id), !!id);

	return (
		<ChannelProvider channelName={`private-channel:${id}`}>
			<Flex direction="column" width="100%">
				<ChannelHeader id={id ? +id : 0} />
				<Flex flex={1}>
					<ServerMessagesCore
						channelData={channelInfo?.value}
						id={id}
						loadingChannelInfo={loadingChannelInfo}
					/>

					<Box
						width="300px"
						display={isOpenMembersSidebar ? 'block' : 'none'}
						bg="blue.200"
						height="calc(100vh - 60px)">
						<Roles
							channelId={id}
							isPrivate={false}
							isAdmin={Boolean(user?.isAdmin)}
						/>
					</Box>
					<Box
						width="400px"
						display={isOpenSearchResultSidebar ? 'block' : 'none'}
						bg="dropBlue"
						height="calc(100vh - 60px)">
						<SearchResults
							id={id ? +id : 0}
							chatType={ChatType.CHANNEL}
						/>
					</Box>
				</Flex>
			</Flex>
		</ChannelProvider>
	);
};
