import { useInfiniteQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { useChannelSearchStore } from '../../../store';
import { ApiResponse } from '../../../types';
import { SearchContentTypeOptionEnum, ConversationHistoryItem } from '../types';
import { MessengerQueryKeys } from './query-keys';

const PAGE_SIZE = 30;

interface IPayload {
	channelId: number;
	isEnabled: boolean;
}

interface FetchFnProps extends Omit<IPayload, 'isEnabled'> {
	pageParam: number;
	authorId?: number;
	mentionId?: number;
	contentType?: SearchContentTypeOptionEnum;
	pinned?: boolean;
	fromDateTime?: string;
	toDateTime?: string;
	search: string;
}

export const useGetChannelMessagesBySearch = ({
	channelId,
	isEnabled,
}: IPayload) => {
	const {
		authorId,
		fromDateTime,
		contentType,
		mentionId,
		pinned,
		search,
		toDateTime,
		isEnabledSearchMessages,
	} = useChannelSearchStore();

	const fetchFn = async ({
		pageParam = 1,
		channelId,
		authorId,
		fromDateTime,
		contentType,
		toDateTime,
		mentionId,
		pinned,
		search,
	}: FetchFnProps) => {
		const res: ApiResponse<{
			count: number;
			messages: ConversationHistoryItem[];
		}> = await API_CHAT.get('/api/app/channel/messages/search', {
			pageSize: PAGE_SIZE,
			currentPage: pageParam,
			channelId,
			authorId,
			fromDateTime,
			contentType,
			toDateTime,
			mentionId,
			pinned,
			search: search || undefined,
		});

		return res.value;
	};

	return useInfiniteQuery({
		queryKey: [
			MessengerQueryKeys.CHANNEL_MESSAGES_BY_SEARCH,
			channelId,
			authorId,
			contentType,
			fromDateTime,
			toDateTime,
			mentionId,
			pinned,
			search,
		],
		queryFn: ({ pageParam }) =>
			fetchFn({
				pageParam,
				channelId,
				authorId,
				contentType,
				fromDateTime,
				toDateTime,
				mentionId,
				pinned,
				search,
			}),
		initialPageParam: 1,
		getNextPageParam: (lastPage, allPages) => {
			return lastPage?.messages?.length === PAGE_SIZE
				? allPages.length + 1
				: null;
		},
		select: data => {
			return {
				items: [...data.pages.map(it => it?.messages)].flat(),
				totalCount: data.pages?.[0]?.count || 0,
			};
		},
		enabled: isEnabledSearchMessages && isEnabled,
	});
};
