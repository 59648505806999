import { useQuery } from '@tanstack/react-query';
import { API_CHAT } from '../../../api/api.base';
import { ApiResponse } from '../../../types';
import { MessageByIdResponse } from '../types';
import { MessengerQueryKeys } from './query-keys';

export const useGetPrivateMessageById = (id?: number | null) => {
	return useQuery<ApiResponse<MessageByIdResponse | null>>({
		queryKey: [MessengerQueryKeys.GET_PRIVATE_MESSAGE_BY_ID, id],
		queryFn: () =>
			API_CHAT.get(`/api/app/conversation/messages/${id}/private`, {}),
		enabled: Boolean(id),
	});
};
