import { useQuery } from '@tanstack/react-query';
import { ApiResponse, PreciselyAutocompleteItem } from '../../../types';
import API from '../../../api/api.base';
import { PreciselyPropertyDetailsResponse } from '../types';
import { QueryKeys } from '../../../constants';
import { useIsGuest } from '../../../hooks';

export const usePropertyDetails = (address: PreciselyAutocompleteItem) => {
	const isGuest = useIsGuest();
	return useQuery<ApiResponse<PreciselyPropertyDetailsResponse>>({
		queryKey: [QueryKeys.GET_PRECISELY_PROPERTY_DETAILS, address],
		queryFn: () =>
			API.get(
				`/api/app/${isGuest ? 'guest/' : ''}precisely/property/details`,
				{
					Address: address.text,
					Longitude: address.coordinates[0],
					Latitude: address.coordinates[1],
				},
			),
		enabled: Boolean(address.coordinates?.[0] || address.coordinates?.[0]),
		refetchOnMount: false,
	});
};
