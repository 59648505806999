/* eslint quotes: ["off"] */

import MarkdownIt from 'markdown-it';
import { spoilerPlugin, mentionPlugin } from './plugins';

export const markdownInstance = new MarkdownIt({
	linkify: true,
	typographer: true,
})
	.use(spoilerPlugin)
	.use(mentionPlugin)
	.disable(['image', 'table']);

markdownInstance.renderer.rules.blockquote_open = function () {
	return `<blockquote class="blockquote-content">`;
};

markdownInstance.renderer.rules.s_open = function () {
	return `<s class="strike-through-content">`;
};

markdownInstance.renderer.rules.code_inline = function (tokens, idx) {
	const token = tokens[idx];
	const tokenContent = token.content || '';

	return `<code class="code-inline"><span class="code-inline-content">${tokenContent}</span></code>`;
};

markdownInstance.renderer.rules.edited = function (_tokens, _idx) {
	return `<span class="edited-mark">(edited)</span>`;
};

markdownInstance.renderer.rules.mention = (tokens, idx) => {
	const token = tokens[idx];
	const tokenContent = token.content || '';
	const name = tokenContent.match(/\[([^\]]+)\]/)?.[1];
	const id = tokenContent.match(/\((.*?)\)/)?.[1];

	return `<span  class="text-mention ${
		id === '0' ? 'text-mention--everyone' : 'text-mention--person'
	}">@${name}</span>`;
};

markdownInstance.renderer.rules.link_open = function (tokens, idx) {
	const href = tokens[idx]?.attrs?.[0]?.[1] || '';
	return `<a href="${href}" target="_blank" class="link-wrapper">`;
};

// markdownInstance.inline.ruler.before('text', 'spoiler', state => {
// 	const startPos = state.pos;
// 	const regex = /\%\%.*?\%\%/g;
// 	regex.lastIndex = startPos;
// 	const match = regex.exec(state.src);

// 	if (match && match.index === startPos) {
// 		state.push('spoiler_open', 'span', 1);

// 		state.pos += 2;

// 		state.md.inline.tokenize(state);

// 		state.push('spoiler_close', 'span', -1);

// 		state.pos = match.index + match[0].length;

// 		// Modify the content of the text token to remove %%
// 		const token = state.tokens[state.tokens.length - 2];
// 		token.content = token.content.slice(0, -2);

// 		return true;
// 	}

// 	return false;
// });

// markdownInstance.renderer.rules.spoiler_open = () => {
// 	return `<span class="spoiler-wrapper spoiler-wrapper--hide" aria-expanded="false" role="button" onclick="handleSpoilerVisible(this)"><span class="spoiler-content spoiler-content--hide" aria-hidden="true">`;
// };
