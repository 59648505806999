import React, { useMemo } from 'react';
import { Box, Button, Skeleton, Text, VStack } from '@chakra-ui/react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NoSearchResultIcon } from '../../../assets';
import { Loader } from '../../../components';
import { useMessageNavigationStore } from '../../../store';
import { MessageWithAvatar } from '../messages-components';
import {
	useGetChannelMessagesBySearch,
	useGetDirectMessagesBySearch,
} from '../queries';
import { ChatType } from '../types';
import { getTimeAndDate } from '../utils';

interface ISearchResults {
	id: number;
	chatType: ChatType;
}

export const SearchResults: React.FC<ISearchResults> = ({ id, chatType }) => {
	const { setMessageAnchorId } = useMessageNavigationStore();

	const channelSearchMessages = useGetChannelMessagesBySearch({
		channelId: id,
		isEnabled: chatType === ChatType.CHANNEL && !!id,
	});

	const directSearchMessages = useGetDirectMessagesBySearch({
		conversationId: id,
		isEnabled: chatType === ChatType.DIRECT && !!id,
	});

	const { data, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
		useMemo(() => {
			if (chatType === ChatType.CHANNEL) {
				return channelSearchMessages;
			}
			return directSearchMessages;
		}, [chatType, channelSearchMessages, directSearchMessages]);

	const resultHeader = useMemo(() => {
		const totalCount = data?.totalCount;
		if (!totalCount) {
			return 'No Results';
		}
		if (totalCount === 1) {
			return '1 Result';
		}
		return `${totalCount} Results`;
	}, [data?.totalCount]);

	return (
		<React.Fragment>
			<Box bg="blue.200" p="15px 10px">
				{isLoading && !isFetchingNextPage ? (
					<Skeleton
						h="19px"
						w="100px"
						startColor="blue.50"
						endColor="blue.100"
					/>
				) : (
					<Text color="white">{resultHeader}</Text>
				)}
			</Box>

			<InfiniteScroll
				dataLength={data?.items?.length || 0}
				refreshFunction={fetchNextPage}
				next={fetchNextPage}
				hasMore={!!hasNextPage}
				scrollThreshold={0.8}
				className="custom-scroll3"
				height="calc(100vh - 110px)"
				loader={<Loader centerHeight="40px" spinnerSize="sm" />}
				style={{
					paddingTop: '40px',
					display: 'flex',
					flexDirection: 'column',
					padding: '10px',
					width: '100%',
					background: 'dropBlue',
					overflowX: 'hidden',
				}}>
				{isLoading && !isFetchingNextPage ? (
					<Loader centerHeight="full" />
				) : data?.items?.length ? (
					data.items.map((el, index) => (
						<Box
							key={el.id}
							w="full"
							bg="mainBg"
							py="5px"
							px="20px"
							mt={index !== 0 ? '10px' : '0px'}
							borderRadius="4px"
							position="relative"
							_hover={{
								'> button:last-of-type': {
									visibility: 'visible',
									opacity: 1,
								},
							}}>
							<MessageWithAvatar
								isEdit={false}
								avatarImage={el.avatarUrl || ''}
								sentByDisplayName={el.senderName}
								text={el.text || ''}
								date={getTimeAndDate(new Date(el.sentAt))}
								isEdited={el.isEdited}
								isSameSenderNext={true}
								showMenu={false}
								type={el.type}
								replyToId={el.replyToId}
								id={el.id}
								isAdmin={false}
								chatType={chatType}
								sentById={el.senderId}
								hideReactions={true}
								messageAttachmentFiles={el.attachmentFiles}
								messageAttachmentImages={el.attachmentImages}
								messageAttachmentVideos={el.attachmentVideos}
							/>
							<Button
								position="absolute"
								visibility="hidden"
								opacity={0}
								transition="visibility .2s ease, opacity .2s ease, color .2s ease"
								top="5px"
								right="5px"
								variant="ghost"
								bg="blue.100"
								color="dropBlue"
								fontSize="12px"
								lineHeight="14px"
								p="5px"
								h="24px"
								borderRadius="2px"
								onClick={() => setMessageAnchorId(el.id)}
								_hover={{ color: 'white' }}>
								Jump
							</Button>
						</Box>
					))
				) : (
					<VStack w="full" h="full" spacing="30px" justify="center">
						<NoSearchResultIcon />
						<Text
							color="blue.50"
							lineHeight="24px"
							fontWeight={600}>
							No search results
						</Text>
					</VStack>
				)}
				{/* {isFetchingNextPage ? (
					<Loader centerHeight="40px" spinnerSize="sm" />
				) : null} */}
			</InfiniteScroll>
		</React.Fragment>
	);
};
