import React from 'react';
import { Box, BoxProps, IconButton } from '@chakra-ui/react';
import { MessageWithAvatar } from '../../../messages-components';
import { XCloseIcon } from '../../../../../assets';
import {
	EditMessageDisplayData,
	ConversationHistoryItem,
	ChatType,
} from '../../../types';
import { getTime, getTimeAndDate } from '../../../utils';

interface IPinnedMessageItemProps extends ConversationHistoryItem {
	onSetMessageToUnPin: (value: EditMessageDisplayData) => void;
	isCurrentUserAdmin?: boolean;
	wrapperProps?: BoxProps;
	onReplyPress: (replyId: number) => void;
}

export const PinnedMessageItem: React.FC<IPinnedMessageItemProps> = ({
	avatarUrl,
	id,
	replyToId,
	text,
	isEdited,
	senderId,
	senderName,
	sentAt,
	isAdmin,
	type,
	isCurrentUserAdmin,
	onSetMessageToUnPin,
	attachmentFiles,
	attachmentImages,
	attachmentVideos,
	wrapperProps,
	onReplyPress,
}) => {
	const onUnPin = () => {
		onSetMessageToUnPin({
			avatarImage: avatarUrl || '',
			sentByDisplayName: senderName,
			text: text || '',
			isEdited,
			type,
			replyToId: replyToId || null,
			id: +id,
			attachmentFiles,
			attachmentImages,
			attachmentVideos,
			date: getTime(new Date(sentAt)),
		});
	};

	return (
		<Box
			pl="20px"
			pr="50px"
			py="8px"
			bg="white"
			position="relative"
			borderRadius="4px"
			{...wrapperProps}>
			<MessageWithAvatar
				isEdit={false}
				avatarImage={avatarUrl || ''}
				sentByDisplayName={senderName}
				text={text || ''}
				date={getTimeAndDate(new Date(sentAt))}
				isEdited={isEdited}
				isSameSenderNext={true}
				showMenu={false}
				type={type}
				replyToId={replyToId}
				id={id}
				isAdmin={isAdmin}
				chatType={ChatType.CHANNEL}
				sentById={senderId}
				hideReactions={true}
				messageAttachmentFiles={attachmentFiles}
				messageAttachmentImages={attachmentImages}
				messageAttachmentVideos={attachmentVideos}
				onReplyPress={onReplyPress}
				isReplyMessageJumpEnabled={true}
			/>
			{isCurrentUserAdmin ? (
				<IconButton
					aria-label="Unpin message"
					variant="ghost"
					position="absolute"
					right="10px"
					top="6px"
					w="30px"
					minW="30px"
					h="30px"
					onClick={onUnPin}>
					<XCloseIcon />
				</IconButton>
			) : null}
		</Box>
	);
};
