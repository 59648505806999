import { FC } from 'react';

interface MoneyOnHandIconProps {
	stroke?: string;
	width?: string;
	height?: string;
}

export const MoneyOnHandIcon: FC<MoneyOnHandIconProps> = ({
	height = '22',
	stroke = '#0F1B49',
	width = '22',
}) => {
	return (
		<svg
			width={width}
			height={height}
			viewBox="0 0 22 22"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M12.5295 7.35186C11.9571 7.75995 11.2566 8 10.5 8C8.567 8 7 6.433 7 4.5C7 2.567 8.567 1 10.5 1C11.753 1 12.8522 1.65842 13.4705 2.64814M5 19.0872H7.61029C7.95063 19.0872 8.28888 19.1277 8.61881 19.2086L11.3769 19.8789C11.9753 20.0247 12.5988 20.0388 13.2035 19.9214L16.253 19.3281C17.0585 19.1712 17.7996 18.7854 18.3803 18.2205L20.5379 16.1217C21.154 15.5234 21.154 14.5524 20.5379 13.9531C19.9832 13.4134 19.1047 13.3527 18.4771 13.8103L15.9626 15.6449C15.6025 15.9081 15.1643 16.0498 14.7137 16.0498H12.2855L13.8311 16.0498C14.7022 16.0498 15.4079 15.3633 15.4079 14.5159V14.2091C15.4079 13.5055 14.9156 12.892 14.2141 12.7219L11.8286 12.1417C11.4404 12.0476 11.0428 12 10.6431 12C9.67833 12 7.93189 12.7988 7.93189 12.7988L5 14.0249M19 5.5C19 7.433 17.433 9 15.5 9C13.567 9 12 7.433 12 5.5C12 3.567 13.567 2 15.5 2C17.433 2 19 3.567 19 5.5ZM1 13.6L1 19.4C1 19.9601 1 20.2401 1.10899 20.454C1.20487 20.6422 1.35785 20.7951 1.54601 20.891C1.75992 21 2.03995 21 2.6 21H3.4C3.96005 21 4.24008 21 4.45399 20.891C4.64215 20.7951 4.79513 20.6422 4.89101 20.454C5 20.2401 5 19.9601 5 19.4V13.6C5 13.0399 5 12.7599 4.89101 12.546C4.79513 12.3578 4.64215 12.2049 4.45399 12.109C4.24008 12 3.96005 12 3.4 12L2.6 12C2.03995 12 1.75992 12 1.54601 12.109C1.35785 12.2049 1.20487 12.3578 1.10899 12.546C1 12.7599 1 13.0399 1 13.6Z"
				stroke={stroke}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};
