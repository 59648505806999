import { create } from 'zustand';

type StoreType = {
	status: number;
};

type Actions = {
	setInvoiceStatus: (value: number) => void;
	resetInvoiceStatus: () => void;
};

const INITIAL_STATE: StoreType = {
	status: 0,
};

export const useTenantInvoiceStore = create<Actions & StoreType>(set => ({
	...INITIAL_STATE,
	setInvoiceStatus: value => set({ status: value }),
	resetInvoiceStatus: () => set({ status: 0 }),
}));
