import React from 'react';
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	IconButton,
	Flex,
	Box,
	Text,
	Button,
	Image,
	Link,
} from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

interface IGuestModalProps {
	isOpen: boolean;
	onClose: () => void;
	title: string;
	underlineTitle: string;
	subtitle?: string;
	highlightedSubtitle: string | React.ReactNode;
	buttonName: string;
	imageScreen: string;
	secondSubtitle?: string;
	move?: boolean;
}

const GuestModal: React.FC<IGuestModalProps> = ({
	isOpen,
	onClose,
	title,
	underlineTitle,
	subtitle,
	highlightedSubtitle,
	buttonName,
	imageScreen,
	secondSubtitle,
	move = false,
}) => {
	const getNextWednesdayAt9PM = () => {
		const now = dayjs().tz('America/New_York');
		const thisWednesday = now.day(3).hour(21).minute(0).second(0);

		return now.isBefore(thisWednesday)
			? thisWednesday
			: thisWednesday.add(1, 'week');
	};
	const nextWednesday = getNextWednesdayAt9PM().format('MMM D');
	return (
		<Modal isCentered={true} size="3xl" isOpen={isOpen} onClose={onClose}>
			<ModalOverlay backdropFilter="blur(3px)" />
			<ModalContent>
				<ModalBody p={0}>
					<Flex>
						<Box
							position="relative"
							bg={'blue.100'}
							w={'285px'}
							h={'420px'}
							borderRadius={'5px 0 0 5px'}>
							<Image
								position={'absolute'}
								top={'50%'}
								left={'50%'}
								transform={['translate(-50%, -50%)']}
								src={imageScreen}></Image>
						</Box>
						<Box w={'483px'} mt={'20px'}>
							<Flex justifyContent={'flex-end'} mr={'20px'}>
								<IconButton
									background={'none'}
									aria-label="close"
									onClick={onClose}>
									<CloseIcon />
								</IconButton>
							</Flex>
							<Flex
								flexDirection={'column'}
								justifyContent={'center'}
								alignItems={'center'}
								p={'72px 40px 0'}>
								<Text
									mb={'16px'}
									color={'blue.200'}
									fontWeight={700}
									variant={'bodyLarge'}>
									{title}
									<span
										style={{
											textDecoration: 'underline',
											textDecorationColor: '#08BB4B',
											textUnderlineOffset: '5px',
										}}>
										{underlineTitle}
									</span>
								</Text>
								<Text
									lineHeight={'24px'}
									paddingX={'16px'}
									paddingY={'5px'}
									textAlign={'center'}
									backgroundColor={'blue.200'}
									mb={'32px'}
									color={'#FFFFFF'}
									fontWeight={500}
									variant={'bodyMedium'}>
									<Text
										as="span"
										fontWeight={700}
										color={'#08BB4B'}>
										{highlightedSubtitle}
									</Text>
									on {nextWednesday} at 8 PM EST
									{subtitle}
									{secondSubtitle ? (
										<>
											{!move ? <br /> : null}
											{secondSubtitle}
										</>
									) : null}
								</Text>
								<Link
									href="https://event.webinarjam.com/go/live/12/2n7z2b7f7s6sk"
									target="_blank"
									rel="noopener noreferrer">
									<Button
										w={'200px'}
										bg="success"
										color="white"
										variant={'green'}
										_hover={{
											bg: 'green.300',
										}}>
										{buttonName}
									</Button>
								</Link>
							</Flex>
						</Box>
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};
export default GuestModal;
