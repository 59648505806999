import { create } from 'zustand';

interface IState {
	isOpenMembersSidebar: boolean;
	onCloseMembersSidebar: VoidFunction;
	onOpenMembersSidebar: VoidFunction;
	onToggleMembersSidebar: VoidFunction;
	isOpenSearchResultSidebar: boolean;
	onCloseSearchResultSidebar: (isChannel: boolean) => void;
	onOpenSearchResultSidebar: (isChannel: boolean) => void;
	isOpenDirectFriendInfoSidebar: boolean;
	onCloseDirectFriendInfoSidebar: VoidFunction;
	onOpenDirectFriendInfoSidebar: VoidFunction;
	onToggleDirectFriendInfoSidebar: VoidFunction;
}

export const useMessengerSidebarsOpenStore = create<IState>(set => ({
	isOpenMembersSidebar: true,
	isOpenSearchResultSidebar: false,
	isOpenDirectFriendInfoSidebar: true,
	onOpenMembersSidebar: () =>
		set(() => ({
			isOpenMembersSidebar: true,
			isOpenSearchResultSidebar: false,
		})),
	onCloseMembersSidebar: () =>
		set(() => ({
			isOpenMembersSidebar: false,
			isOpenSearchResultSidebar: false,
		})),
	onToggleMembersSidebar: () =>
		set(state => ({
			isOpenMembersSidebar: !state.isOpenMembersSidebar,
			isOpenSearchResultSidebar: false,
		})),
	onOpenDirectFriendInfoSidebar: () =>
		set(() => ({
			isOpenDirectFriendInfoSidebar: true,
			isOpenSearchResultSidebar: false,
		})),
	onCloseDirectFriendInfoSidebar: () =>
		set(() => ({
			isOpenDirectFriendInfoSidebar: false,
			isOpenSearchResultSidebar: false,
		})),
	onToggleDirectFriendInfoSidebar: () =>
		set(state => ({
			isOpenDirectFriendInfoSidebar: !state.isOpenDirectFriendInfoSidebar,
			isOpenSearchResultSidebar: false,
		})),
	onOpenSearchResultSidebar: (isChannel: boolean) =>
		set(() => ({
			isOpenSearchResultSidebar: true,
			...(isChannel
				? { isOpenMembersSidebar: false }
				: { isOpenDirectFriendInfoSidebar: false }),
		})),
	onCloseSearchResultSidebar: (isChannel: boolean) =>
		set(() => ({
			isOpenSearchResultSidebar: false,
			...(isChannel
				? { isOpenMembersSidebar: true }
				: { isOpenDirectFriendInfoSidebar: true }),
		})),
}));
