import { useEffect, useCallback, useRef } from 'react';
import { onMessage } from 'firebase/messaging';
import { useGetNotificationSetting } from '../pages/Messenger/queries';
import { messaging } from '../messaging_init';
import { Alerter } from '../utils/Alerter';
import { useDefaultUserStore } from './../store/useUserStore';
import { Claim } from '../types';
import { useLocation } from 'react-router-dom';

export const useMessengerNotificationsListener = () => {
	const location = useLocation();

	const { user } = useDefaultUserStore();
	const { data } = useGetNotificationSetting({
		isEnabled:
			!!user?.claimsIds?.includes(Claim.Messenger) &&
			!!user?.isCommunityInstructionCompleted &&
			!!user?.isCommunityAvailable,
	});

	const currentPathRef = useRef(location.pathname);

	const getRedirectLink = useCallback(
		(channelId?: string, senderId?: string, conversationId?: string) => {
			switch (true) {
				case !!channelId:
					return {
						path: `/messenger/community/${channelId}`,
						redirectLink: `/messenger/community/${channelId}`,
					};
				case !!conversationId && !!senderId:
					return {
						path: `/messenger/direct/${senderId}`,
						redirectLink: `/messenger/direct/${senderId}?conversationId=${conversationId}`,
					};
				default:
					return { path: '', redirectLink: '' };
			}
		},
		[],
	);

	const { inAppNotification } = data?.value || {};

	useEffect(() => {
		currentPathRef.current = location.pathname;
	}, [location.pathname]);

	useEffect(() => {
		if (messaging) {
			const unsubscribe = onMessage(messaging, payload => {
				if (!inAppNotification) {
					return;
				}
				const {
					body,
					conversationId,
					type,
					channelId,
					senderName,
					channelName,
					sentById,
				} = payload.data || {};

				const { path, redirectLink } = getRedirectLink(
					channelId,
					sentById,
					conversationId,
				);

				if (currentPathRef.current === path) {
					return;
				}

				if (channelId) {
					Alerter.messengerNotification({
						senderName,
						type,
						channelId: +channelId,
						channelName,
						message: body,
						time: new Date().toISOString(),
						redirectLink,
					});
				} else if (conversationId) {
					Alerter.messengerNotification({
						senderName,
						type,
						conversationId: +conversationId,
						senderId: sentById ? +sentById : 0,
						message: body,
						time: new Date().toISOString(),
						redirectLink,
					});
				}
			});

			return () => {
				unsubscribe();
			};
		}
	}, [inAppNotification, messaging, getRedirectLink]);
};
