import { UserManager } from 'oidc-react';
import { WebStorageStateStore } from 'oidc-client-ts';
import { OIDC_CONFIG } from '../../constants';

export const userManager = new UserManager({
	...OIDC_CONFIG,
	userStore: new WebStorageStateStore({ store: window.sessionStorage }),
	revokeTokensOnSignout: true,
	revokeTokenTypes: ['refresh_token'],
});
